import axios from 'axios'
import {url} from './url';

export const getKlijenti = async () => {
    return axios.get(url + 'klijenti', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const createKlijent = async (formData) => {
    return axios.post(url + 'create-klijent', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const deleteKlijent = async (formData) => {
    return axios.post(url + 'delete-klijent', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const unsubscribeKlijent = async (email) => {
    return axios.get(url + 'unsubscribe-klijent/' + email, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}