import axios from 'axios'
import {url} from './url';

export const userInfo = async () => {
    return axios.get(url + 'user-info/' + localStorage.getItem("user_id"), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const getRadnici = async () => {
    return axios.get(url + 'radnici', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const createRadnik = async (formData) => {
    return axios.post(url + 'create-radnik', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const deleteRadnik = async (formData) => {
    return axios.post(url + 'delete-radnik', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}