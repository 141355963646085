import axios from 'axios'
import {url} from './url';

export const getMusterije = async () => {
    return axios.get(url + 'musterije', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const deleteMusterija = async (formData) => {
    return axios.post(url + 'delete-musterija', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}