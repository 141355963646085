import React, {useState} from 'react';
import {DateCalendar, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {srRS} from "@mui/material/locale";
import {srLatn} from "date-fns/locale";
import {v4 as uuidv4} from 'uuid';
import {
    Alert,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    LinearProgress,
    ListSubheader,
    Paper,
    Popover,
    Snackbar,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import {useMutation, useQuery} from "@tanstack/react-query";
import {canFitUslugaEP, getWashCenovnik} from "../../apis/UslugaApi";
import Divider from "@mui/material/Divider";
import {checkPromoKod} from "../../apis/PromoKodApi";
import CloseIcon from "@mui/icons-material/Close";
import {createTermin, getTermini} from "../../apis/TerminApi";
import CryptoJS from "crypto-js";
import {getNeradniDani} from "../../apis/NeradniDaniApi";
import {getScenario} from "../../apis/ScenarioApi";
import InfoIcon from '@mui/icons-material/Info';
import upustvo from '../../assets/uputstvo.png';

const theme = createTheme(
    {
        palette: {
            mode: "light",
            primary: {main: "#FC9303"}
        },
    },
    srRS,
);

const generateMac = (data) => {
    const key =
        "8eWakfqeBxuaqGD2umAnJ-wtBdgG-5trJ-NKaegVwz-9RMruQ3JMxjJ-sXmXgmTktCP-ANE-FXW--tJj2dJjgUjWbrMphmatz-4-";
    const stringToHash =
        `URLMS=https://eclectic.rs/potvrda-placanja/200/${
            data.sifra
        }&URLDONE=https://eclectic.rs/potvrda-placanja/200/${
            data.sifra
        }&ORDERID=${
            data.sifra
        }&SHOPID=80729SE00121301&AMOUNT=${
            data.cena
        }&CURRENCY=941&ACCOUNTINGMODE=I&AUTHORMODE=I`;

    // Generate the HMAC hash
    const mac = CryptoJS.HmacSHA256(stringToHash, key).toString(CryptoJS.enc.Hex);

    return mac;
};

const timeslotsPlace1 = [
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30"
]

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ZakazivanjePage = () => {
    const [usluga, setUsluga] = useState(null);
    const [value, setValue] = useState(new Date());
    const [datum, setDatum] = useState((new Date().getFullYear().toString())
        + "-" + (new Date().getMonth() + 1).toString()
        + "-" + new Date().getDate().toString());
    const [mesto, setMesto] = useState(null);
    const [vreme, setVreme] = useState(null);

    const [canFit, setCanFit] = useState(false);

    const [ime, setIme] = useState(null);
    const [prezime, setPrezime] = useState(null);
    const [email, setEmail] = useState(null);
    const [telefon, setTelefon] = useState(null);
    const [registracija, setRegistracija] = useState(null);
    const [promoKod, setPromoKod] = useState(null);
    const [popust, setPopust] = useState(0);

    const [id, setId] = useState(null);
    const [indexUsluga, setIndexUsluga] = useState(null);

    const [imeError, setImeError] = useState(false);
    const [imeErrorMessage, setImeErrorMessage] = useState("");

    const [prezimeError, setPrezimeError] = useState(false);
    const [prezimeErrorMessage, setPrezimeErrorMessage] = useState("");

    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");

    const [telefonError, setTelefonError] = useState(false);
    const [telefonErrorMessage, setTelefonErrorMessage] = useState("");

    const [registracijaError, setRegistracijaError] = useState(false);
    const [registracijaErrorMessage, setRegistracijaErrorMessage] = useState("");

    const [promoKodError, setPromoKodError] = useState(false);
    const [promoKodErrorMessage, setPromoKodErrorMessage] = useState("");

    const currentDate = new Date();
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 2);

    const parseTimeToDate = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0); // Set hours and minutes, reset seconds and milliseconds
        return date;
    };

    const areDatesEqual = (date1, date2) => {
        return (
            date1.getFullYear() == date2.getFullYear() &&
            date1.getMonth() == date2.getMonth() &&
            date1.getDate() == date2.getDate()
        )
    }

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const {data: scenario, isLoading: loadingScenario, isError: errorScenario} = useQuery({
        queryKey: ["scenario"],
        queryFn: getScenario
    });

    const {data: usluge, isLoading: loadingUsluge, isError: errorUsluge} = useQuery({
        queryKey: ['washCenovnik'],
        queryFn: getWashCenovnik
    });

    const {data: neradniDani, isLoading: loadingNeradniDani, isError: errorNeradniDani} = useQuery({
        queryKey: ["neradniDani"],
        queryFn: getNeradniDani,
    });

    const checkPromoKodMutation = useMutation({
        mutationFn: (kod) => checkPromoKod(kod),
        onSuccess: (response) => {
            setType(0);
            setMessage(response.data.message);
            setOpen(true);
            setPopust(response.data.popust);
        },
        onError: (error) => {
            setType(1);
            setMessage(error.response.data.message);
            setOpen(true);
            setPopust(0);
        }
    });

    const paymentMutation = useMutation({
        mutationFn: (formData) => createTermin(formData),
        onSuccess: (response) => {
            const cena = response.data.cena;
            const sifra = response.data.sifra;
            const email = response.data.email;

            setType(0);
            setMessage("Poštovani, prebacićemo vas na ekran za plaćanje za 3 sekunde! Molimo vas da ukoliko nećete da platite odmah, otkažete rezervaciju na ekranu za plaćanje.");
            setOpen(true);

            setTimeout(() => {
                const mac = generateMac({
                    cena: cena,
                    sifra: sifra
                });

                const url = `https://virtualpos.sia.eu/vpos/payments/main?PAGE=LAND&AMOUNT=${cena * 100}&CURRENCY=941&ORDERID=${sifra}&SHOPID=80729SE00121301&URLBACK=https://eclectic.rs/potvrda-placanja/400/${sifra}&URLDONE=https://eclectic.rs/potvrda-placanja/200/${sifra}&URLMS=https://eclectic.rs/potvrda-placanja/200/${sifra}&ACCOUNTINGMODE=I&AUTHORMODE=I&MAC=${mac}&LANG=SR&SHOPEMAIL=${email}`;

                window.location.href = url;
            }, 3000);
        },
        onError: (error) => {
            setType(1);
            setMessage(error.response.data.message);
            setOpen(true);
        }
    });

    const fitMutation = useMutation({
        mutationFn: (formData) => canFitUslugaEP(formData),
        onSuccess: (response) => {
            if (response.data.can_fit == 1) {
                setCanFit(true);
                setType(0);
                setMessage("Ovu uslugu je moguće rezervisati u izabranom periodu!");
                setOpen(true);
            } else {
                setCanFit(false);
                setType(1);
                setMessage("Ovu uslugu nije moguće rezervisati u izabranom periodu!");
                setOpen(true);
            }
        },
        onError: (error) => {
            console.log(error.response.data);
        }
    });

    const {data: termini, isLoading: loadingTermini, isError: errorTermini} = useQuery({
        queryKey: ["termini", datum],
        queryFn: () => getTermini(datum + " 00:00:00"),
        enabled: !!datum,
    });

    const [openPopoverId, setOpenPopoverId] = useState(null); // Track the current popover's id
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickPopover = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpenPopoverId(id);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setOpenPopoverId(null);
    };

    const openPopover = Boolean(anchorEl);
    const idPopover = openPopover ? 'simple-popover' : undefined;

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(event.target);
    }

    const handleCheckPromoKod = (value) => {
        setPromoKod(value);
        checkPromoKodMutation.mutateAsync(value).catch((e) => {
        });
    }

    const checkIfUslugaCanFit = (event, usluga) => {
        event.preventDefault();
        const formData = new FormData();

        formData.append('id_usluga', usluga?.id);
        formData.append('datum', datum);
        formData.append('vreme', vreme);
        formData.append('mesto', mesto);

        fitMutation.mutateAsync(formData).catch((e) => {
        });
    }

    const disableSundays = (date) => {
        const day = date.getDay(); // Get the day of the week (0 = Sunday, 6 = Saturday)
        return day == 0; // Disable if the day is Sunday
    }

    const disableDaysOff = (date) => {
        const datum_od = resetTime(new Date(neradniDani?.data.datum_od));
        const datum_do = resetTime(new Date(neradniDani?.data.datum_do));
        return date >= datum_od && date <= datum_do;
    }

    const resetTime = (d) => new Date(d.getFullYear(), d.getMonth(), d.getDate());

    const steps = [
        {
            label: 'Izaberite datum i vremenski slot na odgovarajućem mestu',
            description:
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                            Datum: <b>{datum}</b> | Mesto: <b>{mesto == null ? "----" : mesto}</b> |
                            Vreme: <b>{vreme == null ? "--:--" : vreme}</b>
                        </Paper>
                    </Grid>
                    <Grid size={{xl: 6, lg: 6, md: 12, xs: 12}}>
                        <Paper sx={{justifyContent: "center", alignItems: "center"}}>
                            <LocalizationProvider
                                adapterLocale={srLatn}
                                dateAdapter={AdapterDateFns}>
                                <DateCalendar
                                    maxDate={newDate}
                                    disablePast={true}
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                        setId(null);
                                        setVreme(null);
                                        setMesto(null);
                                        setDatum(newValue.getFullYear().toString()
                                            + "-" + (newValue.getMonth() + 1).toString()
                                            + "-" + newValue.getDate().toString());
                                    }}
                                    shouldDisableDate={(date) => disableSundays(date) || disableDaysOff(date)}
                                />
                            </LocalizationProvider>
                        </Paper>
                    </Grid>
                    <Grid size={{xl: 6, lg: 6, md: 12, xs: 12}}>
                        <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                            <Grid container spacing={2}>
                                <Grid size={{xs: 6}}>
                                    {loadingTermini ? <LinearProgress/> : (
                                        <Stack sx={{height: 335, overflowY: 'auto'}} direction="column" spacing={2}>
                                            <ListSubheader sx={{textAlign: 'center', fontWeight: 'bold'}}>Mesto broj
                                                1</ListSubheader>
                                            {timeslotsPlace1.map((item, index) => (
                                                <Button
                                                    onClick={() => {
                                                        setMesto(1);
                                                        setVreme(item);
                                                        setId("slot-1-" + index);
                                                    }}
                                                    variant="contained"
                                                    id={"slot-1-" + index}
                                                    disabled={
                                                        termini?.data.termini_1.includes(item) ||
                                                        disableSundays(value) ||
                                                        disableDaysOff(value) ||
                                                        (areDatesEqual(value, currentDate) && parseTimeToDate(item) < parseTimeToDate(currentDate.getHours() + ":" + currentDate.getMinutes()))}
                                                    color={id == "slot-1-" + index ? "secondary" : "primary"}
                                                >
                                                    {item}
                                                </Button>
                                            ))}
                                        </Stack>
                                    )}
                                </Grid>
                                <Grid size={{xs: 6}}>
                                    {loadingTermini || loadingScenario ? <LinearProgress/> : (
                                        <Stack sx={{height: 335, overflowY: 'auto'}} direction="column" spacing={2}>
                                            <ListSubheader sx={{textAlign: 'center', fontWeight: 'bold'}}>Mesto broj
                                                2</ListSubheader>
                                            {scenario?.data.map((item, index) => (
                                                <Button
                                                    onClick={() => {
                                                        setMesto(2);
                                                        setVreme(item);
                                                        setId("slot-2-" + index);
                                                    }}
                                                    variant="contained"
                                                    id={"slot-2-" + index}
                                                    disabled={
                                                        termini?.data.termini_2.includes(item) ||
                                                        disableSundays(value) ||
                                                        disableDaysOff(value) ||
                                                        (areDatesEqual(value, currentDate) && parseTimeToDate(item) < parseTimeToDate(currentDate.getHours() + ":" + currentDate.getMinutes()))
                                                    }
                                                    color={id == "slot-2-" + index ? "secondary" : "primary"}
                                                >
                                                    {item}
                                                </Button>
                                            ))}
                                        </Stack>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
        },
        {
            label: 'Izaberite uslugu',
            description:
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                            Usluga: {usluga == null ? "--------" : usluga?.naziv} |
                            Cena: {usluga == null ? "---- RSD" : usluga?.cena + " RSD"} |
                            Vreme trajanja: {usluga == null ? "---- min" : usluga?.trajanje + " min"}
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                            <Grid container spacing={2}>
                                {loadingUsluge ?
                                    <Grid size={{xs: 12}}>
                                        <CircularProgress size={20}/>
                                    </Grid> :
                                    errorUsluge ?
                                        <Grid size={{xs: 12}}>
                                            <Typography>Greška prilikom učitavanja usluga!</Typography>
                                        </Grid>
                                        : (
                                            usluge?.data.map((item, index) => (
                                                <Grid
                                                    size={{xl: 4, lg: 4, sm: 6, xs: 12}}
                                                    sx={{
                                                        border: "1px solid #ddd",
                                                        borderRadius: "8px",
                                                        pt: 2,
                                                        pl: 2,
                                                        pr: 2,
                                                        pb: 1,
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <IconButton sx={{
                                                        position: 'absolute',
                                                        top: 8,
                                                        right: 8
                                                    }} onClick={(event) => handleClickPopover(event, item.id)}
                                                                color="primary"
                                                                aria-label={"info" - index}>
                                                        <InfoIcon/>
                                                    </IconButton>
                                                    <Popover
                                                        id={idPopover}
                                                        open={openPopoverId == item.id}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClosePopover}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <ol>
                                                            {JSON.parse(item.opis).map((item, index) => (
                                                                <li>{item}</li>
                                                            ))}
                                                        </ol>
                                                    </Popover>
                                                    <Box
                                                        id={'usluga-' + index}
                                                        sx={{
                                                            "&:hover": {
                                                                backgroundColor: "lightgray",
                                                                transform: "scale(1.02)",
                                                                transition: "all 0.3s ease-in-out",
                                                                cursor: "pointer",
                                                            },
                                                            backgroundColor: indexUsluga == `usluga-${index}` ? "lightgray" : null,
                                                            transform: indexUsluga == `usluga-${index}` ? "scale(1.02)" : null,
                                                            transition: indexUsluga == `usluga-${index}` ? "all 0.3s ease-in-out" : null,
                                                            cursor: indexUsluga == `usluga-${index}` ? "pointer" : null,
                                                            border: "1px solid #ddd",
                                                            borderRadius: "8px",
                                                            p: 2,
                                                            mt: 5,
                                                        }}
                                                        onClick={(event) => {
                                                            setUsluga(item);
                                                            setIndexUsluga('usluga-' + index);
                                                            checkIfUslugaCanFit(event, item);
                                                        }}
                                                    >
                                                        <Stack
                                                            direction="column"
                                                            divider={<Divider orientation="vertical" flexItem/>}
                                                            spacing={0.5}
                                                        >
                                                            <Typography>{item.naziv} </Typography>
                                                            <Typography>{item.cena} RSD</Typography>
                                                            <Typography>{item.trajanje} min</Typography>
                                                            <Button
                                                                onClick={() => {
                                                                    setUsluga(item);
                                                                    setIndexUsluga('usluga-' + index);
                                                                }}
                                                                variant="contained"
                                                                color={indexUsluga == `usluga-${index}` ? "secondary" : "primary"}
                                                            >
                                                                Izaberite
                                                            </Button>
                                                        </Stack>
                                                    </Box>
                                                </Grid>
                                            ))
                                        )
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
        },
        {
            label: 'Unesite vaše podatke',
            description:
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                            Datum: <b>{datum}</b> | Mesto: <b>{mesto == null ? "----" : mesto}</b> |
                            Vreme: <b>{vreme == null ? "--:--" : vreme}</b>
                            <br/>
                            Usluga: {usluga == null ? "--------" : usluga?.naziv} |
                            Cena: {usluga == null ? "---- RSD" : usluga?.cena + " RSD"} |
                            Vreme trajanja: {usluga == null ? "---- min" : usluga?.trajanje + " min"}
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                            <Typography>Vaši podaci</Typography>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                noValidate
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    gap: 2,
                                }}
                            >
                                <FormControl>
                                    <TextField
                                        error={imeError}
                                        helperText={imeErrorMessage}
                                        id="ime"
                                        name="ime"
                                        placeholder="Petar"
                                        label="Ime"
                                        required
                                        fullWidth
                                        variant="outlined"
                                        color={imeError ? "error" : "primary"}
                                        sx={{ariaLabel: "ime"}}
                                        value={ime}
                                        onChange={(e) => setIme(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        error={prezimeError}
                                        helperText={prezimeErrorMessage}
                                        id="prezime"
                                        name="prezime"
                                        placeholder="Petrović"
                                        label="Prezime"
                                        required
                                        fullWidth
                                        variant="outlined"
                                        color={prezimeError ? "error" : "primary"}
                                        sx={{ariaLabel: "prezime"}}
                                        value={prezime}
                                        onChange={(e) => setPrezime(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        error={emailError}
                                        helperText={emailErrorMessage}
                                        id="email"
                                        name="email"
                                        placeholder="mail@example.com"
                                        label="E-mail"
                                        required
                                        fullWidth
                                        variant="outlined"
                                        color={emailError ? "error" : "primary"}
                                        sx={{ariaLabel: "email"}}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        error={telefonError}
                                        helperText={telefonErrorMessage}
                                        id="telefon"
                                        name="telefon"
                                        placeholder="+38100000000"
                                        label="Telefon"
                                        required
                                        fullWidth
                                        variant="outlined"
                                        color={telefonError ? "error" : "primary"}
                                        sx={{ariaLabel: "telefon"}}
                                        value={telefon}
                                        onChange={(e) => setTelefon(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        error={registracijaError}
                                        helperText={registracijaErrorMessage}
                                        id="registracija"
                                        name="registracija"
                                        placeholder="BG0000XY"
                                        label="Registracija"
                                        required
                                        fullWidth
                                        variant="outlined"
                                        color={registracijaError ? "error" : "primary"}
                                        sx={{ariaLabel: "registracija"}}
                                        value={registracija}
                                        onChange={(e) => setRegistracija(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        error={promoKodError}
                                        helperText={promoKodErrorMessage == "" ? "Nije obavezno polje!" : promoKodErrorMessage}
                                        id="promo_kod"
                                        name="promo_kod"
                                        placeholder="abc123"
                                        label="Promo kod"
                                        fullWidth
                                        variant="outlined"
                                        color={registracijaError ? "error" : "primary"}
                                        sx={{ariaLabel: "promo_kod"}}
                                        value={promoKod}
                                        onChange={(e) => handleCheckPromoKod(e.target.value)}
                                    />
                                </FormControl>
                                {popust != 0 ? <b>Popust: {popust} %</b> : null}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
        },
        {
            label: 'Potvrda informacija',
            description:
                <Grid container spacing={2}>
                    <Grid size={{xl: 6, lg: 6, sm: 12, xs: 12}}>
                        <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                            <h1 style={{color: 'red'}}>UPUTSTVO</h1>
                            <h4 style={{color: 'red'}}>OBAVEZNO POGLEDAJTE SLIKU</h4>
                            <h5 style={{color: 'red'}}>UKOLIKO VAM NE STIGNE POTVRDA O REZERVACIJI NA MAIL OBAVEZNO NAS
                                KONTAKTIRAJTE</h5>
                            <img src={upustvo} alt="uputstvo" style={{width: "100%", height: "auto"}}/>
                        </Paper>
                    </Grid>
                    <Grid size={{xl: 6, lg: 6, sm: 12, xs: 12}}>
                        <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", textAlign: "left"}}>
                            Usluga: <b>{usluga == null ? "--------" : usluga?.naziv}</b> <br/>
                            Cena: <b>{usluga == null ? "---- RSD" : usluga?.cena + " RSD"}</b> <br/>
                            Vreme trajanja: <b>{usluga == null ? "---- min" : usluga?.trajanje + " min"}</b> <br/>
                            Datum: <b>{datum}</b> <br/>
                            Broj mesta: <b>{mesto}</b> <br/>
                            Vreme: <b>{vreme}</b> <br/>
                            {popust != 0 ?
                                <>
                                    <span>Cena sa popustom <b>{popust} %</b>: <b>{(usluga?.cena * (100 - popust) / 100).toFixed(2)} RSD</b></span><br/>
                                </> :
                                null
                            }
                            Ime: <b>{ime}</b> <br/>
                            Prezime: <b>{prezime}</b> <br/>
                            Email: <b>{email}</b> <br/>
                            Telefon: <b>{telefon}</b> <br/>
                            Registracija: <b>{registracija}</b> <br/>
                        </Paper>
                    </Grid>
                </Grid>
        },
    ];

    const [type, setType] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    const handlePlacanje = (event) => {
        event.preventDefault();
        const formData = new FormData();

        if (ime == "") {
            setType(1);
            setMessage("Molimo vas unesite ime!");
            setOpen(true);
            return;
        }

        if (prezime == "") {
            setType(1);
            setMessage("Molimo vas unesite ime!");
            setOpen(true);
            return;
        }

        if (email == "" || !emailRegex.test(email)) {
            setType(1);
            setMessage("Molimo vas unesite email u ispravnom formatu! (example@domain.com)");
            setOpen(true);
            return;
        }

        if (telefon == "") {
            setType(1);
            setMessage("Molimo vas unesite telefon!");
            setOpen(true);
            return;
        }

        if (registracija == "") {
            setType(1);
            setMessage("Molimo vas unesite registraciju u ispravnom formatu! (BG1234AB)");
            setOpen(true);
            return;
        }

        const cena = popust == 0 ? usluga?.cena : (usluga?.cena * (100 - popust) / 100).toFixed(2);
        const sifra = uuidv4();

        formData.append('ime', ime);
        formData.append('prezime', prezime);
        formData.append('email', email);
        formData.append('telefon', telefon);
        formData.append('registracija', registracija);
        formData.append('vreme_trajanja', usluga?.trajanje);
        formData.append('datum', datum);
        formData.append('vreme', vreme);
        formData.append('mesto', mesto);
        formData.append('id_usluga', usluga?.id);
        formData.append('sifra', sifra);
        formData.append('cena', cena);
        formData.append('status', 0);
        formData.append('placeno', 0);
        formData.append('promo_kod', promoKod);

        paymentMutation.mutateAsync(formData).catch((e) => {
        });
    };

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {type == 0 ? (
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                )}
            </Snackbar>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Zakazivanje</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Zakazivanje</a>
                        </div>
                    </div>
                </div>
            </div>
            <Box sx={{
                padding: {
                    xs: 0, // No padding for extra-small screens
                    sm: 0, // Optional: No padding for small screens (if required)
                    md: 0, // No padding for medium screens
                    lg: 10, // Apply padding for large screens
                    xl: 10, // Apply larger padding for extra-large screens
                },
            }}>
                {loadingNeradniDani ? <LinearProgress/> : (
                    <div>
                        <ThemeProvider theme={theme}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel
                                            optional={
                                                index == steps.length - 1 ? (
                                                    <Typography variant="caption">Poslednji korak</Typography>
                                                ) : null
                                            }
                                        >
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography>{step.description}</Typography>
                                            <Box sx={{mb: 2}}>
                                                <Button
                                                    variant="contained"
                                                    onClick={index == 3 ? handlePlacanje : handleNext}
                                                    sx={{mt: 1, mr: 1}}
                                                    disabled={
                                                        (index == 0 && (vreme == null || mesto == null)) ||
                                                        (index == 1 && (usluga == null || !canFit)) ||
                                                        (index == 2 && (ime == null || prezime == null || email == null || telefon == null || registracija == null))
                                                    }
                                                >
                                                    {index == steps.length - 1 ? 'Plaćanje' : 'Sledeće'}
                                                </Button>
                                                <Button
                                                    disabled={index == 0}
                                                    onClick={handleBack}
                                                    sx={{mt: 1, mr: 1}}
                                                >
                                                    Nazad
                                                </Button>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </ThemeProvider>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{p: 3}}>
                                <Typography>Svi koraci su gotovi - vi ste završili</Typography>
                                <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
                                    Ponovo počnite
                                </Button>
                            </Paper>
                        )}
                    </div>
                )}
            </Box>
        </div>
    );
};

export default ZakazivanjePage;