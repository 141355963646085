import React from 'react';

const UklanjanjeDlakaOdLjubimacaPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Uklanjanje dlaka od kućnih ljubimaca</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Uklanjanje dlaka od kućnih ljubimaca</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Uklanjanje dlaka od ljubimaca</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            Dlake,pogotovo oštre i kratke, imaju neprijatnu osobinu da se usade u tkaninu, toliko
                            tvrdoglavo da ih u nekim slučajevima i nakon naše vrlo detaljne usluge čišćenja enterijera
                            jedino zaseban tretman može “isčupati” i ukloniti.
                            <br/><br/>
                            U okviru ove opcione usluge, koristićemo specijalne četke koje će izvući usađene dlake iz
                            tkanine i tek onda ih detaljno usisati.Postupak ćemo ponoviti ukoliko ima potrebe.
                            <br/><br/>
                            U uslugu ulaze pod,sedišta i patosnice ili oni pojedinačno- u zavisnosti od potrebe.
                            <br/><br/>
                            <strong>Procenjeno vreme potrebno za uslugu: 20-40min</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UklanjanjeDlakaOdLjubimacaPage;