import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {getGalerija} from "../../../apis/GalerijaApi";
import {Backdrop, CircularProgress, ImageList, ImageListItem, ImageListItemBar} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

const GalerijaPage = () => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ["galerija"],
        queryFn: getGalerija,
    });

    if (isLoading) {
        return (
            <Backdrop
                sx={(theme) => ({background: '#383E42', color: '#FC9303', zIndex: theme.zIndex.drawer + 1})}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Galerija</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Galerija</a>
                        </div>
                    </div>
                </div>
            </div>
            <ImageList cols={3} rowHeight={"auto"}>
                {data?.data.map((item, index) => (
                    <ImageListItem key={index}>
                        <img
                            srcSet={item.putanja}
                            src={item.putanja}
                            alt={"slika" + index}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={item.autor}
                            subtitle={item.timestamp}
                            actionIcon={
                                <IconButton
                                    sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                    aria-label={`info about ${item.autor}`}
                                >
                                    <InfoIcon/>
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
};

export default GalerijaPage;