import React from 'react';

const ONamaPage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>O nama</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">O nama</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact">
                <div className="container">
                    <div className="section-header text-center">
                        <p>O nama</p>
                        <h2>Kratak tekst o nama</h2>
                    </div>
                    <div className="row">
                        <p>
                            Dobrodošli u detailing studio ECLECTIC, pravo mesto za sve Vaše potrebe iz oblasti
                            ulepšavanja i održavanja vozila, plovila i letelica.

                            Od svog nastanka smo posvećeni postizanju najvišeg kvaliteta usluga koje nudimo, s posebnim
                            akcentom na profesionalnom pristupu samom radu i klijentu,upotrebi najkvalitetnijih
                            proizvoda i tehnika, kao i konstantnim usavršavanjem iz sfere održavanja vozila.

                            Osnovan 2010. godine, naš mali studio je prešao prilično dug put: od puke strasti, početnih
                            koraka i usavršavanja, do stvaranja sopstvenog pristupa svemu vezano za detailing,metodama
                            rada i najboljoj prezentaciji ove branše u našoj zemlji.

                            Našim klijentima detailing studio ECLECTIC predstavlja mesto gde mogu profesionalno,
                            kvalitetno i bezbedno, izvući maksimum iz lepote svog ljubimca, zaštititi apsolutno sve
                            površine u i na njemu, dok na našoj drugoj lokaciji, u okviru koje posluje premijum
                            auto-perionica ECLECTIC, rezultat našeg rada mogu održavati besprekornim- iako su vrata
                            perionice, naravno, otvorena za sve Vas.

                            Šta god da je Vaša potreba- znajte da imate mesto na kojem ćete spoznati suštinu kvaliteta .

                            Svim postojećim klijentima,koji su zajedno sa nama “stvorili” ECLECTIC se zahvaljujemo na
                            višestruko ukazanom poverenju, a svima Vama, budućim klijentima, se unapred zahvaljujemo na
                            poverenju koje ćete nam tek ukazati.

                            Mnoštvo klijenata iz prve grupacije bi Vam reklo da ćete napraviti najbolji izbor za Vas.

                            Ukoliko imate pitanja ili nedoumice, nemojte oklevati da nas kontaktirate.<br/>

                            S poštovanjem,<br/>

                            Miloš Božić<br/>

                            <h4>ECLECTIC DETAILING STUDIO & CARWASH</h4><br/>
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact-info">
                                <h2>Podaci preduzeća</h2>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fa-solid fa-file-signature"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h3>Poslovno ime</h3>
                                        <p>Eclectic Detailing doo Beograd</p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fa-solid fa-signature"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h3>Skraćeno poslovno ime</h3>
                                        <p>Eclectic detailing doo</p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fa-solid fa-hashtag"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h3>Matični broj/Registarski broj</h3>
                                        <p>21792713</p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fa-solid fa-info"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h3>PIB</h3>
                                        <p>113040488</p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fa-solid fa-user-tie"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h3>Pravna forma</h3>
                                        <p>Društvo sa ograničenom odgovronošću</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ONamaPage;