import React, {useState} from 'react';
import {Backdrop, Button, CircularProgress, ImageList, ImageListItem, ImageListItemBar, Paper} from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import UploadIcon from '@mui/icons-material/Upload';
import Notification from "../../components/Notification";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getGalerija, uploadFiles} from "../../apis/GalerijaApi";
import {MuiFileInput} from "mui-file-input";
import CloseIcon from '@mui/icons-material/Close'

const AdminGalerijaPage = () => {
    const queryClient = useQueryClient();
    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [files, setFiles] = useState([]);

    const handleFileChange = (newValue) => {
        setFiles(newValue);
    };

    const handleUpload = (event) => {
        event.preventDefault();
        const formData = new FormData();

        if (files == null || files.length == 0) {
            setMessage("Izaberite fajlove!");
            setType(0);
            setOpenN(true);
            return;
        }

        Array.from(files).forEach((file) => {
            formData.append('files[]', file);
        });

        mutation.mutateAsync(formData).catch((e) => {
        });
    }

    const mutation = useMutation({
        mutationFn: (formData) => uploadFiles(formData),
        onSuccess: (response) => {
            setMessage(response.data.message);
            setType(1);
            setOpenN(true);
            queryClient.invalidateQueries(["galerija"]);
        },
        onError: (error) => {
            setMessage(error.response.data.message);
            setType(0);
            setOpenN(true);
        }
    });

    const {data, isLoading, isError} = useQuery({
        queryKey: ["galerija"],
        queryFn: getGalerija,
    });

    if (isLoading) {
        return (
            <Backdrop
                sx={(theme) => ({background: '#383E42', color: '#FC9303', zIndex: theme.zIndex.drawer + 1})}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <MuiFileInput
                        margin="normal"
                        fullWidth
                        label="Izaberite fotografije"
                        placeholder="Izaberite fotografije"
                        accept="images/*"
                        multiple
                        value={files}
                        inputProps={{accept: 'image/*'}}
                        onChange={handleFileChange}
                        clearIconButtonProps={{
                            title: "Ukloni",
                            children: <CloseIcon fontSize="medium"/>
                        }}
                    />
                    <Button onClick={handleUpload} fullWidth variant="contained"
                            endIcon={<UploadIcon/>}>Unesite</Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ImageList cols={4} rowHeight={512}>
                        {data?.data.map((item, index) => (
                            <ImageListItem key={index}>
                                <img
                                    srcSet={item.putanja}
                                    src={item.putanja}
                                    alt={"slika" + index}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={item.autor}
                                    subtitle={item.timestamp}
                                    actionIcon={
                                        <IconButton
                                            sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                            aria-label={`info about ${item.autor}`}
                                        >
                                            <InfoIcon/>
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AdminGalerijaPage;