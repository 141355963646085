import React, {useState} from 'react';
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import Grid from "@mui/material/Grid2";
import {LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {getAnalitika, getPartner} from "../../../apis/PartnerApi";
import {srLatn} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";

const PartnerInfoPage = () => {
    const {id} = useParams();

    const [value, setValue] = useState(new Date());
    const [datum, setDatum] = useState((new Date().getFullYear().toString())
        + "-" + (new Date().getMonth() + 1).toString()
        + "-" + new Date().getDate().toString());

    const {data, isLoading, isError} = useQuery({
        queryKey: ['partner', id],
        queryFn: () => getPartner(id)
    });

    const {data: analitika, isLoading: loadingAnalitika, isError: errorAnalitika} = useQuery({
        queryKey: ['analitika', id, datum],
        queryFn: () => getAnalitika(id, datum)
    });

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {isLoading ? (
                        <LinearProgress size={24}/>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table aria-label="reservation-information">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Informacija</TableCell>
                                        <TableCell align="right">Vrednost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        key={1}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Šifra
                                        </TableCell>
                                        <TableCell align="right">{data?.data.partner.uuid}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={2}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Ime
                                        </TableCell>
                                        <TableCell align="right">{data?.data.partner.naziv}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={3}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            PIB
                                        </TableCell>
                                        <TableCell align="right">{data?.data.partner.pib}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={4}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Email
                                        </TableCell>
                                        <TableCell align="right">{data?.data.partner.email}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={5}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Ukupan broj termina
                                        </TableCell>
                                        <TableCell align="right"><b
                                            style={{
                                                color: "red",
                                                fontSize: 22
                                            }}>{data?.data.termini_count}</b></TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={6}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Ukupna zarada
                                        </TableCell>
                                        <TableCell align="right"><b
                                            style={{
                                                color: "red",
                                                fontSize: 22
                                            }}>{data?.data.termini_pare} RSD</b></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LocalizationProvider
                        adapterLocale={srLatn}
                        dateAdapter={AdapterDateFns}>
                        <DatePicker
                            sx={{width: '100%'}}
                            label="Mesec"
                            views={['month', 'year']}
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                                setDatum(newValue.getFullYear().toString()
                                    + "-" + (newValue.getMonth() + 1).toString()
                                    + "-" + newValue.getDate().toString());
                            }}
                        />
                    </LocalizationProvider>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {loadingAnalitika ? (
                        <LinearProgress size={24}/>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table aria-label="reservation-information">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Informacija</TableCell>
                                        <TableCell align="right">Vrednost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        key={1}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Broj termina
                                        </TableCell>
                                        <TableCell align="right">{analitika?.data.termini_count}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={2}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Ukupna zarada
                                        </TableCell>
                                        <TableCell align="right">{analitika?.data.termini_pare} RSD</TableCell>
                                    </TableRow>
                                    {analitika?.data.termini_usluge.map((item, index) => (
                                        <TableRow
                                            key={'1-' + index}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {item.naziv}
                                            </TableCell>
                                            <TableCell align="right"><b
                                                style={{
                                                    color: "red",
                                                    fontSize: 22
                                                }}>{item.total_count}</b></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default PartnerInfoPage;