import React from 'react';
import Lottie from "lottie-react";

import animation from '../../assets/lottie/animation404.json'

const ErrorPage = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Lottie animationData={animation} width={75 + '%'} loop={true}/>
        </div>
    );
};

export default ErrorPage;