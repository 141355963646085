import * as React from "react";
import {useState} from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer, {drawerClasses} from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MenuContent from "./MenuContent";
import {useMutation, useQuery} from "@tanstack/react-query";
import {userInfo} from "../../apis/UserApi";
import {CircularProgress} from "@mui/material";
import {logout} from "../../apis/AuthApi";
import Notification from "../Notification";
import {useNavigate} from "react-router";

function SideMenuMobile({open, toggleDrawer}) {
    const navigate = useNavigate();
    let id = localStorage.getItem("user_id");
    const {data, isLoading, isError} = useQuery({
        queryKey: ["userInfo", id],
        queryFn: () => userInfo(id),
    });

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const logoutMutation = useMutation({
        mutationFn: (formData) => logout(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setTimeout(() => {
                localStorage.clear();
                navigate("/login");
            }, 2000);
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        },
    });

    const handleLogout = () => {
        const formData = new FormData();

        formData.append("id", id);

        logoutMutation.mutateAsync(formData).catch((e) => {
        });
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            sx={{
                [`& .${drawerClasses.paper}`]: {
                    backgroundImage: "none",
                    backgroundColor: "background.paper",
                },
            }}
        >
            <Notification
                open={openN}
                message={message}
                setOpen={setOpenN}
                type={type}
            />
            <Stack
                sx={{
                    maxWidth: "70dvw",
                    height: "100%",
                }}
            >
                <Stack direction="row" sx={{p: 2, pb: 0, gap: 1}}>
                    <Stack
                        direction="row"
                        sx={{gap: 1, alignItems: "center", flexGrow: 1, p: 1}}
                    >
                        <Avatar sizes="small" alt="Avatar" sx={{width: 24, height: 24}}/>
                        <Typography component="p" variant="h6">
                            {isLoading ? (
                                <CircularProgress size={24}/>
                            ) : (
                                data?.data.korisnik.ime + " " + data?.data.korisnik.prezime
                            )}
                        </Typography>
                    </Stack>
                </Stack>
                <Divider/>
                <Stack sx={{flexGrow: 1}}>
                    <MenuContent/>
                    <Divider/>
                </Stack>
                <Stack sx={{p: 2}}>
                    <Button
                        variant="outlined"
                        fullWidth
                        startIcon={<LogoutRoundedIcon/>}
                        onClick={handleLogout}
                    >
                        Odjavite se
                    </Button>
                </Stack>
            </Stack>
        </Drawer>
    );
}

SideMenuMobile.propTypes = {
    open: PropTypes.bool,
    toggleDrawer: PropTypes.func.isRequired,
};

export default SideMenuMobile;
