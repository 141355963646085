import React, {useState} from 'react';
import HandshakeIcon from "@mui/icons-material/Handshake";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import GroupIcon from "@mui/icons-material/Group";
import EngineeringIcon from "@mui/icons-material/Engineering";
import {useNavigate} from "react-router-dom";
import {
    Alert,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowOutwardTwoToneIcon from "@mui/icons-material/ArrowOutwardTwoTone";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import CollectionsIcon from "@mui/icons-material/Collections";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import {createScenario, getScenarioAdmin} from "../../apis/ScenarioApi";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ArticleIcon from "@mui/icons-material/Article";

const mainListItems = [
    {
        text: "Partneri",
        icon: <HandshakeIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/partneri",
    },
    {
        text: "Termini",
        icon: <CalendarMonthIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/termini",
    },
    {
        text: "Usluge",
        icon: <CleaningServicesIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/usluge",
    },
    {
        text: "Klijenti",
        icon: <GroupIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/mušterije",
    },
    {
        text: "Prijavljeni korisnici",
        icon: <NewspaperIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/klijenti",
    },
    {
        text: "Radnici",
        icon: <EngineeringIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/radnici",
    },
    {
        text: "Galerija",
        icon: <CollectionsIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/galerija",
    },
    {
        text: "Promo kodovi",
        icon: <QrCode2Icon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/promo-kodovi",
    },
    {
        text: "Neradni dani",
        icon: <EventBusyIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/neradni-dani",
    },
    {
        text: "Novosti",
        icon: <ArticleIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/novosti",
    },
];

const AdminDashboardPage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [scenario, setScenario] = useState(null);

    const handleChange = (event) => {
        setScenario(event.target.value);
    };

    const {data, isLoading, isError} = useQuery({
        queryKey: ["scenario"],
        queryFn: getScenarioAdmin
    });

    const mutation = useMutation({
        mutationFn: (formData) => createScenario(formData),
        onSuccess: (response) => {
            setType(0);
            setMessage(response.data.message);
            setOpen(true);
            queryClient.invalidateQueries(['scenario'])
        },
        onError: (error) => {
            setType(1);
            setMessage(error.response.data.message);
            setOpen(true);
        }
    });

    const handleSubmitScenario = (event) => {
        event.preventDefault();

        const formData = new FormData();

        if (scenario == null) {
            return;
        }

        formData.append('id_scenarija', scenario);
        mutation.mutateAsync(formData).catch((e) => {
        });
    }

    const [type, setType] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <Grid container spacing={2}>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {type == 0 ? (
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                )}
            </Snackbar>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <AdminPanelSettingsTwoToneIcon size={22} sx={{mr: 2}}/>
                        Dobro došli na administratorski panel
                    </Typography>
                </Paper>
            </Grid>
            {mainListItems.map((item, index) => (
                <Grid size={{xs: 12, md: 6, lg: 6, xl: 6}}>
                    <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                        <Typography sx={{fontSize: 22, textAlign: "center"}}>
                            {item.icon}
                            {item.text}
                        </Typography>
                        <Button
                            endIcon={<ArrowOutwardTwoToneIcon/>}
                            sx={{mt: 2}}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                localStorage.setItem("index", index + 1);
                                navigate(item.route);
                            }}
                        >
                            Pristup
                        </Button>
                    </Paper>
                </Grid>
            ))}
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <AccessTimeFilledIcon size={22} sx={{mr: 2}}/>
                        Scenario rada | Trenutni scenario: {isLoading ? <CircularProgress size={22}/> :
                        <span>{data?.data.id}</span>}
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmitScenario}>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="demo-simple-select-label">Scenario rada</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={scenario}
                                label="Scenario rada"
                                onChange={handleChange}
                            >
                                <MenuItem value={1}>Standard (13:00 - 17:00)</MenuItem>
                                <MenuItem value={2}>Jutarnji (09:00 - 17:00)</MenuItem>
                                <MenuItem value={3}>Popodnevni (13:00 - 21:00)</MenuItem>
                                <MenuItem value={4}>Full (09:00 - 21:00)</MenuItem>
                            </Select>
                        </FormControl>
                        <Button fullWidth variant="contained" type="submit">Sačuvaj scenario</Button>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AdminDashboardPage;