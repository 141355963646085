import React from 'react';

const NanosenjeZastitnogPremazaPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Nanošenje zaštitnog premaza na lakirane delove</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Nanošenje zaštitnog premaza na lakirane delove</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Nanošenje zaštitnog premaza na lakirane delove</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            U toku ECLECTIC WASH-a
                            <br/><br/>
                            *pretpranje
                            <br/><br/>
                            *pranje 2bm*
                            <br/><br/>
                            *hemijska dekontaminacija laka
                            <br/><br/>
                            *ponovno pretpranje
                            <br/><br/>
                            *sušenje
                            <br/><br/>
                            *odmašćivanje površine
                            <br/><br/>
                            *nanošenje zaštitnog premaza
                            <br/><br/>
                            procenjeno vreme
                            <br/><br/>
                            2-4h, u zavisnosti od vozila(bez vremena potrebnog za ECLECTIC WASH).
                            <br/><br/>
                            <strong>PREPORUČUJEMO DA UNAPRED ZAKAŽETE OVU USLUGU,ILI SE RASPITATE ZA SLOBODNE TERMINE NA
                                NEKOJ OD LOKACIJA!</strong>
                            <br/><br/>
                            Nakon ECLECTIC WASH-a, i samo nakon njega, možete se odlučiti za vrhunsku zaštitu Vašeg
                            vozila, u vidu nanošenja nekog od naprednih zaštitnih premaza.
                            <br/><br/>
                            Ova vrsta premaza nudi većinu kvaliteta kao mnogo skuplje i trajnije KERAMIČKE ZAŠTITE, s
                            time da NE NUDE FIZIČKU ZAŠTITU LAKA, tj. nemaju tvrdoću klasične keramičke zaštite.
                            <br/><br/>
                            Njihove glavne karakteristike su:
                            <br/><br/>
                            *postojanost-traju minimalno 15 meseci, uz pravilno održavanje i duže,
                            <br/><br/>
                            *ekstremna hidrofobnost-lakše čišćenje i održavanje, kao i “bežanje” vode nakon kiše,
                            <br/><br/>
                            *dubina boje
                            <br/><br/>
                            *refleksija
                            <br/><br/>
                            *jača otpornost na hemijske elemente(so,rizla,industrijsko zagađenje u urbanim sredinama),
                            kao i situacije poput ptičjeg izmeta i sl,
                            <br/><br/>
                            *popunjavanje i,sledstveno,skrivanje defekata u laku,poput riseva i sitnih ogrebotina.
                            <br/><br/>
                            Tokom ECLECTIC WASH-a, ubacićemo dodatne korake u HEMIJSKOJ DEKONTAMINACIJI vozila, koja će
                            maksimalno očistiti lak od “usađene” i nataložene kontaminacije u samom laku, te ćemo
                            vozilo, nakon sušenja, i odmastiti u pripremi za nanošenje zaštitnog premaza.
                            <br/><br/>
                            Na sve površine eksterijera na koje je moguće i predviđeno nanošenje zaštite-ista će biti
                            naneta profesionalno i pedantno.
                            <br/><br/>
                            NAPOMENA
                            <br/><br/>
                            Vozilo će nakon aplikacije premaza kod nas stajati još pola sata, a premaz je u potpunosti
                            suv nakon 2-5 sati-u zavisnosti od spoljašnje temperature. U tom vremenu auto ne treba da se
                            kvasi, da bi se premaz maksimalno stvrdnuo..
                            <br/><br/>
                            <strong>Procenjeno vreme potrebno za uslugu: 1h30m-2h (bez vremena potrebnog za ECLECTIC
                                WASH).</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NanosenjeZastitnogPremazaPage;