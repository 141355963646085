import React from 'react';
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Alert, Button, IconButton, LinearProgress, Paper, Snackbar} from "@mui/material";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../assets/language/tableLang";
import {cancelTermin, getTerminiAdmin} from "../../apis/TerminApi";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

const AdminTerminiPage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [type, setType] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const {data, isLoading, isError} = useQuery({
        queryKey: ["getTerminiAdmin"],
        queryFn: getTerminiAdmin,
    });

    const mutation = useMutation({
        mutationFn: (formData) => cancelTermin(formData),
        onSuccess: (response) => {
            setType(0);
            setMessage(response.data.message);
            setOpen(true);
            queryClient.invalidateQueries(['getTerminiRadnik']);
        },
        onError: (error) => {
            setType(1);
            setMessage(error.response.data.message);
            setOpen(true);
        },
    });

    const handleCancel = (event, sifra) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('sifra', sifra);

        mutation.mutateAsync(formData).catch((e) => {
        });
    }

    const columns = [
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../termin/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
        {
            field: "otkazivanje",
            headerName: "Otkazivanje",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="error"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<DeleteIcon/>}
                        onClick={(event) => handleCancel(event, params.row.sifra)}
                    >
                        Otkazivanje
                    </Button>
                );
            },
        },
        {
            field: "datum",
            headerName: "Datum",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                const datum = new Date(params.row.datum);
                return (
                    <span>{datum.getDate() + "/" + (datum.getMonth() + 1) + "/" + datum.getFullYear()}</span>
                )
            }
        },
        {
            field: "vreme",
            headerName: "Vreme",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra termina",
            minWidth: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "ime",
            headerName: "Ime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "prezime",
            headerName: "Prezime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "telefon",
            headerName: "Telefon",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "registracija",
            headerName: "Registracija",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv usluge",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <Grid container spacing={2}>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {type == 0 ? (
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                )}
            </Snackbar>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        height: 900,
                    }}
                >
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja termina!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AdminTerminiPage;