import React from 'react';
import WebsiteLayout from "../layout/WebsiteLayout";
import {Outlet} from "react-router-dom";

const WebsiteRoute = () => {
    return (
        <WebsiteLayout>
            <Outlet/>
        </WebsiteLayout>
    );
};

export default WebsiteRoute;