import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import RadnikLayout from "../layout/RadnikLayout";

const RadnikRoute = () => {
    const role = localStorage.getItem("role");

    if (role != 2) {
        localStorage.clear();
        return <Navigate to="/login"/>;
    }

    return (
        <RadnikLayout>
            <Outlet/>
        </RadnikLayout>
    );
};

export default RadnikRoute;