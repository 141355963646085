import React from 'react';
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {updateTerminStatus} from "../../apis/TerminApi";
import {
    Alert,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Box from "@mui/material/Box";

const PotvrdaPlacanjaPage = () => {
    const {code, uuid} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['updateTerminStatus', uuid],
        queryFn: () => updateTerminStatus(code, uuid)
    });

    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Potvrda plaćanja</h2>
                        </div>
                        <div className="col-12">
                            <a href="/zakazivanje">Zakazivanje</a>
                            <a href="">Potvrda plaćanja</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="section-header text-center">
                    <p>Informacije o rezervaciji</p>
                </div>
                <div className="row">
                    {code == 200 ? (
                        <Box sx={{width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Alert variant="filled" severity="success">
                                Poštovani, trebalo bi da vam je stigla potvrda na mejl. Uspešno ste rezervisali termin.
                            </Alert>
                            {isLoading ? (
                                <LinearProgress size={24}/>
                            ) : (
                                <TableContainer sx={{mt: 5}} component={Paper}>
                                    <Table aria-label="reservation-information">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Informacija</TableCell>
                                                <TableCell align="right">Vrednost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                key={1}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Šifra
                                                </TableCell>
                                                <TableCell align="right">{uuid}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={2}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Ime
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.ime}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={3}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Prezime
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.prezime}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={4}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Email
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.email}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={5}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Telefon
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.telefon}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={6}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Registracija
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.registracija}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={7}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Cena
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.cena} RSD</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={8}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Broj mesta
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.mesto}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={9}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Status
                                                </TableCell>
                                                <TableCell align="right"><b
                                                    style={{color: 'green'}}>ZAKAZANO</b></TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={10}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Datum
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.datum}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={11}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Vreme
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.vreme}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Alert variant="filled" severity="error" sx={{textAlign: 'center'}}>
                                <b>Poštovani, nažalost Vaša uplata nije prošla. Vaš termin je otkazan.</b>
                            </Alert>
                            {isLoading ? (
                                <LinearProgress size={24}/>
                            ) : (
                                <TableContainer sx={{mt: 5}} component={Paper}>
                                    <Table aria-label="reservation-information">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Informacija</TableCell>
                                                <TableCell align="right">Vrednost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                key={1}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Šifra
                                                </TableCell>
                                                <TableCell align="right">{uuid}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={2}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Ime
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.ime}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={3}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Prezime
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.prezime}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={4}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Email
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.email}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={5}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Telefon
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.telefon}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={6}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Registracija
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.registracija}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={7}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Cena
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.cena} RSD</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={8}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Broj mesta
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.mesto}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={9}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Status
                                                </TableCell>
                                                <TableCell align="right"><b
                                                    style={{color: 'red'}}>OTKAZANO</b></TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={10}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Datum
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.datum}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={11}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Vreme
                                                </TableCell>
                                                <TableCell align="right">{data?.data.termin.vreme}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Box>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PotvrdaPlacanjaPage;