import axios from 'axios'
import {url} from './url';

export const getPoruke = async () => {
    return axios.get(url + 'poruke', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const createPoruka = async (formData) => {
    return axios.post(url + 'create-poruka', formData, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}