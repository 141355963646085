import React from 'react';
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import Grid from "@mui/material/Grid2";
import {LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {userInfo} from "../../../apis/UserApi";

const RadnikInfoPage = () => {
    const {id} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['korisnik', id],
        queryFn: () => userInfo(id)
    });

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {isLoading ? (
                        <LinearProgress size={24}/>
                    ) : (
                        <TableContainer sx={{mt: 5}} component={Paper}>
                            <Table aria-label="reservation-information">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Informacija</TableCell>
                                        <TableCell align="right">Vrednost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        key={2}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Ime
                                        </TableCell>
                                        <TableCell align="right">{data?.data.korisnik.ime}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={3}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Prezime
                                        </TableCell>
                                        <TableCell align="right">{data?.data.korisnik.prezime}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={4}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Email
                                        </TableCell>
                                        <TableCell align="right">{data?.data.korisnik.email}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default RadnikInfoPage;