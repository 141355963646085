import React from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    createTheme,
    CssBaseline,
    FormControl,
    IconButton,
    Snackbar,
    Stack,
    styled,
    TextField,
    ThemeProvider,
    Typography
} from "@mui/material";
import MuiCard from "@mui/material/Card";
import {srRS} from "@mui/material/locale";
import {useMutation} from "@tanstack/react-query";
import {login} from "../apis/AuthApi";
import {useNavigate} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme(
    {
        palette: {
            mode: "dark",
        },
    },
    srRS,
);

const Card = styled(MuiCard)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    },
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const SignInContainer = styled(Stack)(({theme}) => ({
    height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
    minHeight: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));

const LoginPage = () => {
    const navigate = useNavigate();
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');

    const [loading, setLoading] = React.useState(false);

    const [type, setType] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const mutation = useMutation({
        mutationFn: (formData) => login(formData),
        onSuccess: (response) => {
            setLoading(false);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user_id", response.data.korisnik.id);
            localStorage.setItem("role", response.data.korisnik.role);

            setType(0);
            setMessage(response.data.message);
            setOpen(true);

            if (response.data.korisnik.role == 1) {
                setTimeout(() => {
                    navigate('/administrator/početna');
                }, 2500);
            } else {
                setTimeout(() => {
                    navigate('/radnik/početna');
                }, 2500);
            }
        },
        onError: (error) => {
            setLoading(false);
            setType(1);
            setMessage(error.response.data.message);
            setOpen(true);
        }
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        if (emailError || passwordError) {
            return;
        }
        const data = new FormData(event.currentTarget);
        setLoading(true);

        mutation.mutateAsync(data).catch((e) => {
        });
    };

    const validateInputs = () => {
        const email = document.getElementById('email');
        const password = document.getElementById('password');

        let isValid = true;

        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        if (!password.value || password.value.length < 2) {
            setPasswordError(true);
            setPasswordErrorMessage('Password must be at least 2 characters long.');
            isValid = false;
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        return isValid;
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme/>
            <SignInContainer direction="column" justifyContent="space-between">
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    action={action}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    {type == 0 ? (
                        <Alert
                            onClose={handleClose}
                            severity="success"
                            variant="filled"
                            sx={{width: '100%', textAlign: "center"}}
                        >
                            {message}
                        </Alert>
                    ) : (
                        <Alert
                            onClose={handleClose}
                            severity="error"
                            variant="filled"
                            sx={{width: '100%', textAlign: "center"}}
                        >
                            {message}
                        </Alert>
                    )}
                </Snackbar>
                <Card variant="outlined">
                    <Typography
                        component="h3"
                        variant="h5"
                        sx={{
                            width: '100%',
                            fontSize: 'clamp(2rem, 10vw, 2.15rem)',
                            color: "white",
                            textAlign: "center"
                        }}
                    >
                        Eclectic | Prijavljivanje
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <TextField
                                error={emailError}
                                helperText={emailErrorMessage}
                                id="email"
                                name="email"
                                placeholder="mail@example.com"
                                label="Email"
                                required
                                fullWidth
                                variant="outlined"
                                color={emailError ? "error" : "primary"}
                                sx={{ariaLabel: "email"}}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                error={passwordError}
                                helperText={passwordErrorMessage}
                                id="password"
                                name="password"
                                placeholder="••••••••••••"
                                type="password"
                                label="Šifra"
                                required
                                fullWidth
                                variant="outlined"
                                color={passwordError ? "error" : "primary"}
                                sx={{ariaLabel: "password"}}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            disabled={loading}
                            variant="contained"
                            onClick={validateInputs}
                        >
                            {loading ? <CircularProgress size={20}/> : "Prijavite se"}
                        </Button>
                    </Box>
                </Card>
            </SignInContainer>
        </ThemeProvider>
    );
};

export default LoginPage;