import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, LinearProgress, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Notification from "../../components/Notification";
import UploadIcon from "@mui/icons-material/Upload";
import { createNeradniDani, getNeradniDani } from "../../apis/NeradniDaniApi";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { srLatn } from "date-fns/locale";

const AdminNeradniDaniPage = () => {
  const queryClient = useQueryClient();
  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const [datumOd, setDatumOd] = useState(new Date());
  const [datumDo, setDatumDo] = useState(new Date());

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();

    if (datumOd >= datumDo) {
      setMessage("Datum od mora biti manji nego datum do!");
      setType(0);
      setOpenN(true);
      return;
    }

    formData.append(
      "datum_od",
      datumOd.getFullYear().toString() +
        "-" +
        (datumOd.getMonth() + 1).toString() +
        "-" +
        datumOd.getDate()
    );
    formData.append(
      "datum_do",
      datumDo.getFullYear().toString() +
        "-" +
        (datumDo.getMonth() + 1).toString() +
        "-" +
        datumDo.getDate()
    );

    console.log(formData);

    mutation.mutateAsync(formData).catch((e) => {});
  };

  const mutation = useMutation({
    mutationFn: (formData) => createNeradniDani(formData),
    onSuccess: (response) => {
      setMessage(response.data.message);
      setType(1);
      setOpenN(true);
      queryClient.invalidateQueries(["neradniDani"]);
    },
    onError: (error) => {
      setMessage(error.response.data.message);
      setType(0);
      setOpenN(true);
    },
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ["neradniDani"],
    queryFn: getNeradniDani,
  });

  return (
    <Grid container spacing={2}>
      <Notification
        type={type}
        message={message}
        open={openN}
        setOpen={setOpenN}
      />
      <Grid size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LocalizationProvider
            adapterLocale={srLatn}
            dateAdapter={AdapterDateFns}
          >
            <DatePicker
              disablePast={true}
              sx={{ mt: 2, width: "100%" }}
              label="Izaberite datum početka neradnih dana"
              value={datumOd}
              onChange={(newValue) => setDatumOd(newValue)}
            />
          </LocalizationProvider>
          <br />
          <LocalizationProvider
            adapterLocale={srLatn}
            dateAdapter={AdapterDateFns}
          >
            <DatePicker
              disablePast={true}
              sx={{ mt: 2, mb: 2, width: "100%" }}
              label="Izaberite datum završetka neradnih dana"
              value={datumDo}
              onChange={(newValue) => setDatumDo(newValue)}
            />
          </LocalizationProvider>
          <Button
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            endIcon={<UploadIcon />}
          >
            Unesite
          </Button>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <LinearProgress />
          ) : (
            <Grid container spacing={2}>
              <Grid
                sx={{ textAlign: "center", fontSize: 28, fontWeight: "bold" }}
                size={{ xl: 4, lg: 4, sm: 4, xs: 12 }}
              >
                {data?.data.datum_od}
              </Grid>
              <Grid
                sx={{ textAlign: "center", fontSize: 28, fontWeight: "bold" }}
                size={{ xl: 4, lg: 4, sm: 4, xs: 12 }}
              >
                -
              </Grid>
              <Grid
                sx={{ textAlign: "center", fontSize: 28, fontWeight: "bold" }}
                size={{ xl: 4, lg: 4, sm: 4, xs: 12 }}
              >
                {data?.data.datum_do}
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AdminNeradniDaniPage;
