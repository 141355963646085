import React from 'react';

const ReklamacijeIPovracajRobePage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Reklamacije</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Reklamacije</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog">
                <div className="container">
                    <div className="section-header text-center">
                        <p></p>
                        <h2>Reklamacije</h2>
                    </div>
                    <div className="row" id="post-div">
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p><strong>Obaveštenje o načinu i mestu prijema reklamacija i načinu postupanja po
                                primljenim reklamacijama.</strong></p>

                            <p>Reklamacije na kupljene proizvode možete da izjavite u jednoj od naših prodavnica,
                                elektronskim putem na email adresu: reklamacije@eclectic.rs ili preko broja telefona:
                                069 210 3985. Prilikom izjavljivanja reklamacije dužni ste da dostavite robu na koju se
                                reklamacija odnosi, račun na uvid ili drugi dokaz o kupovini tih proizvoda (kopija
                                računa, slip i sl.), kao i garantni list ukoliko je reč o proizvodima sa garancijom.
                                Prilikom dostavljanja proizvoda niste dužni da dostavite ambalažu.</p>

                            <p>Po prijemu reklamacije izdaćemo Vam pismenu potvrdu ili ćemo Vas elektronskim putem
                                obavestiti da potvrđujemo da smo primili Vašu reklamaciju, odnosno saopštićemo Vam broj
                                pod kojim je ona zavedena u evidenciji primljenih reklamacija. Najkasnije u roku od osam
                                dana od dana prijema reklamacije, pismenim ili elektronskim putem odgovorićemo Vam na
                                izjavljenu reklamaciju. Odgovor će sadržati odluku da li prihvatamo reklamaciju,
                                izjašnjenje o Vašem zahtevu i konkretan predlog i rok za rešavanje reklamacije koji ne
                                može biti duži od 30 dana od dana podnošenja reklamacije.</p>

                            <p>Rok za rešavanje reklamacije prekida se kada potrošač primi odgovor prodavca i počinje da
                                teče iznova kada prodavac primi izjašnjenje potrošača na odgovor prodavca. Potrošač je
                                dužan da se izjasni na odgovor prodavca najkasnije u roku od tri dana od dana prijema
                                odgovora prodavca. Smatraće se da potrošač nije saglasan sa predlogom prodavca ukoliko
                                se ne izjasni u roku od tri dana.</p>

                            <p>U slučaju odbijanja reklamacije Eclectic d.o.o je dužan da pruži odgovarajuće obaveštenje
                                potrošaču u slučaju odbijanja reklamacije (obrazloženje prodavca u slučaju neprihvatanja
                                reklamacije) kao i&nbsp; da potrošača sveobuhvatno obavesti o mogućnosti rešavanja spora
                                vansudskim putem, kao i o nadležnim telima za vansudsko rešavanje potrošačkih
                                sporova.</p>

                            <p>Ukoliko je potrošač za kupljeni proizvod dobio garanciju i ako se reklamacija izjavljuje
                                nakon proteka 2 godine od dana kupovine, potrošač je dužan da reklamaciju izjavi davaocu
                                garancije.</p>

                            <p>&nbsp;</p>

                            <p>Više o uslovima kupovine proizvoda možete naći na veb sajtu: www.eclectic.rs u dokumentu
                                pod nazivom: “<a href="/uslovi-korišćenja">Uslovi korišćenja</a>”.</p>

                            <p>Vaš Eclectic</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReklamacijeIPovracajRobePage;