import React from 'react';

const DetailingMotornogProstoraPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Detailing motornog prostora</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Detailing motornog prostora</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Detailing motornog prostora</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            *uklanjanje većeg otpada-lišće, grančice i sl,<br/>

                            *izduvavanje kompresorom<br/>

                            *vizuelna inspekcija vozila<br/>

                            *zaštita osigurača, alternatora i sl,<br/>

                            *nanošenje proizvoda za čišćenje na donju stranu haube i motorni prostor<br/>

                            *rad četkama<br/>

                            *ispiranje TORNADOROM i izduvavanje,<br/>

                            *sušenje,<br/>

                            *nanošenje dresinga na plastične i gumene delove,<br/>

                            *finalno brisanje.<br/>

                            Imate potrebu da i ono što je nevidljivo većinom vremena bude čisto, uredno i
                            reprezentativno?<br/>

                            Ova usluga će Vam to pružiti.<br/>

                            U okviru nje ćemo od Vašeg motornog prostora napraviti skulpturu, kojoj će se odabrani
                            diviti.<br/>

                            Najpre ćemo fizički ukloniti otpad(grančice i slično) a potom komprimovanim vazduhom
                            izduvati sto više prašine iz najskrajnutijih delova motornog prostora. Zaštitićemo klasične
                            rizične delove(osigurače,alternator,anlanser i sl, iako su moderni automobili najčešće
                            dovoljno zaštićeni raznim poklopcima), i krenuti sa radom.<br/>

                            Na donji deo haube i motorni prostor ćemo naneti sredstvo za tu namenu, i većim izborom
                            četkica svaku dostupnu tačku detaljno očetkati. Potom ćemo sve to isprati TORNADOROM, što
                            omogućava da koristimo minimalnu količinu vode(u cilju dodatnog smanjenja rizika), a u isto
                            vreme njegova snaga dodatno čisti površine. Potom ćemo svu zaostalu vlagu ukloniti
                            komprimovanim vazduhom i mikrofiber krpama.<br/>

                            Na sve gumene i plastične delove ćemo naneti kvalitetan dresing, rasporediti ga ujednačeno i
                            ukloniti višak, a koji čle biti postojan duže vremena te će Vaša(ili naša) obaveza biti samo
                            da povremeno prebrišete taj prostor i duže održite takvo stanje.<br/>

                            Finalno brisanje i-motorni prostor je spreman da mu se divite.<br/>

                            NAPOMENA!<br/>

                            Ova usluga je namenjena za blago do srednje teže zaprljanog motornog prostora. Vozila sa
                            većim i ekstremnim zaprljanjima- kao na primer veće količine ulja, antifriza i slično, kao i
                            vozila sa očigledno oštećenim crevima i plastikama-NEĆEMO prihvatiti.<br/>

                            Ukoliko je zaštita haube oštećena i/ili visi-radimo uz konsultaciju sa Vama, NA VAŠ
                            RIZIK,što ne treba da Vas zaplaši jer ćemo profesionalizmom i pristupom uraditi sve u našoj
                            moći da ne dodje do poteškoća.<br/>
                            <br/>
                            <strong>Procenjeno vreme potrebno za uslugu: 1h-1,5h</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailingMotornogProstoraPage;