import React from 'react';

const PoliranjeFarovaPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Poliranje farova</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Poliranje farova</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Poliranje farova</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            *pranje fara
                            <br/><br/>
                            *šmirglanje različitim granulacijama šmirgle
                            <br/><br/>
                            *poliranje u više koraka
                            <br/><br/>
                            *pranje i odmašćivanje
                            <br/><br/>
                            *opciono-nanošenje keramičke zaštite
                            <br/><br/>
                            Ovom uslugom ćemo vratiti prozirnost vašim matiranim(požutelim) farovima, čime ćete imati
                            jaču osvetljenost puta i sigurnost,nećete imati poteškoće prilikom tehničkih pregleda,i
                            podmladićete celokupan izgled Vašeg vozila.
                            <br/><br/>
                            Nizom različitih granulacija šmirgle ćemo skinuti defekt, a potom nizom različitih pasti
                            dovesti far u (u najvećem broju slučajeva) fabričko stanje, sa maksimumom propustljivosti
                            svetlosnog snopa.
                            <br/><br/>
                            Imajte na umu da na defekte sa unutrašnje strane fara(zaprljanja, kondenzacija, prašina i
                            slično), kao i na ekstremne ogrebotine spolja-ne možemo uticati.
                            <br/><br/>
                            Opciono. možete se odlučiti da nakon tretmana poliranja farova na iste nanesemo keramičku
                            zaštitu, koja će štititi far od elemenata koji su u osnovi i uticali na pojavu matiranosti,
                            i time dodatno odložiti povratak problema.
                            <br/><br/>
                            <strong> vreme potrebno za uslugu: 30-45 minuta(bez nanošenja keramičke zaštite).</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PoliranjeFarovaPage;