import React from 'react';

const CorporateWashPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Corporate Wash</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Corporate Wash</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Corporate Wash</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            *vizuelna inspekcija vozila<br/>

                            *nanošenje proizvoda za čišćenje na lice felne i gume<br/>

                            *puškiranje potkrila<br/>

                            *puškiranje DEMINERALIZOVANOM vodom<br/>

                            *sigurno ručno pranje(2 bucket method)<br/>

                            *puškiranje DEMINERALIZOVANOM vodom<br/>

                            *detaljno sušenje<br/>

                            *nanošenje dresinga na gume-mat ili sjaj, po želji.<br/>

                            *<b>izduvavanje kompresorom</b> i usisavanje enterijera<br/>
                            *brisanje kontrolne table i centralne konzole<br/>
                            *brisanje stakla<br/>

                            Ova usluga je “ulazni” nivo naše usluge održavanja vozila.<br/>

                            Namenjena je klijentima koji nemaju dovoljno vremena koje bi odvojili za neku od naših
                            detaljnijih usluga,redovni su klijenti, često peru svoja vozila, ili, pak, za one situacije
                            koje su podrazumevaju da je potrebno češće pranje spoljašnjosti: prelazna doba(prolećne ili
                            jesenje kiše,prašina,razna zaprljanja, zimski uslovi vožnje,so,rizla…).<br/>

                            U okviru nje ćemo se posvetiti ponajviše spoljašnjosti Vašeg vozila,dok će unutrašnjost ovaj
                            put biti u drugom planu.<br/>

                            Vozilo će najpre proći vizuelnu inspekciju,a potom ćemo pristupiti radu:<br/>

                            na gume i “lice” felni će biti naneti profesionalni i kvalitetni proizvodi namenjeni
                            maksimalnom efektu čišćenja, proizvodi koji neće oštetiti finiš Vaših(verovatno) skupocenih
                            i voljenih felni, a guma neće postati “suva”,požutela ili,pak, izbledela.<br/>

                            Nakon toga,pristupamo detaljnim ispiranjem felni,guma i podkrila DEMINERALIZOVANOM vodom,
                            koja će sigurno ukloniti nanetu hemiju i NEĆE OSTAVITI KAMENAC, što je problem koji se
                            najlakše uočava na tamnim ili hromiranim/niklovanim felnama.<br/>

                            Kada smo to završili, pristupamo pranju ostatka vozila.<br/>

                            Najpre ćemo naneti AKTIVNU PENU,koja će “omekšati” tvrdokornu nečistoću,tako što će aktivni
                            sastojci u njoj obložiti čestice prljavštine i,pod uticajem gravitacije,povući najveći deo
                            sa sobom dok se spušta niz vozilo.<br/>

                            Potom sledi detaljno ispiranje vozila.<br/>

                            Nakon toga, pristupamo jedinom metodu kontaktnog iliti ručnog pranja koji minimizuje rizik
                            od oštećivanja Vašeg laka-pranja tehnikom koja se u detailingu zove “2 bucket
                            method”(tehnika pranja pomoću dve kante). Ovom tehnikom se obezbeđuje da rizik od grebanja
                            laka, nanošenja novih riseva i sl. bude sveden na apsolutni minimum. Kvalitetan šampon i
                            demineralizovana voda se podrazumevaju.<br/>

                            Sledi ponovno ispiranje demineralizovanom vodom, te nanošenje neke od kvalitetnih, a brzih
                            vidova zaštite, što će proceniti Vaš tehničar.<br/>

                            Fun fact: najveći rizik od oštećenja laka je upravo sledeći korak: sušenje vozila. Zato ćemo
                            mi na Vašem vozilu koristiti najsavremeniju opremu: AIR DRYER, koji će bez ikakvog fizičkog
                            kontakta osušiti Vaš auto velikom brzinom TOPLOG vazduha, a zaostale kapi će se eventualno
                            “pokupiti” premijum peškirima za sušenje vozila.<br/>

                            Za finale-izabraćete da li želite mat ili sjajni dresing za gume, izabraćete parfem koji će
                            vam tehničar naneti u unutrašnjost i- spremni ste da nastavite Vaš dan.
                            <br/>
                            <strong>Procenjeno vreme trajanja usluge: 45 minuta</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CorporateWashPage;