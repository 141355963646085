import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {deleteKlijent, getKlijenti} from "../../apis/KlijentiApi";
import Grid from "@mui/material/Grid2";
import {Alert, Button, LinearProgress, Paper} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tableLang} from "../../assets/language/tableLang";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import {useNavigate} from "react-router-dom";
import Notification from "../../components/Notification";
import DeleteIcon from "@mui/icons-material/Delete";

const AdminKlijentiPage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const {data, isLoading, isError} = useQuery({
        queryKey: ["getKlijenti"],
        queryFn: getKlijenti,
    });

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const del = useMutation({
        mutationFn: (formData) => deleteKlijent(formData),
        onSuccess: (response) => {
            setOpen(false);
            setOpenN(true);
            setType(1);
            setMessage(response.data.message);
            queryClient.invalidateQueries(["getKlijenti"]);
        },
        onError: (error) => {
            setOpenN(true);
            setType(2);
            setMessage(error.response.data.message);
        },
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "ime",
            headerName: "Ime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "prezime",
            headerName: "Prezime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "timestamp",
            headerName: "Vreme",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../usluga/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
        {
            field: "delete",
            headerName: "Brisanje",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="error"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<DeleteIcon/>}
                        onClick={(e) => handleDelete(e, params.row.id)}
                    >
                        Obriši
                    </Button>
                );
            },
        },
    ];

    const handleDelete = (event, value) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('id', value);
        del.mutateAsync(formData).catch((e) => {
        });
    };

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        height: 900,
                    }}
                >
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja klijenata!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                            slots={{toolbar: GridToolbar}}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AdminKlijentiPage;