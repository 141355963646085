import axios from 'axios'
import {url} from './url';

export const getPartneri = async () => {
    return axios.get(url + 'partneri', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const getPartner = async (id) => {
    return axios.get(url + 'partner/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const getAnalitika = async (id, datum) => {
    return axios.get(url + 'analitika/' + id + '/' + datum, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const createPartner = async (formData) => {
    return axios.post(url + 'create-partner', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const deletePartner = async (formData) => {
    return axios.post(url + 'delete-partner', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}