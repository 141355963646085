import React from 'react';

const OdrzavanjeKeramickeZastitePage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Održavanje keramičkih zaštita</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Održavanje keramičkih zaštita</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Održavanje keramičkih zaštita</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            U toku ili nakon izvršenog ECLECTIC WASH-a(u zavisnosti od brenda i potrebnih proizvoda),
                            pristupiće se “hranjenju”,tj boostovanju keramičke zaštite na vozilu,čime ćete osigurati
                            i/ili produžiti njeno trajanje u predviđenom roku, i pojačati ili vratiti svojstva koja ista
                            poseduje, a koja mogu biti umanjena usred nepravilnog održavanja.
                            <br/><br/>
                            U nekom trenutku ste na svoje vozilo naneli keramičku zaštitu, ali sada imate problem u
                            održavanju iste? Pa ,čestitamo, više nemate taj problem, jer kod nas možete održavati svoju
                            investiciju na profesionalan način, upotrebom isključivo proizvoda namenjenih u tu svrhu.
                            <br/><br/>
                            Ne samo to-u zavisnosti od brenda i vrste keramike na kolima, koristićemo samo proizvode
                            koje su ti brendovi i namenili za tu svrhu, u ubaciti ih u rad u toku i/ili nakon našeg
                            ECLECTIC WASH-a, u zavisnosti od vrste proizvoda i načina apliciranja koji je predviđen.
                            <br/><br/>
                            To znači da,ukoliko je u pitanju brend koji nije u našoj ponudi, ipak možemo uraditi redovno
                            održavanje premaza.
                            <br/><br/>
                            Detailing Studio Eclectic je sertifikovan od strane proizvođača keramičkih zaštita
                            SONAX-a,DREXLER CERAMIC-a,koristimo i proizvode brendova GYEON i FX PROTECT-a, a ukoliko je
                            zaštita nekog drugog proizvođača-u najskorije vreme ćemo ga imati u ponudi.
                            <br/><br/>
                            Vaše je samo da nam unapred napomenete brend,u slučaju da trenutno nije u ponudi, i da imate
                            poverenja u ljude koji se ovime bave preko decenije.
                            <br/><br/>
                            Ukoliko,pak, nemate keramičku zaštitu na vozilu a razmišljate o toj usluzi- u mogućnosti ste
                            da zakažete termin u našem detailing studiju, i da budete bezbrižni po pitanju održavanja
                            iste.
                            <br/><br/>
                            <strong>Procenjeno vreme za održavanje keramičkih zaštita SONAX,DREXLER,GYEON,FX PROTECT:
                                oko 30min (dodatno na ECLECTIC WASH)</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OdrzavanjeKeramickeZastitePage;