import React from 'react';

const NanosenjeVoskaIliSilerPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Nanošenje voska ili silera na lak</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Nanošenje voska ili silera na lak</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Nanošenje voska ili silera na lak</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            U ponudi imamo i uslugu nanošenja još dve vrste zaštite:voska sa KARNAUBOM i SILERA.
                            <br/><br/>
                            Vosak sa karnaubom daje veliku refleksiju,hidrofobnost,dubinu boji i popunjava riseve u
                            laku. Do pojave keramičkih zaštita je bio nedostižan u ovim sferama.
                            <br/><br/>
                            Njegova “mana”: trajnost, Traje oko mesec dana.
                            <br/><br/>
                            Vosak nanosimo nakon LIGHT ili ECLECTIC pranja, mašinski, na sve lakirane delove i svetlosne
                            grupe, uklanja se ručno.
                            <br/><br/>
                            Predlažemo ga za tamnije boje.
                            <br/><br/>
                            Dugotrajnija opcija su svakako SILERI, sintetički proizvodi,koji nude sve kao i vosak, s tim
                            da im je trajnost oko 6 meseci. Nanose se nakon LIGHT ili ECLECTIC pranja, a uklanjaju
                            ručno.
                            <br/><br/>
                            Naša preporuka je za klijente koji žele da zaštite svoja vozila na srednji rok ili sezonu.
                            <br/><br/>
                            Vaše je da odaberete koju vrstu zaštite želite, a sam izbor prepustite tehničarima, koji će
                            odabrati proizvod koji će postići najbolji efekat za Vaše vozilo.
                            <br/><br/>
                            <strong>Procenjeno vreme za uslugu: 30min( za vosak), oko 1h(za siler).</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NanosenjeVoskaIliSilerPage;