import React from 'react';

const UklanjanjeFlekaPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Uklanjanje fleka</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Uklanjanje fleka</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Uklanjanje fleka</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            <strong>NAPOMENA:</strong> u okviru auto-perionice ECLECTIC, moguće je uklanjanje fleka samo
                            sa pojedinačnih delova enterijera. To znači da Vam možemo očistiti deo koji zahteva
                            tretman,i to hemijskim čišćenjem dela,pod uslovom da fleka nije od goriva,ulja i slično.
                            <br/><br/>
                            Takodje, u auto perionici ne vršimo sušenje dela, sto ne treba da Vas brine, jer će usluga
                            biti sprovedena tako da se minimalno kvasi materijal.
                            <br/><br/>
                            U slučaju velikih zaprljanja, upornih fleka koje traže dubinsko i/ili henijsko čišćenje,
                            ili,jednostavno, odlučite da detaljno očistite enterijer od mebla ili kože–pozivamo Vas da
                            zakažete termin detaljnog pranja enterijera u našem detailing studiju,gde ćemo se posvetiti
                            svakom centimetru enterijera i dovesti ga u fabričko stanje.
                            <br/><br/>
                            U okviru ove usluge, problematični deo će biti detaljno usisan, a okolina zaštićena da bi se
                            sprečilo nepotrebno kvašenje. Zatim ćemo naneti profesionalno sredstvo za čišćenje,
                            istrljati ga četkom, i dodatno preći TORNADOROM. Zatim ćemo mikrofiber krpama istrljati
                            tretiranu površinu, “pokupiti” nečistoće i vlagu. poslednji korak je dodatno sušenje
                            tornadorom.
                            <br/><br/>
                            Ostatak vlage u najvećem broju slučaja ne predstavlja opasnost-neće je biti dovoljno da se
                            enterijer, npr, oseti na vlagu ili ubuđa, ali svakako preporučujemo da se uradi jedna od
                            sledećih stvari:upali grejanje u toku vožnje i usmeri vazduh na površinu, auto ostavi na
                            suncu 15tak minuta, i u ovom slučaju tretirana površina je suva za jako kratko vreme.
                            <br/><br/>
                            <strong>Procenjeno vreme za pružanje usluge: 10-20 minuta</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UklanjanjeFlekaPage;