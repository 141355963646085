import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getWashCenovnik} from "../../../apis/UslugaApi";
import {useQuery} from "@tanstack/react-query";
import {Alert, LinearProgress} from "@mui/material";

const CarWashCenovnikPage = () => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ['washCenovnik'],
        queryFn: getWashCenovnik
    });

    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Cenovnik - Car Wash</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Car Wash - Cenovnik</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="prices">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Cenovnik</p>
                        <h2>Car Wash</h2>
                    </div>
                    {isLoading ? <LinearProgress size={24}/> :
                        <div className="row">
                            {data?.data.length == 0 ? (
                                <Alert sx={{width: '100%', textAlign: 'center', fontWeight: 'bold'}} severity="warning">Nema
                                    usluga na cenovniku
                                    trenutno!</Alert>
                            ) : data?.data.map((item, index) => (
                                <Accordion sx={{
                                    width: '100%', "&:hover": {
                                        backgroundColor: "#FC9303", // Changes background color on hover
                                        transform: "scale(1.05)", // Slight zoom effect
                                        transition: "all 0.3s ease", // Smooth transition
                                    },
                                }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls={"panel" + index + "-content"}
                                        id={"panel" + index + "-header"}
                                        sx={{fontSize: 20}}
                                    >
                                        <b>{item.naziv} | {item.cena.toFixed(2)} RSD | Trajanje: {item.trajanje} min</b>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            <ol>
                                                {JSON.parse(item.opis).map((item, index) => (
                                                    <li>{item}</li>
                                                ))}
                                            </ol>
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default CarWashCenovnikPage;