import React from 'react';

const CiscenjeKoznihElemenataPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Čišćenje kožnih elemenata</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Čišćenje kožnih elemenata</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Čišćenje kožnih elemenata</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            *izduvavanje nečistoća<br/>

                            *detaljno čišćenje kožnih delova premijum proizvodima<br/>

                            *brisanje mikrofiber krpama<br/>

                            *sušenje<br/>

                            *nanošenje proizvoda za prehranu kože.<br/>

                            Luksuzan i estetski privlačan materijal poput kože , nasuprot uvreženom mišljenu, zahteva
                            kvalitetnije i redovnije održavanje od ostalih materijala. Ljudi su skloni shvatanju:”koža
                            je, izdrživija je i zahvalnija od štofa, ne mora se održavati”.<br/>

                            Zapitajte se-koliko puta je neko pohvalio sjaj kožnog sedišta,”specifičan miris kože”,
                            koliko puta ste videli da je boja sa farmerki obojila svetli kožni enterijer,koliko ste puta
                            videli iskrzanu ili ispucalu kožu?<br/>

                            Sada ćemo podeliti sa vama istinu-čista i negovana koža nema sjaj, nego mat odsjaj. Nema
                            miris ni na šta drugo sem na samu kožu. pod rukom mora biti suptilna i mora se lagano
                            ugibati. Sa čistom i prehranjenom kožom treba da skliznete u sediste, ne da zapinjete ,
                            takva koža ne može biti krta,ali, u isto vreme-pod rukom ne treba da bude masna.<br/>

                            Sve to ćemo vam omogućiti, uslugom kvalitetnog čišćenja i prehrane kožnog
                            enterijera:sedišta,naslona za ruku,kožnih elemenata u okviru vrata.<br/>

                            Samostalno ili u sklopu neke naše usluge.<br/>

                            Najpre ćemo izduvati sve spojeve sedišta od nečistoća koje su tu zapale(a koje su krivci za
                            pucanje šavova na spojevima, verovali ili ne), te pristupiti njihovom detaljnom čišćenju,
                            koristeći proverene proizvode,razne četkice i mikrofiber krpe.<br/>

                            Kada uklonimo nečistoce, kožu ćemo još jednom prebrisati, osušiti i naneti premijum
                            proizvode za održavanje kože , koji će je nahraniti, vratiti mekoću i sprečiti prevremeno
                            isušivanje, suptilnost,mat sjaj, i miris prave kože u Vaše vozilo.<br/>

                            Jedva čekamo nama poznatu reakciju:” nije bila prljava, a 3 nijanse drugačija boja”. Ne, to
                            je ista koža, u nijansi u kojoj je izašla iz fabrike, u stanju u kakvom treba da bude. Samo
                            je došla kod nas na tretman.<br/>

                            Ukoliko je koža relativno nova ili u dobrom stanju,opciono, uz doplatu, možemo Vam naneti
                            KERAMIČKU ZAŠTITU ZA KOŽU, koja će biti svojevrsna barijera protiv lakog transfera boje,
                            isušivanja, sprečiće prodor nečistoća u pore, biće barijera prilokom prolivene
                            tečnosti…<br/>

                            Ukoliko je,pak, koža u lošijem stanju ili starija a zanemarena-preporučujemo vam da ostanete
                            pri proizvodima za prehranu kože-od njih ćete imati veće benefite.<br/>

                            Naša preporuka je da se ovaj tretman odradi barem 1 godišnje, idealno 2 puta godišnje.<br/>

                            Procenjeno vreme potrebno za uslugu: 30-60min<br/>

                            NAPOMENA

                            U sklopu perionice Vam možemo očistiti i prehraniti navedene kožne delove, ali u uslugu
                            ulaze samo oni.<br/>

                            Ukoliko vam je želja da se detaljno očisti ili keramički zaštiti kompletan
                            enterijer-zakažite ili nas pitajte za uslugu detaljnog pranja i čišćenja enterijera u našem
                            detailing studiju.<br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CiscenjeKoznihElemenataPage;