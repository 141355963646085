import axios from 'axios'
import {url} from './url';

export const getNeradniDani = async () => {
    return axios.get(url + 'neradni-dani', {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export const createNeradniDani = async (formData) => {
    return axios.post(url + 'create-neradni-dan', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}