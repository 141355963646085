import axios from 'axios'
import {url} from './url';

export const createMailingCampaign = async (formData) => {
    return axios.post(url + 'create-newsletter', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}