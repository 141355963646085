import React from 'react';

const PravoNaOdustajanjePage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Pravo na odustajanje</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Pravo na odustajanje</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Uslovi</p>
                        <h2>Pravo na odustajanje</h2>
                    </div>
                    <div className="row" id="post-div">
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>Zahvaljujemo Vam na izvršenoj kupovini.</p>

                            <p>Obaveštavamo Vas da imate pravo do odustanete od kupovine bez posebnog obrazloženja u
                                roku od 14 dana od dana kada Vam je proizvod isporučen. To možete da učinite
                                popunjavanjem i slanjem ovog obrasca za odustanak, zajedno sa neoštećenim i nekorišćenim
                                proizvodima u originalnoj ambalaži i koji sadrže sve dodatke i propratnu dokumentaciju
                                (garantni list, uputstva za upotrebu i slično), kao i originalni račun (odštasmpan na
                                drugoj strani ovog papira) poštom na adresu Eclectic-a ili odlaskom u jednu od Eclectic
                                lokacija.</p>

                            <p>
                                Obrazac za odustajanak od kupovine mora da bude poslat Eclectic-u najkasnije u roku od
                                14 dana od dana kada je proizvod dospeo u Vašu, odnosno državinu trećeg lica koje ste
                                odredili za primaoca pošiljke.<br/>
                                U slučaju odustanka od kupovine Vi snosite troškove vraćanja proizvoda i dužni ste da u
                                roku od 14 dana od dana odustanka vratite Eclectic-u kupljene proizvode.
                            </p>

                            <p>Povraćaj uplaćenog novca ćemo izvršiti na Vaš tekući račun naveden u obrascu za odustanak
                                od ugovora.</p>

                            <p>Molimo Vas da prilikom preuzimanja pošiljke pažljivo i detaljno pregledate proizvod kako
                                biste se uverili da je isti kompletan i neoštećen, jer naknadne reklamacije po tom
                                osnovu neće biti prihvaćene od strane Eclectic-a.</p>

                            <p>Obaveštavamo Vas da je saglasno zakonu Eclectic odgovoran za nesaobraznost proizvoda
                                ugovoru koja se pojavi u roku od dve godine od dana isporuke proizvoda Vama ili trećem
                                licu koje ste odredili kao primaoca pošiljke.</p>

                            <p>Reklamacije na kupljene proizvode možete da izjavite u jednoj od naših prodavnica,
                                elektronskim putem na email adresu: reklamacije@eclectic.rs ili preko broja telefona:
                                063 1048 518. Prilikom izjavljivanja reklamacije dužni ste da dostavite robu na koju se
                                reklamacija odnosi, račun na uvid ili drugi dokaz o kupovini tih proizvoda (kopija
                                računa, slip i sl.), kao i garantni list ukoliko je reč o proizvodima sa garancijom.
                                Prilikom dostavljanja proizvoda niste dužni da dostavite ambalažu.</p>

                            <p>Po prijemu reklamacije izdaćemo Vam pismenu potvrdu ili ćemo Vas elektronskim putem
                                obavestiti da potvrđujemo da smo primili Vašu reklamaciju, odnosno saopštićemo Vam broj
                                pod kojim je ona zavedena u evidenciji primljenih reklamacija. Najkasnije u roku od osam
                                dana od dana prijema reklamacije, pismenim ili elektronskim putem odgovorićemo Vam na
                                izjavljenu reklamaciju. Odgovor će sadržati odluku da li prihvatamo reklamaciju,
                                izjašnjenje o Vašem zahtevu i konkretan predlog i rok za rešavanje reklamacije koji ne
                                može biti duži od 30 dana od dana podnošenja reklamacije.</p>

                            <p>Više o uslovima kupovine proizvoda možete naći na veb sajtu: www.eclectic.rs u dokumentu
                                pod nazivom: “Uslovi korišćenja Veb-sajta”.</p>

                            <p>Vaš Eclectic</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PravoNaOdustajanjePage;