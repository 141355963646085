import React from 'react';
import Grid from "@mui/material/Grid2";
import {
    Button,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {getTermin} from "../../../apis/TerminApi";

const TerminInfoPage = () => {
    const {id} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['termin', id],
        queryFn: () => getTermin(id)
    });

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {isLoading ? (
                        <LinearProgress size={24}/>
                    ) : (
                        <TableContainer sx={{mt: 5}} component={Paper}>
                            <Table aria-label="reservation-information">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Informacija</TableCell>
                                        <TableCell align="right">Vrednost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        key={1}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Šifra
                                        </TableCell>
                                        <TableCell align="right">{data?.data.sifra}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={2}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Ime
                                        </TableCell>
                                        <TableCell align="right">{data?.data.ime}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={3}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Prezime
                                        </TableCell>
                                        <TableCell align="right">{data?.data.prezime}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={4}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Email
                                        </TableCell>
                                        <TableCell align="right">{data?.data.email}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={5}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Telefon
                                        </TableCell>
                                        <TableCell align="right">{data?.data.telefon}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={6}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Registracija
                                        </TableCell>
                                        <TableCell align="right">{data?.data.registracija}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={7}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Cena
                                        </TableCell>
                                        <TableCell align="right">{data?.data.cena} RSD</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={8}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Broj mesta
                                        </TableCell>
                                        <TableCell align="right">{data?.data.mesto}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={9}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Status
                                        </TableCell>
                                        <TableCell align="right"><b
                                            style={{color: 'green'}}>ZAKAZANO</b></TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={12}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Plaćeno
                                        </TableCell>
                                        <TableCell align="right">
                                            {data?.data.placeno == 0 ?
                                                <span style={{color: 'red'}}>NIJE PLAĆENO</span> :
                                                <span style={{color: 'green'}}>PLAĆENO</span>
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={10}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Datum
                                        </TableCell>
                                        <TableCell align="right">{data?.data.datum}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={11}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Vreme
                                        </TableCell>
                                        <TableCell align="right">{data?.data.vreme}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={12}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            Usluga
                                        </TableCell>
                                        <TableCell align="right">{data?.data.naziv}</TableCell>
                                    </TableRow>
                                    {data?.data.promo_kod != 'null' ? (
                                        <TableRow
                                            key={13}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                Promo kod
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button variant="contained"
                                                        onClick={() => console.log("promo kod")}>{data?.data.promo_kod}</Button>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <TableRow
                                            key={13}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                Promo kod
                                            </TableCell>
                                            <TableCell align="right">
                                                NIJE KORIŠĆEN PROMO KOD.
                                            </TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default TerminInfoPage;