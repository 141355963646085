import React from 'react';

const PolitikaPrivatnostiPage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Politika privatnosti</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Politika privatnosti</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Naša politika privatnosti</p>
                        <h2>Politika privatnosti</h2>
                    </div>
                    <div className="row" id="post-div">
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p><strong>OBAVEŠTENJE O OBRADI PODATAKA O LIČNOSTI </strong></p>

                            <p>Eclectic doo popravka i održavanje motornih vozila Beograd (u daljem tekstu:
                                Eclectic&nbsp; ili mi) izdaje ovo obaveštenje o obradi podataka o ličnosti (u daljem
                                tekstu: Obaveštenje) kako biste Vi kao korisnik veb-sajta: www.eclectic.rs (u daljem
                                tekstu: Veb-sajt) bili upoznati sa svim važnim informacijama u vezi sa obradom Vaših
                                ličnih podataka, njihovom zaštitom i pravima koja Vam po tom osnovu pripadaju u skladu
                                sa Zakonom o zaštiti podataka o ličnosti „Službeni glasnik RS“, br. 87/2018 (u daljem
                                tekstu: ZZPL).</p>

                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

                            <p><strong>Značenje izraza „podatak o ličnosti“</strong></p>

                            <p>U skladu sa ZZPL-om "podatak o ličnosti" (u daljem tekstu: podatak o ličnosti ili lični
                                podatak) je svaki podatak koji se odnosi na fizičko lice čiji je identitet određen ili
                                odrediv, neposredno ili posredno, posebno na osnovu oznake identiteta, kao što je ime i
                                identifikacioni broj, podataka o lokaciji, identifikatora u elektronskim komunikacionim
                                mrežama ili jednog, odnosno više obeležja njegovog fizičkog, fiziološkog, genetskog,
                                mentalnog, ekonomskog, kulturnog i društvenog identiteta.</p>

                            <p>&nbsp;</p>

                            <p><strong>Rukovalac </strong></p>

                            <p>Vaše lične podatke prikuplja i njihovom obradom upravlja:</p>

                            <p>Eclectic doo popravka i održavanje motornih vozila Beograd</p>

                            <p>Venizelosova 15</p>

                            <p>11000 Stari Grad</p>

                            <p>MB: 21792713</p>

                            <p>PIB: 113040488</p>

                            <p>Email: office@eclectic.rs</p>

                            <p>&nbsp;</p>

                            <p><strong>Vrsta podataka i način njihove obrade</strong></p>

                            <p>Prikupljamo samo one podatke koje nam Vi dobrovoljno date i koji su nužni za ostvarivanje
                                svrhe obrade.</p>

                            <p>U zavisnosti od svrhe nameravane obrade, vrste podataka o ličnosti koje obrađujemo
                                obuhvataju sledeće: ime, prezime, jedinstveni matični broj građana, broj važeće lične
                                karte ili pasoša, datum rođenja, podatke o Vašim transakcijama (plaćanju, kupovinama,
                                narudžbinama, povraćajima proizvoda itd.) i Vaše kontakt podatke (broj fiksnog ili
                                mobilnog telefona), email adresu, adresu stanovanja odnosno adresu za isporuku
                                proizvoda).</p>

                            <p>Podatke prikupljamo i obrađujemo putem našeg Veb-sajta, na društvenim mrežama „Facebook“
                                i „Instagram“.&nbsp;</p>

                            <p>&nbsp;</p>

                            <p><strong>Svrha obrade Vaših podataka</strong></p>

                            <p>Eclectic prikuplja i obrađuje Vaše lične podatke u sledeće svrhe:</p>

                            <ul>
                                <li>upravljanja Vašom registracijom kao korisnika Veb-sajta;</li>
                                <li>omogućavanja članstva u Eclectic Privilege Club-u;</li>
                                <li>izvršenja kupoprodajnog ugovora za proizvode koje ste kupili ili bilo kojeg drugog
                                    ugovora između Vas i Eclectic-a;
                                </li>
                                <li>rešavanja Vaših zahteva;</li>
                                <li>pružanja informacija o proizvodima iz prodajnog asortimana Eclectic-a i njihovog
                                    reklamiranja putem obaveštenja odnosno newsletter-a (osim ako ne zahtevate da budete
                                    uklonjeni sa liste primalaca obaveštenja odnosno newsletter-a) i
                                </li>
                                <li>unapređenja naših usluga.</li>
                            </ul>

                            <p>U slučaju da nam date lične podatke trećih lica garantujete da ste ih obavestili o
                                upotrebi njihovih ličnih podataka. U slučaju da ste kupili proizvod ili Gift karticu,
                                lični podaci trećih lica koje ste nam dali biće upotrebljeni isključivo u svrhu
                                sprovođenja isporuke i rešavanja upita ili predloga koje možete imati Vi ili treće
                                lice.</p>

                            <p>&nbsp;</p>

                            <p><strong>Pravni osnov obrade Vaših ličnih podataka</strong></p>

                            <p>U zavisnosti od svrhe prikupljanja i obrade, pravni osnov za obradu Vaših ličnih podataka
                                može da bude Vaš pristanak (saglasnost), izvršenje ugovora odnosno usluge koju ste
                                tražili, poštovanje zakonskih propisa koji nalažu obradu Vaših ličnih podataka ili
                                legitimni (opravdani) interesi Eclectic-a, osim ako su nad tim interesima pretežniji
                                interesi i osnovna prava i slobode Vaši odnosno lica na koje se podaci odnose koji
                                zahtevaju zaštitu podataka o ličnosti, a posebno ako je lice na koje se podaci odnose
                                maloletno.</p>

                            <p>Na primer, Vaše podatke kao što su ime i prezime, adresa, broj telefona, email adresa
                                možemo da dobijemo od Vas kada se prijavljujete kao korisnik Veb-sajta ili nas
                                kontaktirate email-om, poštom ili na drugi način i da iste obrađujemo u svrhu
                                registracije, rešavanja reklamacije, kupovine proizvoda i dr. U svakom od ovih slučajeva
                                podatke obrađujemo na osnovu Vašeg pristanka ili našeg legitimnog interesa s obzirom na
                                to da ste nam se neposredno obratili ili zbog nužnosti izvršenja ugovora odnosno usluge
                                koju ste tražili (tačnije, ako želite da se registrujete kao korisnik Veb-sajta mi
                                moramo da obradimo Vaše lične podatke, jer u protivnom ne možemo da upravljamo Vašom
                                registracijom ili izvršimo Vašu narudžbinu).</p>

                            <p>Ukoliko koristite Veb-sajt, registrujete se kao korisnik Veb-sajta ili se prijavite u naš
                                Eclectic Privilege Club povodom čega nam poveravate podatke o Vašoj email adresi ili
                                adresi stanovanja tada, rukovođeni našim legitimnim interesom, smatrajući da ste
                                zainteresovani za naše dalje ponude, možemo da Vam šaljemo podsetnike i obaveštenja. U
                                svakom trenutku slanje obaveštenja i ponuda možete onemogućiti jednostavnim klikom na
                                odjavu, nakon čega Vas više nećemo kontaktirati.</p>

                            <p>Vaši lični podaci koji se prikupljaju i obrađuju u svrhu članstva u Eclectic Privilege
                                Club-u obrađuju se isključivo na osnovu Vašeg pristanka. Pristanak za prikupljanje i
                                obradu Vaših ličnih podataka u svrhu članstva u Eclectic Privilege Club-u dajete
                                prilikom kupovine preko Veb-sajta odnosno prilikom kupovine u bilo kojoj našim
                                prodavnicama i možete da ga opozovete u svakom trenutku.</p>

                            <p>Pristanak od Vas tražimo i u slučaju kada želimo da Vam pošaljemo materijale namenjene
                                direktnom oglašavanju. Nakon opoziva pristanka Vaše podatke ni na koji način ne
                                obrađujemo, ali&nbsp; moramo da ih sačuvamo samo da bismo u slučaju potrebe dokazali da
                                je Vaš pristanak za dalju obradu opozvan i da je obrada pre opoziva pristanka bila
                                zakonita.</p>

                            <p>Odjavu sa mail liste ili opoziv pristanka za prikupljanje i obradu Vaših ličnih podataka
                                u svakom trenutku možete da izvršite na jedan od sledećih načina: slanjem email-a na
                                adresu: <a href="mailto:office@eclectic.rs">office@eclectic.rs</a> ili slanjem pisma
                                Eclectic doo popravka i održavanje motornih vozila Beograd, Stopićeva ulica 6, 11070
                                Novi Beograd.</p>

                            <p>&nbsp;</p>

                            <p><strong>Rok čuvanja podataka o ličnosti</strong></p>

                            <p>Eclectic pohranjuje i čuva Vaše podatke samo onoliko dugo koliko je to potrebno radi
                                ostvarivanje svrhe obrade za koju su prikupljeni, i to:</p>

                            <ul>
                                <li>lične podatke koje obrađujemo u svrhu upravljanja Vašom registracijom kao korisnika
                                    Veb-sajta i u svrhu članstva u Eclectic Privilege Club-u pohranjujemo, čuvamo i
                                    obrađujemo za sve vreme koje ste registrovani kao korisnik i/ili član odnosno do
                                    Vaše odjave;
                                </li>
                                <li>za izvršenje kupoprodajnog ugovora za proizvode koje ste kupili ili bilo kojeg
                                    drugog ugovora između Vas i Eclectic-a podatke pohranjujemo, čuvamo i obrađujemo
                                    onoliko dugo koliko je to potrebno da bi se kupovina proizvoda koje ste kupili
                                    sprovela do kraja uključujući i vreme potrebno da se postupi po prigovorima ili
                                    zahtevima koji se odnose na kupovinu proizvoda, osim ukoliko ne zahtevate da se Vaši
                                    podaci izbrišu pre isteka navedenih rokova (u kom slučaju će oni, ukoliko brisanje
                                    zatražite pre isteka ugovornog odnosa, biti pseudonimizirani);
                                </li>
                                <li>prilikom Vašeg obraćanja i Vaših upita, Vaše lične podatke zadržavamo u roku
                                    potrebnom za obradu upita odnosno dok ne zatražite njihovo brisanje;
                                </li>
                                <li>Vaše lične podatke koje obrađujemo u svrhu dostavljanja podsetnika i obaveštenja ili
                                    direktnog oglašavanja zadržavamo sve dok ne izvršite odjavu s naše mail liste
                                    odnosno dok ne opozovete prisatnak i
                                </li>
                                <li>prilikom pohranjivanja cookies-a (kolačića) na Vašem računaru, Vaše lične podatke
                                    zadržavamo dok služe svrsi.
                                </li>
                            </ul>

                            <p>Vaše lične podatke možemo da čuvamo i nakon što prestanete da koristite usluge
                                Eclectic-a&nbsp; ili prestanete da koristite Veb-sajt u svrhu
                                izvršavanja&nbsp; zakonskih obaveza ili zaštite prava Eclectic-a (pred sudovima ili
                                drugim nadležnim organima), u skladu sa važećim propisima ili prikupljanja statističkih
                                podataka i vođenja evidencija.</p>

                            <p>Vaši lični podaci koji se ne koriste više ni za jedan od navedenih zakonitih načina
                                obrade, Eclectic će izbrisati iz svojih sistema za pohranjivanje i čuvanje ili
                                pseudonimizirati.&nbsp;</p>

                            <p>&nbsp;</p>

                            <p><strong>Ko ima pristup Vašim ličnim podacima - primaoci</strong></p>

                            <p>U zavisnosti od svrhe obrade Vaših ličnih podataka definisanih ovim Obaveštenjem i u meri
                                u kojoj je to nužno radi njenog ostvarivanja Vaši lični podaci su dostupni zaposlenima u
                                Eclectic-u i honorarnim saradnicima (koji se obavezuju na čuvanje poverljivosti Vaših
                                ličnih podataka kao profesionalne tajne) i pouzdanim trećim licima koja nam pružaju
                                usluge u vezi sa uslugama koje mi Vama nudimo i to:</p>

                            <ul>
                                <li>IT agencijama odnosno licima koja nam pružaju pomoć u vidu platforme ili smeštaja na
                                    serveru, podrške i održavanja baze podataka, kao i softvera i aplikacija;
                                </li>
                                <li>licima koja nam pružaju usluge isporuke proizvoda (usluge pošte, kurirske službe)
                                    i
                                </li>
                                <li>licima koja nam pružaju usluge u vezi sa podrškom kupcima.</li>
                            </ul>

                            <p>Svi primaoci su obavezni da preduzmu odgovarajuće tehničke, organizacione i kadrovske
                                mere kako bi zaštitili Vaše lične podatke. U slučajevima kada smo mi rukovaoci i obradu
                                podataka po našem nalogu vrši neko treće lice kao obrađivač, odnose sa obrađivačima
                                regulišemo ugovorima kojima se&nbsp; uređuju svi bitni aspekti obrade podataka o
                                ličnosti kao i mere zaštite. Zaštita Vaših ličnih podataka od strane tih trećih lica
                                mora da bude na istom nivou kao i zaštita koju garantuje Eclectic.</p>

                            <p>Vaše lične podatke možemo da otkrijemo i državnim organima kada smo na to obavezani
                                zakonom odnosno kada smo to dužni da učinimo u skladu sa propisima ili u cilju zaštite
                                naših prava i interesa i prava Vas kao kupaca.</p>

                            <p>&nbsp;</p>

                            <p><strong>Vaša prava u vezi sa ličnim podacima koje obrađujemo</strong></p>

                            <p>Obavezujemo se da ćemo čuvati Vaše lične podatke kao poverljive i da ćemo Vam omogućiti
                                da ostvarite prava koja imate po ZZPL-u.</p>

                            <p>Vaša prava u vezi sa obradom podataka o ličnosti su:</p>

                            <ul>
                                <li>pravo da tražite kopiju podataka koji se obrađuju;</li>
                                <li>pravo pristupa (imate pravo da dobijete jasne, transparentne i lako razumljive
                                    informacije o svrsi obrade, vrstama podataka o ličnosti koji se obrađuju, o primaocu
                                    ili vrstama primalaca kojima su podaci o ličnosti otkriveni ili će biti otkriveni,
                                    kao i o predviđenom roku čuvanja podataka o ličnosti);
                                </li>
                                <li>pravo na ispravku i/ili dopunu (imate pravo da zahtevate ispravku Vaših ličnih
                                    podatka ako su oni neispravni ili nevažeći i/ili njihovo dopunjavanje ako su
                                    nepotpuni);
                                </li>
                                <li>pravo na brisanje (imate pravo da tražite da se Vaši lični podaci izbrišu u meri u
                                    kojoj oni više nisu potrebni za svrhe obrade ili kada Eclectic-u zakon ne dozvoljava
                                    da ih više obrađuje);
                                </li>
                                <li>pravo na prigovor i pritužbu (imate pravo prigovora na način obrade Vaših ličnih
                                    podataka koji možete podneti direktno Eclectic-u ilikoju podnosite Povereniku za
                                    informacije od javnog značaja i zaštitu podataka o ličnosti);
                                </li>
                                <li>pravo na ograničenje obrade (u svakom trenutku imate pravo da tražite ograničenje
                                    obrade Vaših ličnih podataka);
                                </li>
                                <li>pravo na opoziv pristanka na obradu ličnih podataka koja se zasniva na pristanku u
                                    bilo kojem trenutku i
                                </li>
                                <li>pravo na prenosivost ličnih podataka (imate pravo preuzimanja ličnih podataka iz
                                    naše baze u drugu bazu podataka ili pravo da zahtevate da mi to za Vas učinimo, ali
                                    samo pod uslovom da se radi o ličnim podacima koji se obrađuju na osnovu ugovora ili
                                    pristanka i ako se obrada vrši automatizovano).
                                </li>
                            </ul>

                            <p>Ukoliko želite da ostvarite napred navedena prava (osim pritužbe Povereniku) to možete da
                                učinite na sledeće načine: popunjavanjem i predajom <u>Zahteva za ostvarivanje prava u
                                    vezi sa obradom podataka o ličnosti</u> na Veb-sajtu ili u bilo kojoj&nbsp; od naših
                                prodavnica ili slanjem Zahteva za ostvarivanje prava u vezi sa obradom podataka o
                                ličnosti ili zahteva sastavljenog u slobodnoj formi email-om na adresu: <a
                                    href="mailto:office@eclectic.rs">office@eclectic.rs</a> ili poštom na Eclectic doo
                                popravka i održavanje motornih vozila Beograd, Stopićeva ulica 6, 11070 Novi Beograd</p>

                            <p>&nbsp;</p>

                            <p><strong>Korišćenje kolačića</strong></p>

                            <p>Kolačići su tekstualni dokumenti koji se čuvaju na Vašem uređaju, a nastaju dok
                                posećujete Veb-sajt. Kada ponovo budete pristupili Veb-sajtu Eclectic može preuzeti
                                podatke sa Vašeg uređaja, a koji su sadržani u kolačićima.</p>

                            <p>Na Veb-sajtu koristimo više vrsta kolačića koji se razlikuju po svojoj svrsi, dužini
                                trajanja itd.</p>

                            <p>Na primer, prilikom posete Veb-sajtu radi pretraživanja, čitanja ili preuzimanja
                                informacija, od Vas prikupljamo i pohranjujemo neke podatke, kao što su: naziv domena i
                                računara sa kojeg pristupate internetu, adresu internet protokola (IP) računara koji
                                koristite, datuma i vremena pristupanja Veb-sajtu, internet adrese stranica preko kojih
                                ste pristupili Veb-sajtu.</p>

                            <p>Pomoću kolačića omogućava Vam se bolje, pristupačnije i prilagođenije korisničko
                                iskustvo, kao i prikaz Veb-sajta, a Eclectic pomoću njih analizira kupce, prati posete i
                                unapređuje svoje oglašavanje.</p>

                            <p>Kolačići mogu poticati i sa drugih veb-sajtova koje posećujete na kojima se
                                Eclectic&nbsp; reklamira, a mogu biti pohranjivani na Vašem uređaju od strane spoljnih
                                servisa čije usluge Eclectic koristi i koji mu služe da prati korišćenje interneta u
                                marketinške svrhe.</p>

                            <p>Isključivanjem kolačića odlučujete da li ćete da dozvolite njihovo pohranjivanje na Vašem
                                uređaju. Podešavanja željenih postavki u vezi sa kolačićima možete izvršiti u Vašem
                                internet pretraživaču.</p>

                            <p>Više o kolačićima pročitajte ovde <a href="/kolačići"><u>Obaveštenje o
                                kolačićima.</u></a></p>

                            <p>&nbsp;</p>

                            <p><strong>Bezbednost Vaših ličnih podatka</strong></p>

                            <p>U cilju bezbednosti Vaših ličnih podataka koje obrađujemo, uvek nastojimo da primenjujemo
                                neophodne standarde u zaštiti podataka o ličnosti&nbsp; sve neophodne tehničke,
                                organizacione i kadrovske mere zaštite u skladu sa zahtevima ZZPL-a, uključujući mere
                                zaštite informacione bezbednosti Vaših podataka u skladu sa važećim propisima i ostale
                                mere koje su primerene obradi i nužne za obezbeđivanje zaštite konkretnih podataka o
                                ličnosti.</p>

                            <p>Treća lica koja obrađuju Vaše lične podatke koje prikuplja i čijom obradom upravlja
                                Eclectic&nbsp; takođe imaju obavezu da primenjuju sve potrebne tehničke, organizacione i
                                kadrovske mere kako bi se i sa njihove strane obezbedio isti nivo zaštite koji pruža i
                                garantuje Eclectic.</p>

                            <p>&nbsp;</p>

                            <p><strong>Završne odredbe </strong></p>

                            <p>Ovo Obaveštenje ima za cilj da Vam pruži sve potrebne informacije za davanje ili
                                uskraćivanje pristanka za obradu Vaših ličnih podataka na napred opisan način.</p>

                            <p>
                                Ukoliko imate bilo kakvih pitanja u vezi sa zaštitom Vaših ličnih podataka, o
                                tome kako postupamo sa Vašim ličnim podacima i kako ih upotrebljavamo ili hoćete da
                                ostvarite prethodno navedena prava u vezi sa Vašim ličnim podacima molimo Vas da nas
                                kontaktirate u toku radnog vremena centralne kancelarije (radnim danom 8-16h)
                                telefonom na&nbsp; +381692103985, slanjem emaila na adresu: <a
                                href="mailto:office@eclectic.rs">office@eclectic.rs</a>
                                ili slanjem pisma poštom na adresu sedišta: Eclectic doo popravka i
                                održavanje motornih vozila Beograd, Venizelosova 15, 11000 Stari Grad.
                            </p>

                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolitikaPrivatnostiPage;