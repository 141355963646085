import React from 'react';
import {useMutation} from "@tanstack/react-query";
import {createPoruka} from "../../apis/PorukaApi";
import {Alert, Button, IconButton, Snackbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const KontaktPage = () => {
    const [type, setType] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const mutation = useMutation({
        mutationFn: (formData) => createPoruka(formData),
        onSuccess: (response) => {
            setType(0);
            setMessage(response.data.message);
            setOpen(true);

            const form = document.getElementById("contact-form");
            if (form) {
                form.reset();
            }
        },
        onError: (error) => {
            setType(1);
            setMessage(error.response.data.message);
            setOpen(true);
        }
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        if (formData.get("ime_prezime") == "") {
            setType(1);
            setMessage("Molimo vas unesite vaše ime i prezime.");
            setOpen(true);
            return;
        }

        if (formData.get("email") == "") {
            setType(1);
            setMessage("Molimo vas unesite vaš email.");
            setOpen(true);
            return;
        }

        if (formData.get("tema") == "") {
            setType(1);
            setMessage("Molimo vas unesite temu.");
            setOpen(true);
            return;
        }

        if (formData.get("poruka") == "") {
            setType(1);
            setMessage("Molimo vas unesite vašu poruku.");
            setOpen(true);
            return;
        }

        mutation.mutateAsync(formData).catch((e) => {
        });
    }

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {type == 0 ? (
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                )}
            </Snackbar>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Kontakt</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Kontakt</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Kontakt</p>
                        <h2>Kontaktirajte nas ako imate bilo kakve nedoumice</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact-info">
                                <h2>Kontakt podaci</h2>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="far fa-clock"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h3>Radno vreme</h3>
                                        <p>Pon - Pet, 08:00 - 21:00</p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fa fa-phone-alt"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h3>Pozovite nas</h3>
                                        <p><a style={{color: "white"}} href="tel:+381692103985">+381692103985</a></p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fa fa-location-dot"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h3>Naša lokacija</h3>
                                        <p><a style={{color: "white"}}
                                              href="https://www.google.com/maps/place/Eclectic+detailing+doo/@44.8199987,20.4709148,20.21z/data=!4m5!3m4!1s0x475a7befabedb307:0x44a40ca2add5b03!8m2!3d44.8200229!4d20.4706318">Venizelosova
                                            15, Beograd</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="contact-form">
                                <form onSubmit={handleSubmit} method="POST" id="contact-form" noValidate="novalidate">
                                    <div className="control-group">
                                        <input type="text" className="form-control" name="ime_prezime" id="name"
                                               placeholder="Vaše ime i prezime" required="required"
                                               data-validation-required-message="Please enter your name"/>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="control-group">
                                        <input type="email" className="form-control" name="email" id="email"
                                               placeholder="Vaš email" required="required"
                                               data-validation-required-message="Please enter your email"/>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="control-group">
                                        <input type="text" className="form-control" name="tema" id="subject"
                                               placeholder="Tema" required="required"
                                               data-validation-required-message="Please enter a subject"/>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="control-group">
                                        <textarea className="form-control" id="message" name="poruka"
                                                  placeholder="Poruka" required="required"
                                                  data-validation-required-message="Please enter your message"></textarea>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div>
                                        <button className="btn btn-custom" type="submit"
                                                id="sendMessageButton">Pošaljite <i style={{marginLeft: 3}}
                                                                                    className="fa-solid fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3492.289801962975!2d20.46923808498273!3d44.81993527205003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7befabedb307%3A0x44a40ca2add5b03!2sEclectic%20detailing%20doo!5e1!3m2!1sen!2srs!4v1682276979783!5m2!1sen!2srs"
                                width="600" height="450" style={{border: 0}} allowFullScreen={true} loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KontaktPage;