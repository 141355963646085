import React from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router";
import {addOpisEP, deleteOpisEP, getUsluga, updateCena} from "../../../apis/UslugaApi";
import Grid from "@mui/material/Grid2";
import {
    Alert,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    OutlinedInput,
    Paper,
    Popover,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from "@mui/icons-material/Close";

const UslugaInfoPage = () => {
    const queryClient = useQueryClient();
    const {id} = useParams();

    const [tip, setTip] = React.useState(null);
    const [vrednost, setVrednost] = React.useState(null);

    const [type, setType] = React.useState(null);
    const [openN, setOpenN] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const {data, isLoading, isError} = useQuery({
        queryKey: ["usluga", id],
        queryFn: () => getUsluga(id)
    });

    const cenaMutation = useMutation({
        mutationFn: (formData) => updateCena(formData),
        onSuccess: (response) => {
            setType(0);
            setMessage(response.data.message);
            setOpenN(true);
            setVrednost(null);
            queryClient.invalidateQueries(['usluga', id]);
        },
        onError: (error) => {
            setType(1);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const addMutation = useMutation({
        mutationFn: (formData) => addOpisEP(formData),
        onSuccess: (response) => {
            setType(0);
            setMessage(response.data.message);
            setOpenN(true);
            setVrednost(null);
            queryClient.invalidateQueries(['usluga', id]);
        },
        onError: (error) => {
            setType(1);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const deleteMutation = useMutation({
        mutationFn: (formData) => deleteOpisEP(formData),
        onSuccess: (response) => {
            setType(0);
            setMessage(response.data.message);
            setOpenN(true);
            queryClient.invalidateQueries(['usluga', id]);
        },
        onError: (error) => {
            setType(1);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const deleteOpis = (event, value) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('opis', value);
        formData.append('id', id);

        deleteMutation.mutateAsync(formData).catch((e) => {
        });
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleCloseN = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenN(false);
    };

    const handleSubmit = (event, value) => {
        event.preventDefault();

        if (value == null) {
            return;
        }

        const formData = new FormData();
        if (tip == "cena") {
            formData.append('value', vrednost);
            formData.append('id', id);

            cenaMutation.mutateAsync(formData).catch((e) => {
            });
        } else {
            formData.append('opis', vrednost);
            formData.append('id', id);

            addMutation.mutateAsync(formData).catch((e) => {
            });
        }
    }

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleCloseN}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseN}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <Grid container spacing={2}>
            <Snackbar
                open={openN}
                autoHideDuration={6000}
                onClose={handleCloseN}
                action={action}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {type == 0 ? (
                    <Alert
                        onClose={handleCloseN}
                        severity="success"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleCloseN}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                )}
            </Snackbar>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <AdminPanelSettingsTwoToneIcon size={22} sx={{mr: 2}}/>
                        Informacije o usluzi
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isLoading ? <LinearProgress/> : (
                        <Stack direction="column" spacing={2}>
                        <span>Naziv: <b>{data?.data.naziv}</b>
                            </span>
                            <span>
                            Cena: <b>{data?.data.cena} RSD</b>
                            <IconButton onClick={(e) => {
                                handleClick(e);
                                setTip("cena");
                            }} color="primary" aria-label="add to shopping cart">
                              <EditIcon/>
                            </IconButton>
                            </span>
                            <span>Vreme trajanja: <b>{data?.data.trajanje} min</b></span>
                            <div>
                                Opis
                                <IconButton onClick={(e) => {
                                    handleClick(e);
                                    setTip("opis");
                                }} color="primary" aria-label="add to shopping cart">
                                    <AddBoxIcon/>
                                </IconButton>
                                {JSON.parse(data?.data.opis).map((item, index) => {
                                    return (
                                        <div><span>{index + 1} - {item}
                                            <IconButton onClick={(event) => deleteOpis(event, item)} color="error"
                                                        aria-label="add to shopping cart">
                                          <DeleteIcon/>
                                        </IconButton>
                                    </span><br/></div>
                                    )
                                })}
                            </div>
                        </Stack>
                    )}

                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <FormControl sx={{mt: 1}} fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Izmenite podatak</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={
                                                'add-opis'
                                            }
                                            edge="end"
                                            color="primary"
                                            onClick={(e) => handleSubmit(e, vrednost)}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                value={vrednost}
                                label="Izmenite podatak"
                                onChange={(e) => setVrednost(e.target.value)}
                            />
                        </FormControl>
                    </Popover>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default UslugaInfoPage;