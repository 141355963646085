import React from 'react';

import file1 from "../../../assets/documents/OBRAZAC-ZA-JEDNOSTRANI-RASKID-UGOVORA.docx";
import file2 from "../../../assets/documents/zahtev-eclectic.docx";
import slika from '../../../assets/img/kartice.jpg';

const UsloviKoriscenjaPage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Uslovi korišćenja i prodaje</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Uslovi korišćenja i prodaje</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Naši uslovi korišćenja i prodaje</p>
                        <h2>Uslovi korišćenja i prodaje</h2>
                    </div>
                    <div className="row" id="post-div">
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p><strong>USLOVI KORIŠĆENJA VEB-SAJTA I KUPOVINE PROIZVODA</strong></p>

                            <p>Ovaj dokument (zajedno sa drugim dokumentima koji se ovde spominju) utvrđuje opšte uslove
                                za korišćenje veb-sajta: www.eclectic.rs (u daljem tekstu: Veb-sajt) i kupovinu
                                proizvoda preko Veb-sajta.</p>

                            <p>Uslove korišćenja Veb-sajta i kupovine preko Veb-sajta postavlja i uređuje:</p>

                            <p>Eclectic doo za popravku i održavanje motornih vozila</p>

                            <p>Venizelosova 15</p>

                            <p>11000 Stari Grad</p>

                            <p>MB: 21792713</p>

                            <p>PIB: 113040488</p>

                            <p>Šifra delatnosti: 4520-održavanje i popravka motornih vozila</p>

                            <p>Email: <a href="mailto:office@eclectic.rs">office@eclectic.rs</a> &nbsp;(u daljem tekstu:
                                Eclectic ili mi)</p>

                            <p>Broj telefon: <a href="tel:+381692103985" target="_self" title="069 21 03 985"><span>069 21 03 985</span></a>
                            </p>

                            <p>&nbsp;</p>

                            <p>Molimo Vas da pažljivo pročitate ove Uslove korišćenja Veb-sajta i kupovine proizvoda (u
                                daljem tekstu: Uslovi korišćenja), pre korišćenja Veb-sajta. Korišćenjem Veb-sajta
                                smatra se da je korisnik upoznat sa ovim Uslovima koriščenja i da ih prihvata.</p>

                            <p>Eclectic može da izmeni sadržaj ovih Uslova, asortiman proizvoda, cene proizvoda, kao i
                                druge podatke koji se odnose na Veb-sajt, zbog čega su korisnici Veb-sajta dužni da
                                prilikom svake posete pažljivo pregledaju sadržaj Veb-sajta. Prilikom korišćenja
                                Veb-sajta ili naručivanja proizvoda preko Veb-sajta, obavezuju Vas ovi Uslovi
                                korišćenja. Ako se ne slažete sa Uslovima i svrhom i načinom obrade Vaših ličnih
                                podataka molimo Vas da ne koristite Veb-sajt. Ako imate pitanja u vezi sa ovim Uslovima
                                možete da nas kontaktirati na email adresu: <a
                                    href="mailto:office@eclectic.rs">office@eclectic.rs</a> ili adresu sedišta
                                Eclectic-a: Venizelosova 15, 11000 Stari Grad, odnosno putem broja telefona:069 2103
                                985.</p>

                            <p>&nbsp;</p>

                            <p><strong>Odricanje od odgovornosti</strong></p>

                            <p>Eclectic ne preuzima odgovornost za štetu koju korisnik pretrpi usled: toga što nije
                                pročitao ove Uslove; nemogućnosti da se izvrši kupovina preko Veb-sajta iz opravdanih
                                razloga; nemogućnosti korišćenja Veb-sajta zbog održavanja, obezbeđenja ili bilo kog
                                drugog tehničkog razloga, kao i usled uskraćivanja pristupa Veb-sajtu zbog nedozvoljenih
                                načina korišćenja.</p>

                            <p>&nbsp;</p>

                            <p><strong>Korišćenje Veb-sajta</strong></p>

                            <p>Podatke (uključujući i Vaše lične podatke) koje nam date obradićemo u skladu sa ovim
                                Uslovima, <a
                                    href="https://www.eclectic.rs/files/files/games/dokumenti/Eclectic-Zahtev-za-ostvarivanje-prava-u-vezi-sa-obradom-podataka-o-licnosti.docx">Obaveštenjem
                                    o obradi podataka o ličnosti</a> i <a href="/kolačići">Obaveštenjem o
                                    kolačićima</a>. Kada koristite Veb-sajt, slažete se sa obradom Vaših ličnih podataka
                                u skladu sa navedenim dokumentima.</p>

                            <p>Korišćenjem Veb-sajta i naručivanjem proizvoda preko Veb-sajta, prihvatate da:</p>

                            <ul>
                                <li>koristite Veb-sajt samo za pretragu i pravno valjane narudžbine;</li>
                                <li>nećete upućivati lažne ili obmanjujuće narudžbine;</li>
                                <li>navedena email adresa, adresa i/ili drugi kontakt podaci koje navodite su tačni,
                                    važeći i potpuni.
                                </li>
                            </ul>

                            <p>Naručivanjem proizvoda preko Veb-sajta pristajete da Eclectic upotrebljava Vaše lične
                                podatke da Vas kontaktira u svrhu izvršenja Vaše narudžbine, ukoliko je to potrebno, a u
                                skladu sa Obaveštenjem o obradi podataka o ličnosti. Ukoliko ne navedete sve podatke
                                koji su nam potrebni, nećete moći daizvršite kupovinu proizvoda. Kada vršite naručivanje
                                proizvoda preko Veb-sajta, potvrđujete da imate više od 18 godina i da ste sposobni za
                                zaključenje obavezujućih ugovora.</p>

                            <p>&nbsp;</p>

                            <p><strong>KUPOVINA PROIZVODA </strong></p>

                            <p>Prezentacija proizvoda na Veb-sajtu predstavlja ponudu za zaključenje ugovora o
                                kupoprodaji proizvoda i usluga.&nbsp; Ugovor o kupoprodaji zajključujete sa Eclectic-om
                                kao prodavcem. Zaključenjem ugovora o kupoprodaji sa Eclectic-om imate sva prava
                                predviđena važećim propisima.</p>

                            <p>&nbsp;</p>

                            <p><strong>Naručivanje</strong></p>

                            <p>Poručivanje je prvi korak u kupovini preko Veb-sajta. Kako biste poručili proizvode ili
                                usluge preko Veb-sajta, morate da budete registrovani korisnik. Registrovanjem ćete
                                dobiti korisničko ime kojim ćete ubuduće pristupati Veb-sajtu prilikom poručivanja.
                                Pošto odaberete proizvode ili usluge i smestite ih u korpu, možete odabrati dva načina
                                plaćanja:</p>

                            <ul>
                                <li>platnom karticom</li>
                                <li>plaćanje na licu mesta</li>
                            </ul>

                            <p>Eclectic zadržava pravo da otkaže kupoprodaju ukoliko mu se učini da je došlo do nekih
                                nepravilnosti u procesu naplaćivanja.</p>

                            <p>Isporuka proizvoda i usluga vrši se isključivo ako je proizvod u potpunosti plaćen.<br/>
                            </p>

                            <p>&nbsp;</p>

                            <p><strong>Plaćanje</strong></p>

                            <p>Platnim karticama</p>

                            <p>Svoju narudžbinu možete platiti koristeći Visa, Visa Electron, MasterCard ili Maestro
                                platnu karticu. Kartica mora biti odobrena od strane banke izdavaoca za online
                                (Internet) plaćanje.</p>

                            <p style={{
                                marginTop: 0 + "in",
                                marginRight: 0 + "in",
                                marginBottom: 7.5 + "pt",
                                marginLeft: 0 + "in"
                            }}>Prilikom
                                online naručivanja, odabirom odgovarajućeg načina plaćanja bićete preusmereni na
                                stranicu OTP Banka koja je zaštićena i sigurna za ovakav način plaćanja. Niti jednog
                                trenutka podaci o Vašoj platnoj kartici nisu dostupni našem sistemu.</p>

                            <p style={{
                                marginTop: 0 + "in",
                                marginRight: 0 + "in",
                                marginBottom: 7.5 + "pt",
                                marginLeft: 0 + "in"
                            }}>Na ovoj
                                stranici je potrebno da unesete sledeće podatke: Broj kartice, Datum isteka
                                i&nbsp; CVC2/CVV2 kod, koje možete pročitati sa Vaše kartice.</p>

                            <p><img alt="" src={slika}/></p>

                            <p><em>*Unos i provera podataka isključivo se obavljaju između korisnika kartice i OTP
                                Banka, a Internet trgovac nema uvid u podatke koji se razmenjuju.</em><br/>
                                Sve cene su izražene u dinarima sa uračunatim PDV-om. Prilikom unošenja podataka o
                                platnoj kartici, poverljive informacija se prenose putem javne mreže u zaštićenoj
                                (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije
                                kriptografske tehnologije. Sva plaćanja će biti obavljena u dinarima (RSD). Ukoliko se
                                plaća platnim karticama inostranih Banaka izdavalaca, dinarski iznos transakcije će biti
                                konvertovan u settlement valutu Banke (EUR) prema kursu Narodne Banke Srbije. Pri
                                zaduživanju Vaše platne kartice, već konvertovan iznos ce se konvertovati u Vašu lokalnu
                                valutu, prema kursu koji primenjuju operatori platnih kartica. Plaćanje nije moguće u
                                stranoj valuti, već isključivo u valuti RSD. Plaćanje je moguće platnim karticama
                                domaćih i inostranih banaka.</p>

                            <p>Sigurnost podataka prilikom kupovine, garantuje obrađivač platnih kartica, OTP Banka
                                Srbija AD Beograd, pa se tako kompletan proces naplate obavlja <span>u potpuno sigurnom okruženju</span>.
                            </p>

                            <p>Kupac koji koristi uslugu online plaćanja karticama preko e-shopa mora ispunjavati
                                sledeće uslove:</p>

                            <ul>
                                <li>&nbsp;&nbsp;&nbsp; Isporuka robe je moguća samo na teritoriji Republike Srbije, na
                                    adresi navedenoj u porudžbini,
                                </li>
                                <li>&nbsp;&nbsp;&nbsp; Isporuka usluga je moguća samo na adresi gde je usluga i
                                    zakazana,
                                </li>
                                <li>&nbsp;&nbsp;&nbsp; Samo vlasnik platne kartice može izvršiti plaćanje.</li>
                            </ul>

                            <p>Nakon poručivanja proizvoda ili usluga sa našeg Veb-sajta, i odabira plaćanja putem
                                kartice, kontaktiraće Vas predstavnik Eclectic-a, a kako bismo potvrdili
                                porudžbenicu.</p>

                            <p>Eclectic zadržava pravo na izmenu cena i dostupnost proizvoda bez predhodnog obaveštenja.
                                Ipak Eclectic neće menjati cene nakon izvršene potvrde Vaše porudžbine.</p>

                            <p>&nbsp;</p>

                            <p><strong>Pravo na odustanak od kupovine</strong></p>

                            <p>U skladu sa zakonom imate pravo do odustanete od ugovora o kupoprodaji proizvoda
                                kupljenih preko Veb-sajta bez obrazloženja u roku od 14 dana od dana kada Vam je
                                proizvod isporučen. Ukoliko želite da odustanete od ugovora o kupoprodaji proizvoda koji
                                je zaključen preko Veb-sajta, to možete da učinite popunjavanjem i slanjem <a
                                    href="https://www.eclectic.rs/files/files/games/dokumenti/OBRAZAC-ZA-JEDNOSTRANI-RASKID-UGOVORA.docx">obrasca
                                    za odustanak od ugovora</a> koji možete naći na Veb-sajtu, a koji dobijate i uz
                                kupljene proizvode (na poleđini računa) zajedno sa neoštećenim i nekorišćenim
                                proizvodima poštom na adresu Eclectic-a ili odlaskom u Eclectic prodajno mesto. Ukoliko
                                na poleđini računa niste dobili obrazac za odustanak, možete ga preuzeti <a
                                    href={file1}><u>ovde</u></a>.
                            </p>

                            <p>Izjava o odustajanju od ugovora proizvodi pravno dejstvo od dana kada je poslata
                                Eclectic-u&nbsp; i smatra se blagovremenom ako je poslata u roku od 14 dana od dana kada
                                je proizvod dospeo u Vašu državinu, odnosno u državinu trećeg lica koje ste odredili za
                                primaoca pošiljke.<br/>
                                U slučaju da odustanete od ugovora Vi snosite troškove vraćanja proizvoda i dužni ste da
                                u roku od 14 dana od dana odustajanja od ugovora vratite Eclectic-u kupljene
                                proizvode.<br/>
                                Eclectic je dužan da bez odlaganja izvrši povraćaj uplata koje je primio za proizvode,
                                uključujući i troškove isporuke, a najkasnije u roku od 14 dana od dana kada je primio
                                obrazac o odustanku od ugovora s tim što može da odloži povraćaj sredstava dok ne dobije
                                proizvode koji se vraćaju, ili dok ne dostavite dokaz da ste poslali proizvode
                                Eclectic-u u zavisnosti od toga šta nastupa prvo.<br/>
                                Kupovinom proizvoda dajete izričitu saglasnost da se povraćaj uplate izvrši na Vaš
                                tekući račun koji ćete navesti u obrascu za odustanak od ugovora.</p>

                            <p>Napominjemo da je moguće odustati od kupovine proizvoda koji su vraćeni u originalnoj
                                amabalaži, koji nisu korišćeni ili oštećeni i koji sadrže sve dodatke i propratnu
                                dokumentaciju (garantni list, uputstva za upotrebu i slično), kao i originalni
                                račun.</p>

                            <p>&nbsp;</p>

                            <p><strong>Povraćaj sredstava </strong></p>

                            <p>U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom od
                                platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, Eclectic
                                online prodavnica je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i Maestro
                                metoda plaćanja, što znači da će banka na zahtev prodavca obaviti povraćaj sredstava na
                                račun korisnika kartice.</p>
                            <p>
                                Povraćaj novca za kupljeni termin za isporuku usluge se refundira otkazivanjem minimum
                                24 sata pre pružanja usluge.
                            </p>
                            <p><br/>
                                <strong>Saobraznost proizvoda ugovoru</strong><br/>
                                Obaveštavamo Vas da je saglasno zakonu Eclectic odgovoran za nesaobraznost proizvoda
                                ugovoru koja se pojavi u roku od dve godine od dana isporuke proizvoda Vama ili trećem
                                licu koje ste odredili kao primaoca pošiljke.<br/>
                                Ako nesaobraznost nastane u roku od šest meseci od dana isporuke proizvoda pretpostavlja
                                se da je nesaobraznost postojala u trenutku isporuke proizvoda, osim ako je to u
                                suprotnosti sa prirodom proizvoda i prirodom određene nesaobraznosti.<br/>
                                Eclectic je dužan da isporuči proizvode koji su saobrazni ugovoru o kupoprodaji
                                proizvoda.
                            </p>
                            <p>Eclectic odgovara za nesaobraznosti isporučenih proizvoda ugovoru ako je ona postojala u
                                momentu isporuke proizvoda Vama ili trećem licu koje ste odredili kao primaoca pošiljke,
                                kao i u slučaju kad se nesaobraznost proizvoda pojavila posle isporuke, a potiče od
                                uzroka koji je postojao pre isporuke.<br/>
                                Eclectic je odgovoran i za nesaobraznost nastalu zbog nepravilnog pakovanja.<br/>
                                Ako se nesaobraznost pojavi u roku od šest meseci od dana isporuke proizvoda, imate
                                pravo da izaberete da li će se nesaobraznost otkloniti zamenom, odgovarajućim umanjenjem
                                cene ili da izjavite da raskidate ugovor. Ako se nesaobraznost pojavi po isteku roka od
                                šest meseci od dana isporuke proizvoda, imate pravo da izaberete da li će Eclectic da
                                otkloni nesaobraznost proizvoda opravkom ili zamenom.<br/>
                                Ne možete da raskinete ugovor o kupoprodaji proizvoda ako je nesaobraznost proizvoda
                                neznatna.<br/>
                                Sve troškove koji su neophodni da bi se proizvodi saobrazili ugovoru, a naročito
                                troškove rada, materijala, preuzimanja i isporuke, snosi Eclectic.</p>

                            <p>&nbsp;</p>

                            <p><strong>Proizvodi sa garancijom</strong></p>

                            <p>Eclectic jemči da su svi proizvodi iz njegvovog asortimana originalne robne marke.
                                Eclectic u garantnom roku, o svom trošku obezbeđuje otklanjanje kvarova i nedostataka
                                proizvoda koji proizlaze iz nepodudarnosti stvarnih sa propisanim odnosno deklarisanim
                                karakteristikama kvaliteta proizvoda.</p>

                            <p>U slučaju neizvršenja ove obaveze, Eclectic će izvršiti zamenu proizvoda novim ili će Vam
                                vratiti novac.</p>

                            <p>Garantni rok počinje da teče danom prodaje proizvoda sa garancijom koji se unosi u
                                garantni list i overava pečatom i potpisom ovlašćenog prodavca. Kupac gubi pravo na
                                garanciju ako se kvar izazove nepridržavanjem datim uputstvima za upotrebu i ako su na
                                proizvodu vršene bilo kakve popravke od strane neovlašćenih lica.</p>

                            <p>U garanciju ne ulaze oštećenja prouzrokovana prilikom transporta nakon preuzimanja
                                kupljenih proizvoda, oštećenja zbog nepravilne montaže ili održavanja, mehanička
                                oštećenja nastala krivicom korisnika, oštećenja zbog varijacije napona električne mreže,
                                udara groma i pratećih pojava.<br/>
                                Kupac je dužan da prilikom preuzimanja proizvoda ustanovi kompletnost i fizičku
                                neoštećenost proizvoda&nbsp; koji preuzima, jer naknadne reklamacije po tom osnovu neće
                                biti prihvaćene od strane Eclectic-a.</p>

                            <p><br/>
                                <strong>Obaveštenje o načinu i mestu prijema reklamacija i načinu postupanja po
                                    primljenim reklamacijama.</strong>
                            </p>

                            <p>Reklamacije na kupljene proizvode možete da izjavite u jednoj od naših prodavnica,
                                elektronskim putem na email adresu: reklamacije@eclectic.rs ili preko broja telefona:
                                069 2103 985. Prilikom izjavljivanja reklamacije dužni ste da dostavite robu na koju se
                                reklamacija odnosi, račun na uvid ili drugi dokaz o kupovini tih proizvoda (kopija
                                računa, slip i sl.), kao i garantni list ukoliko je reč o proizvodima sa garancijom.
                                Prilikom dostavljanja proizvoda niste dužni da dostavite ambalažu.</p>

                            <p>Po prijemu reklamacije izdaćemo Vam pismenu potvrdu ili ćemo Vas elektronskim putem
                                obavestiti da potvrđujemo da smo primili Vašu reklamaciju, odnosno saopštićemo Vam broj
                                pod kojim je ona zavedena u evidenciji primljenih reklamacija. Najkasnije u roku od osam
                                dana od dana prijema reklamacije, pismenim ili elektronskim putem odgovorićemo Vam na
                                izjavljenu reklamaciju. Odgovor će sadržati odluku da li prihvatamo reklamaciju,
                                izjašnjenje o Vašem zahtevu i konkretan predlog i rok za rešavanje reklamacije koji ne
                                može biti duži od 30 dana od dana podnošenja reklamacije.</p>

                            <p>Rok za rešavanje reklamacije prekida se kada potrošač primi odgovor prodavca i počinje da
                                teče iznova kada prodavac primi izjašnjenje potrošača na odgovor prodavca. Potrošač je
                                dužan da se izjasni na odgovor prodavca najkasnije u roku od tri dana od dana prijema
                                odgovora prodavca. Smatraće se da potrošač nije saglasan sa predlogom prodavca ukoliko
                                se ne izjasni u roku od tri dana.</p>

                            <p>U slučaju odbijanja reklamacije Eclectic d.o.o je dužan da pruži odgovarajuće obaveštenje
                                potrošaču u slučaju odbijanja reklamacije (obrazloženje prodavca u slučaju neprihvatanja
                                reklamacije) kao i&nbsp; da potrošača sveobuhvatno obavesti o mogućnosti rešavanja spora
                                vansudskim putem, kao i o nadležnim telima za vansudsko rešavanje potrošačkih
                                sporova.</p>

                            <p>Ukoliko je potrošač za kupljeni proizvod dobio garanciju i ako se reklamacija izjavljuje
                                nakon proteka 2 godine od dana kupovine, potrošač je dužan da reklamaciju izjavi davaocu
                                garancije.</p>

                            <p><strong>Vansudsko rešavanje potrošačkih sporova</strong></p>

                            <p>Potrošački spor može se rešiti postupkom vansudskog rešavanja potrošačkih sporova. Kao
                                trgovac smo dužni da vas obavestimo da smo po zakonu obavezni da učestvujemo u ovom
                                postupku. Vansudsko rešavanje potrošačkih sporova obavlja se na transparentan, efikasan,
                                brz i pravičan način pred telom za vansudsko rešavanje potrošačkih sporova. Ministarstvo
                                sačinjava listu tela i javno je objavljuje. Dostupna je na adresi <a
                                    href=" https://mtt.gov.rs/tekst/2306/zastita-potrosaca.php">https://mtt.gov.rs/tekst/2306/zastita-potrosaca.php</a> Postupak
                                pred telom može da pokrene potrošač samo ukoliko je prethodno izjavio reklamaciju ili
                                prigovor trgovcu. Potrošač protekom jedne godine od dana (bezuspešnog) podnošenja
                                reklamacije gubi pravo na podnošenje predloga za vansudsko rešavanje spora.</p>

                            <p>Vansudsko rešavanje potrošačkog spora može da traje najduže 90 dana od dana podnošenja
                                predloga. ISPUNJENOST KRITERIJUMA ZA DODELU E-TRUSTMARK OZNAKE</p>

                            <p>Vansudsko rešavanje potrošačkih sporova, ne primenjuje se, pored ostalog:</p>

                            <p>• u potrošačkim sporovima koji su predmet Zakona o zaštiti potrošača, ako je vansudsko
                                rešavanje</p>

                            <p>sporova uređeno posebnim zakonom, a naročito u oblasti pružanja elektronskih
                                komunikacionih</p>

                            <p>usluga, poštanskih usluga, finansijskih usluga, osim finansijskih pogodbi, usluga
                                putovanja;</p>

                            <p>• za rešavanje sporova po procedurama koje je ustanovio sam trgovac;</p>

                            <p>• na neposredne pregovore između potrošača i trgovca;</p>

                            <p>• na pokušaj mirenja strana povodom spora u parničnom postupku;</p>

                            <p>• u postupcima koje je trgovac pokrenuo protiv potrošača.</p>

                            <p>Svaka stranka u postupku vansudskog rešavanja potrošačkog spora plaća svoje troškove
                                (troškovi</p>

                            <p>zastupanja, putni troškovi i sl.). Rad tela za vansudsko rešavanje potrošačkog spora je
                                besplatan za</p>

                            <p>stranke u postupku vansudskog rešavanja potrošačkog spora.</p>

                            <p><strong>Zaštita privatnosti korisnika </strong></p>

                            <p>U ime Eclectic online prodavnice obavezujemo se da ćemo čuvati privatnost svih naših
                                kupaca. Prikupljamo samo neophodne, osnovne podatke o kupcima/korisnicima i podatke
                                neophodne za poslovanje i informisanje korisnika u skladu sa dobrim poslovnim običajima
                                i u cilju pružanja kvalitetne usluge. Dajemo kupcima mogućnost izbora uključujući
                                mogućnost odluke da li žele ili ne da se izbrišu sa mailing lista koje se koriste za
                                marketinške kampanje. Svi podaci o korisnicima/kupcima se strogo čuvaju i dostupni su
                                samo zaposlenima kojima su ti podaci nužni za obavljanje posla. Svi zaposleni Eclectic
                                online prodavnice (i poslovni partneri) odgovorni su za poštovanje načela zaštite
                                privatnosti.</p>

                            <p>&nbsp;</p>

                            <p><strong>OBAVEŠTENJE O OBRADI PODATAKA O LIČNOSTI </strong></p>

                            <p>Eclectic doo za popravku i održavanje motornih vozila (u daljem tekstu:
                                Eclectic&nbsp; ili mi) izdaje ovo obaveštenje o obradi podataka o ličnosti (u daljem
                                tekstu: Obaveštenje) kako biste Vi kao korisnik veb-sajta: www.eclectic.rs (u daljem
                                tekstu: Veb-sajt) bili upoznati sa svim važnim informacijama u vezi sa obradom Vaših
                                ličnih podataka, njihovom zaštitom i pravima koja Vam po tom osnovu pripadaju u skladu
                                sa Zakonom o zaštiti podataka o ličnosti „Službeni glasnik RS“, br. 87/2018 (u daljem
                                tekstu: ZZPL).</p>

                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

                            <p><strong>Značenje izraza „podatak o ličnosti“</strong></p>

                            <p>U skladu sa ZZPL-om "podatak o ličnosti" (u daljem tekstu: podatak o ličnosti ili lični
                                podatak) je svaki podatak koji se odnosi na fizičko lice čiji je identitet određen ili
                                odrediv, neposredno ili posredno, posebno na osnovu oznake identiteta, kao što je ime i
                                identifikacioni broj, podataka o lokaciji, identifikatora u elektronskim komunikacionim
                                mrežama ili jednog, odnosno više obeležja njegovog fizičkog, fiziološkog, genetskog,
                                mentalnog, ekonomskog, kulturnog i društvenog identiteta.</p>

                            <p>&nbsp;</p>

                            <p><strong>Rukovalac </strong></p>

                            <p>Vaše lične podatke prikuplja i njihovom obradom upravlja:</p>

                            <p>Eclectic doo za popravku i održavanje motornih vozila</p>

                            <p>Venizelosova 15</p>

                            <p>11000 Stari Grad</p>

                            <p>MB: 21792713</p>

                            <p>PIB: 113040488</p>

                            <p>Email: office@eclectic.rs</p>

                            <p>&nbsp;</p>

                            <p><strong>Vrsta podataka i način njihove obrade</strong></p>

                            <p>Prikupljamo samo one podatke koje nam Vi dobrovoljno date i koji su nužni za ostvarivanje
                                svrhe obrade.</p>

                            <p>U zavisnosti od svrhe nameravane obrade, vrste podataka o ličnosti koje obrađujemo
                                obuhvataju sledeće: ime, prezime, jedinstveni matični broj građana, broj važeće lične
                                karte ili pasoša, datum rođenja, podatke o Vašim transakcijama (plaćanju, kupovinama,
                                narudžbinama, povraćajima proizvoda itd.) i Vaše kontakt podatke (broj fiksnog ili
                                mobilnog telefona), email adresu, adresu stanovanja odnosno adresu za isporuku
                                proizvoda).</p>

                            <p>Podatke prikupljamo i obrađujemo putem našeg Veb-sajta, na društvenoj mreži
                                „Instagram“,&nbsp; i/ili na druge načine.</p>

                            <p>&nbsp;</p>

                            <p><strong>Svrha obrade Vaših podataka</strong></p>

                            <p>Eclectic prikuplja i obrađuje Vaše lične podatke u sledeće svrhe:</p>

                            <ul>
                                <li>izvršenja kupoprodajnog ugovora za proizvode koje ste kupili ili bilo kojeg drugog
                                    ugovora između Vas i Eclectic-a;
                                </li>
                                <li>rešavanja Vaših zahteva;</li>
                                <li>pružanja informacija o proizvodima iz prodajnog asortimana Eclectic-a i njihovog
                                    reklamiranja putem obaveštenja odnosno newsletter-a (osim ako ne zahtevate da budete
                                    uklonjeni sa liste primalaca obaveštenja odnosno newsletter-a) i
                                </li>
                                <li>unapređenja naših usluga.</li>
                            </ul>

                            <p>U slučaju da nam date lične podatke trećih lica garantujete da ste ih obavestili o
                                upotrebi njihovih ličnih podataka. U slučaju da ste kupili proizvod ili Gift karticu,
                                lični podaci trećih lica koje ste nam dali biće upotrebljeni isključivo u svrhu
                                sprovođenja isporuke i rešavanja upita ili predloga koje možete imati Vi ili treće
                                lice.</p>

                            <p>&nbsp;</p>

                            <p><strong>Pravni osnov obrade Vaših ličnih podataka</strong></p>

                            <p>U zavisnosti od svrhe prikupljanja i obrade, pravni osnov za obradu Vaših ličnih podataka
                                može da bude Vaš pristanak (saglasnost), izvršenje ugovora odnosno usluge koju ste
                                tražili, poštovanje zakonskih propisa koji nalažu obradu Vaših ličnih podataka ili
                                legitimni (opravdani) interesi Eclectic-a, osim ako su nad tim interesima pretežniji
                                interesi i osnovna prava i slobode Vaši odnosno lica na koje se podaci odnose koji
                                zahtevaju zaštitu podataka o ličnosti, a posebno ako je lice na koje se podaci odnose
                                maloletno.</p>

                            <p>Na primer, Vaše podatke kao što su ime i prezime, adresa, broj telefona, email adresa
                                možemo da dobijemo od Vas kada koristite Veb-Sajt ili nas kontaktirate email-om, poštom
                                ili na drugi način i da iste obrađujemo u svrhu registracije, rešavanja reklamacije,
                                kupovine proizvoda i dr. U svakom od ovih slučajeva podatke obrađujemo na osnovu Vašeg
                                pristanka ili našeg legitimnog interesa s obzirom na to da ste nam se neposredno
                                obratili ili zbog nužnosti izvršenja ugovora odnosno usluge koju ste tražili (tačnije,
                                ako želite da se registrujete kao korisnik Veb-sajta mi moramo da obradimo Vaše lične
                                podatke, jer u protivnom ne možemo da upravljamo Vašom registracijom ili izvršimo Vašu
                                narudžbinu).</p>

                            <p>Pristanak od Vas tražimo i u slučaju kada želimo da Vam pošaljemo materijale namenjene
                                direktnom oglašavanju. Nakon opoziva pristanka Vaše podatke ni na koji način ne
                                obrađujemo, ali&nbsp; moramo da ih sačuvamo samo da bismo u slučaju potrebe dokazali da
                                je Vaš pristanak za dalju obradu opozvan i da je obrada pre opoziva pristanka bila
                                zakonita.</p>

                            <p>Odjavu sa mail liste ili opoziv pristanka za prikupljanje i obradu Vaših ličnih podataka
                                u svakom trenutku možete da izvršite na jedan od sledećih načina: slanjem email-a na
                                adresu: <a href="mailto:office@eclectic.rs">office@eclectic.rs</a> ili slanjem pisma
                                Eclectic doo za popravku i održavanje motornih vozila, Venizelosova 15, 11000 Stari
                                Grad.</p>

                            <p>&nbsp;</p>

                            <p><strong>Rok čuvanja podataka o ličnosti</strong></p>

                            <p>Eclectic pohranjuje i čuva Vaše podatke samo onoliko dugo koliko je to potrebno radi
                                ostvarivanje svrhe obrade za koju su prikupljeni, i to:</p>

                            <ul>
                                <li>za izvršenje kupoprodajnog ugovora za proizvode koje ste kupili ili bilo kojeg
                                    drugog ugovora između Vas i Eclectic-a podatke pohranjujemo, čuvamo i obrađujemo
                                    onoliko dugo koliko je to potrebno da bi se kupovina proizvoda koje ste kupili
                                    sprovela do kraja uključujući i vreme potrebno da se postupi po prigovorima ili
                                    zahtevima koji se odnose na kupovinu proizvoda, osim ukoliko ne zahtevate da se Vaši
                                    podaci izbrišu pre isteka navedenih rokova (u kom slučaju će oni, ukoliko brisanje
                                    zatražite pre isteka ugovornog odnosa, biti pseudonimizirani);
                                </li>
                                <li>prilikom Vašeg obraćanja i Vaših upita, Vaše lične podatke zadržavamo u roku
                                    potrebnom za obradu upita odnosno dok ne zatražite njihovo brisanje;
                                </li>
                                <li>Vaše lične podatke koje obrađujemo u svrhu dostavljanja podsetnika i obaveštenja ili
                                    direktnog oglašavanja zadržavamo sve dok ne izvršite odjavu s naše mail liste
                                    odnosno dok ne opozovete prisatnak i
                                </li>
                                <li>prilikom pohranjivanja cookies-a (kolačića) na Vašem računaru, Vaše lične podatke
                                    zadržavamo dok služe svrsi.
                                </li>
                            </ul>

                            <p>Vaše lične podatke možemo da čuvamo i nakon što prestanete da koristite usluge
                                Eclectic-a&nbsp; ili prestanete da koristite Veb-sajt u svrhu
                                izvršavanja&nbsp; zakonskih obaveza ili zaštite prava Eclectic-a (pred sudovima ili
                                drugim nadležnim organima), u skladu sa važećim propisima ili prikupljanja statističkih
                                podataka i vođenja evidencija.</p>

                            <p>Vaši lični podaci koji se ne koriste više ni za jedan od navedenih zakonitih načina
                                obrade, Eclectic će izbrisati iz svojih sistema za pohranjivanje i čuvanje ili
                                pseudonimizirati.&nbsp;</p>

                            <p>&nbsp;</p>

                            <p><strong>Ko ima pristup Vašim ličnim podacima - primaoci</strong></p>

                            <p>U zavisnosti od svrhe obrade Vaših ličnih podataka definisanih ovim Obaveštenjem i u meri
                                u kojoj je to nužno radi njenog ostvarivanja Vaši lični podaci su dostupni zaposlenima u
                                Eclectic-u i honorarnim saradnicima (koji se obavezuju na čuvanje poverljivosti Vaših
                                ličnih podataka kao profesionalne tajne) i pouzdanim trećim licima koja nam pružaju
                                usluge u vezi sa uslugama koje mi Vama nudimo i to:</p>

                            <ul>
                                <li>IT agencijama odnosno licima koja nam pružaju pomoć u vidu platforme ili smeštaja na
                                    serveru, podrške i održavanja baze podataka, kao i softvera i aplikacija;
                                </li>
                                <li>licima koja nam pružaju usluge isporuke proizvoda (usluge pošte, kurirske službe)
                                    i
                                </li>
                                <li>licima koja nam pružaju usluge u vezi sa podrškom kupcima.</li>
                            </ul>

                            <p>Svi primaoci su obavezni da preduzmu odgovarajuće tehničke, organizacione i kadrovske
                                mere kako bi zaštitili Vaše lične podatke. U slučajevima kada smo mi rukovaoci i obradu
                                podataka po našem nalogu vrši neko treće lice kao obrađivač, odnose sa obrađivačima
                                regulišemo ugovorima kojima se&nbsp; uređuju svi bitni aspekti obrade podataka o
                                ličnosti kao i mere zaštite. Zaštita Vaših ličnih podataka od strane tih trećih lica
                                mora da bude na istom nivou kao i zaštita koju garantuje Eclectic.</p>

                            <p>Vaše lične podatke možemo da otkrijemo i državnim organima kada smo na to obavezani
                                zakonom odnosno kada smo to dužni da učinimo u skladu sa propisima ili u cilju zaštite
                                naših prava i interesa i prava Vas kao kupaca.</p>

                            <p>&nbsp;</p>

                            <p><strong>Vaša prava u vezi sa ličnim podacima koje obrađujemo</strong></p>

                            <p>Obavezujemo se da ćemo čuvati Vaše lične podatke kao poverljive i da ćemo Vam omogućiti
                                da ostvarite prava koja imate po ZZPL-u.</p>

                            <p>Vaša prava u vezi sa obradom podataka o ličnosti su:</p>

                            <ul>
                                <li>pravo da tražite kopiju podataka koji se obrađuju;</li>
                                <li>pravo pristupa (imate pravo da dobijete jasne, transparentne i lako razumljive
                                    informacije o svrsi obrade, vrstama podataka o ličnosti koji se obrađuju, o primaocu
                                    ili vrstama primalaca kojima su podaci o ličnosti otkriveni ili će biti otkriveni,
                                    kao i o predviđenom roku čuvanja podataka o ličnosti);
                                </li>
                                <li>pravo na ispravku i/ili dopunu (imate pravo da zahtevate ispravku Vaših ličnih
                                    podatka ako su oni neispravni ili nevažeći i/ili njihovo dopunjavanje ako su
                                    nepotpuni);
                                </li>
                                <li>pravo na brisanje (imate pravo da tražite da se Vaši lični podaci izbrišu u meri u
                                    kojoj oni više nisu potrebni za svrhe obrade ili kada Eclectic-u zakon ne dozvoljava
                                    da ih više obrađuje);
                                </li>
                                <li>pravo na prigovor i pritužbu (imate pravo prigovora na način obrade Vaših ličnih
                                    podataka koji možete podneti direktno Eclectic-u ilikoju podnosite Povereniku za
                                    informacije od javnog značaja i zaštitu podataka o ličnosti);
                                </li>
                                <li>pravo na ograničenje obrade (u svakom trenutku imate pravo da tražite ograničenje
                                    obrade Vaših ličnih podataka);
                                </li>
                                <li>pravo na opoziv pristanka na obradu ličnih podataka koja se zasniva na pristanku u
                                    bilo kojem trenutku i
                                </li>
                                <li>pravo na prenosivost ličnih podataka (imate pravo preuzimanja ličnih podataka iz
                                    naše baze u drugu bazu podataka ili pravo da zahtevate da mi to za Vas učinimo, ali
                                    samo pod uslovom da se radi o ličnim podacima koji se obrađuju na osnovu ugovora ili
                                    pristanka i ako se obrada vrši automatizovano).
                                </li>
                            </ul>

                            <p>Ukoliko želite da ostvarite napred navedena prava (osim pritužbe Povereniku) to možete da
                                učinite na sledeće načine: popunjavanjem i predajom <u>Zahteva za ostvarivanje prava u
                                    vezi sa obradom podataka o ličnosti</u> na Veb-sajtu ili u bilo kojoj&nbsp; od naših
                                prodavnica ili slanjem Zahteva za ostvarivanje prava u vezi sa obradom podataka o
                                ličnosti ili zahteva sastavljenog u slobodnoj formi email-om na adresu: <a
                                    href="mailto:office@eclectic.rs">office@eclectic.rs</a> ili poštom na Eclectic doo
                                za popravku i održavanje motornih vozila, Venizelosova 15, 11000 Stari Grad</p>

                            <p>&nbsp;</p>

                            <p><strong>Korišćenje kolačića</strong></p>

                            <p>Kolačići su tekstualni dokumenti koji se čuvaju na Vašem uređaju, a nastaju dok
                                posećujete Veb-sajt. Kada ponovo budete pristupili Veb-sajtu Eclectic može preuzeti
                                podatke sa Vašeg uređaja, a koji su sadržani u kolačićima.</p>

                            <p>Na Veb-sajtu koristimo više vrsta kolačića koji se razlikuju po svojoj svrsi, dužini
                                trajanja itd.</p>

                            <p>Na primer, prilikom posete Veb-sajtu radi pretraživanja, čitanja ili preuzimanja
                                informacija, od Vas prikupljamo i pohranjujemo neke podatke, kao što su: naziv domena i
                                računara sa kojeg pristupate internetu, adresu internet protokola (IP) računara koji
                                koristite, datuma i vremena pristupanja Veb-sajtu, internet adrese stranica preko kojih
                                ste pristupili Veb-sajtu.</p>

                            <p>Pomoću kolačića omogućava Vam se bolje, pristupačnije i prilagođenije korisničko
                                iskustvo, kao i prikaz Veb-sajta, a Eclectic pomoću njih analizira kupce, prati posete i
                                unapređuje svoje oglašavanje.</p>

                            <p>Kolačići mogu poticati i sa drugih veb-sajtova koje posećujete na kojima se
                                Eclectic&nbsp; reklamira, a mogu biti pohranjivani na Vašem uređaju od strane spoljnih
                                servisa čije usluge Eclectic koristi i koji mu služe da prati korišćenje interneta u
                                marketinške svrhe.</p>

                            <p>Isključivanjem kolačića odlučujete da li ćete da dozvolite njihovo pohranjivanje na Vašem
                                uređaju. Podešavanja željenih postavki u vezi sa kolačićima možete izvršiti u Vašem
                                internet pretraživaču.</p>

                            <p>Više o kolačićima pročitajte ovde <a href="/kolačići"><u>Obaveštenje o
                                kolačićima.</u></a></p>

                            <p>&nbsp;</p>

                            <p><strong>Bezbednost Vaših ličnih podatka</strong></p>

                            <p>U cilju bezbednosti Vaših ličnih podataka koje obrađujemo, uvek nastojimo da primenjujemo
                                neophodne standarde u zaštiti podataka o ličnosti&nbsp; sve neophodne tehničke,
                                organizacione i kadrovske mere zaštite u skladu sa zahtevima ZZPL-a, uključujući mere
                                zaštite informacione bezbednosti Vaših podataka u skladu sa važećim propisima i ostale
                                mere koje su primerene obradi i nužne za obezbeđivanje zaštite konkretnih podataka o
                                ličnosti.</p>

                            <p>Treća lica koja obrađuju Vaše lične podatke koje prikuplja i čijom obradom upravlja
                                Eclectic&nbsp; takođe imaju obavezu da primenjuju sve potrebne tehničke, organizacione i
                                kadrovske mere kako bi se i sa njihove strane obezbedio isti nivo zaštite koji pruža i
                                garantuje Eclectic.</p>

                            <p>&nbsp;</p>

                            <p><strong>Završne odredbe </strong></p>

                            <p>Ovo Obaveštenje ima za cilj da Vam pruži sve potrebne informacije za davanje ili
                                uskraćivanje pristanka za obradu Vaših ličnih podataka na napred opisan način.</p>

                            <p>Ukoliko imate bilo kakvih pitanja u vezi sa zaštitom Vaših ličnih podataka, o tome kako
                                postupamo sa Vašim ličnim podacima i kako ih upotrebljavamo ili hoćete da ostvarite
                                prethodno navedena prava u vezi sa Vašim ličnim podacima molimo Vas da nas kontaktirate
                                u toku radnog vremena centralne kancelarije (radnim danom 9-17h) telefonom na&nbsp; 069
                                2103 985, slanjem emaila na adresu: <a
                                    href="mailto:office@eclectic.rs">office@eclectic.rs</a> ili slanjem pisma poštom na
                                adresu sedišta: Eclectic doo za popravku i održavanje motornih vozila, Venizelosova 15,
                                11000 Stari Grad.</p>
                            <p></p>
                            <a href={file2}><strong>ZAHTEV ZA OSTVARIVANJE PRAVA U
                                VEZI&nbsp; SA OBRADOM PODATAKA O LIČNOSTI</strong></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsloviKoriscenjaPage;