import React from 'react';

const DezinfekcijaOzonomPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Dezinfekcija vozila ozonom i Uklanjanje neprijatnih mirisa</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Dezinfekcija vozila ozonom i Uklanjanje neprijatnih mirisa</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Dezinfekcija vozila ozonom i Uklanjanje neprijatnih mirisa</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>

                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DezinfekcijaOzonomPage;