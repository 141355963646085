import React from 'react';

const PovracajSredstavaPage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Povraćaj sredstava</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Povraćaj sredstava</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Uslovi</p>
                        <h2>Povraćaj sredstava</h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>U slučaju da se radi povrat novca usled uvažene reklamacije u garantnom roku i/ili
                                raskida ugovora od strane kupca u propisanom roku, procedura je sledeća:<br/>
                                <br/>
                                Ukoliko je kupac platio karticom, sredstva se vraćaju na njegovu karticu – šaljemo
                                zahtev banci i po njihovoj proceduri vraćamo sredstva<br/>
                                Ukoliko je platio pouzećem ili preko računa, potrebno je da nam kupac dostavi broj svog
                                tekućeg računa u banci ili pošti na mejl <a
                                    href="mailto:office@eclectic.rs">office@eclectic.rs</a> i sredstva vraćamo na njegov
                                račun.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PovracajSredstavaPage;