import React, {useEffect, useState} from 'react';
import logo from '../assets/logo.png'
import logo1 from '../assets/logo/MC/maestro/Artwork/maestro_logo/folder/ms_hrz_opt_pos_75_3x.png'
import logo2 from '../assets/logo/MC/mastercard/Artwork/folder/mc_symbol_opt_73_2x.png'
import logo3 from '../assets/logo/Visa/Visa_Brandmark_RGB_2021_PNG/Visa_Brandmark_White_RGB_2021.png'
import logo4 from '../assets/logo/Dina/dina.jpg'
import logo5 from '../assets/logo/logo.svg'
import logo6 from '../assets/logo/Visa/verified_visa/Ver-by-VBM-2c-JPG.jpg'
import logo7 from '../assets/logo/MC/SecureCode/sclogo_92x50.gif'
import {Alert, Backdrop, Button, CircularProgress, IconButton, Snackbar} from "@mui/material";
import {createKlijent} from "../apis/KlijentiApi";
import {useMutation} from "@tanstack/react-query";
import CloseIcon from "@mui/icons-material/Close";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import enpay from '../assets/partner_logo/enpay.jpg';
import starmax from '../assets/partner_logo/starmax.png';
//import htec from '../assets/partner_logo/logo.svg';

const WebsiteLayout = ({children}) => {
    const [loading, setLoading] = useState(true);

    const [type, setType] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const mutation = useMutation({
        mutationFn: (formData) => createKlijent(formData),
        onSuccess: (response) => {
            setType(0);
            setMessage(response.data.message);
            setOpen(true);

            const form = document.getElementById("newsletter-form");
            if (form) {
                form.reset();
            }
        },
        onError: (error) => {
            setType(1);
            setMessage(error.response.data.message);
            setOpen(true);
        }
    });

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000)
    }, []);

    if (loading) {
        return (
            <Backdrop
                sx={(theme) => ({background: '#383E42', color: '#FC9303', zIndex: theme.zIndex.drawer + 1})}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        if (formData.get("ime") == "") {
            setType(1);
            setMessage("Molimo vas unesite vaše ime.");
            setOpen(true);
            return;
        }

        if (formData.get("prezime") == "") {
            setType(1);
            setMessage("Molimo vas unesite vaše prezime.");
            setOpen(true);
            return;
        }

        if (formData.get("email") == "") {
            setType(1);
            setMessage("Molimo vas unesite vaš email.");
            setOpen(true);
            return;
        }

        mutation.mutateAsync(formData).catch((e) => {
        });
    }

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );


    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {type == 0 ? (
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%', textAlign: "center"}}
                    >
                        {message}
                    </Alert>
                )}
            </Snackbar>
            <div className="top-bar">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-12">
                            <div className="logo">
                                <a href="/">
                                    <img src={logo} alt="Logo"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 d-none d-lg-block">
                            <div className="row">
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-icon">
                                            <i className="far fa-clock"></i>
                                        </div>
                                        <div className="top-bar-text">
                                            <h3>Radno vreme</h3>
                                            <p>Pon - Pet, 09:00 - 21:00</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-icon">
                                            <i className="fa fa-phone-alt"></i>
                                        </div>
                                        <div className="top-bar-text">
                                            <h3>Pozovite nas</h3>
                                            <p><a href="tel:+381692103985">+381692103985</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-icon">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </div>
                                        <div className="top-bar-text">
                                            <h3>Naša lokacija</h3>
                                            <p><a
                                                href="https://www.google.com/maps/place/Eclectic+detailing+doo/@44.8199987,20.4709148,20.21z/data=!4m5!3m4!1s0x475a7befabedb307:0x44a40ca2add5b03!8m2!3d44.8200229!4d20.4706318">Venizelosova
                                                15, Beograd</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nav-bar">
                <div className="container">
                    <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                        <a href="/" className="navbar-brand">Eclectic Detailing</a>
                        <button type="button" className="navbar-toggler" data-toggle="collapse"
                                data-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div className="navbar-nav mr-auto">
                                <a href="/" className="nav-item nav-link">Početna</a>
                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Usluge</a>
                                    <div className="dropdown-menu">
                                        <a href="/corporate-wash" className="dropdown-item">Corporate
                                            Wash</a>
                                        <a href="/quick-wash" className="dropdown-item">Quick Wash</a>
                                        <a href="/standard-wash" className="dropdown-item">Standard
                                            Wash</a>
                                        <a href="/eclectic-wash" className="dropdown-item">Eclectic
                                            Wash</a>
                                        <a href="/keramicke-zastite" className="dropdown-item">Održavanje
                                            keramičkih zaštita</a>
                                        <a href="/zastitni-premaz" className="dropdown-item">Nanošenje
                                            zaštitnog premaza na lakirane delove</a>
                                        <a href="/nanosenje-voska" className="dropdown-item">Nanošenje voska
                                            ili silera na lak</a>
                                        <a href="/poliranje-farova" className="dropdown-item">Poliranje farova</a>
                                        <a href="/detailing-motornog-prostora" className="dropdown-item">Detailing
                                            motornog prostora</a>
                                        <a href="/dezinfekcija" className="dropdown-item">Dezinfekcija vozila
                                            ozonom i uklanjanje neprijatnih mirisa</a>
                                        <a href="/uklanjanje-fleka" className="dropdown-item">Uklanjanje fleka</a>
                                        <a href="/ciscenje-koznih-sedista" className="dropdown-item">Čišćenje kožnih
                                            elemenata</a>
                                        <a href="/uklanjanje-dlaka" className="dropdown-item">Uklanjanje
                                            dlaka od ljubimaca</a>
                                    </div>
                                </div>
                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Cenovnik</a>
                                    <div className="dropdown-menu">
                                        <a href="/car-wash-cenovnik" className="dropdown-item">Car Wash</a>
                                        <a href="/detailing-cenovnik" className="dropdown-item">Detailing
                                            Studio</a>
                                    </div>
                                </div>
                                <a href="/galerija"
                                   className="nav-item nav-link">Galerija</a>
                                <a href="/o-nama"
                                   className="nav-item nav-link">O nama</a>
                                <a href="/kontakt"
                                   className="nav-item nav-link">Kontakt</a>
                                <a href="/blog"
                                   className="nav-item nav-link">Blog</a>
                                <a href="/prodavnica"
                                   className="nav-item nav-link"><i
                                    className="fa-solid fa-cart-shopping"></i></a>
                            </div>
                            <div className="ml-auto">
                                <a className="btn btn-custom" href="/zakazivanje">Zakažite termin</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div>{children}</div>
            <div className="container">
                <div className="section-header text-center">
                    <p>Premium partneri</p>
                </div>
                <Swiper
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    centeredSlides={true}
                    spaceBetween={30}
                    slidesPerView={1}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    modules={[Autoplay]}
                >
                    <SwiperSlide>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100}}>
                            <a href="https://enpay.rs/" target="_blank" rel="noreferrer">
                                <img width={100}
                                     height={100}
                                     src={enpay}
                                     alt={"AllYes doo"}/>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100}}>
                            <a href="https://www.starmax.rs/" target="_blank" rel="noreferrer">
                                <img width={200}
                                     height={100}
                                     src={starmax}
                                     alt={"Starmax doo"}/>
                            </a>
                        </div>
                    </SwiperSlide>
                    {/*<SwiperSlide>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100}}>
                            <a href="https://htec.com/" target="_blank" rel="noreferrer">
                                <img width={200}
                                     height={100}
                                     src={htec}
                                     alt={"HTEC doo"}/>
                            </a>
                        </div>
                    </SwiperSlide>*/}
                </Swiper>
            </div>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-contact">
                                <h2>Kontaktirajte nas</h2>
                                <p>
                                    <i className="fa fa-map-marker-alt"></i>
                                    <a style={{color: 'white'}}
                                       target="_blank" rel="noreferrer"
                                       href="https://www.google.com/maps/place/Eclectic+detailing+doo/@44.8199987,20.4709148,20z/data=!4m6!3m5!1s0x475a7befabedb307:0x44a40ca2add5b03!8m2!3d44.8200229!4d20.4706318!16s%2Fg%2F11t2_n1hkz">Venizelosova
                                        15, Beograd</a>
                                </p>
                                <p><i className="fa fa-phone-alt"></i>
                                    <a style={{color: 'white'}} target="_blank" rel="noreferrer"
                                       href="tel:+381692103985">+381692103985</a></p>
                                <p><i className="fa fa-envelope"></i>
                                    <a style={{color: 'white'}}
                                       target="_blank" rel="noreferrer"
                                       href="mailto:office@eclectic.rs">office@eclectic.rs</a>
                                </p>
                                <div className="footer-social">
                                    <a className="btn" target="_blank" rel="noreferrer"
                                       href="https://www.instagram.com/eclectic.detailing.beograd/"><i
                                        className="fab fa-instagram"></i></a>
                                    <a className="btn" target="_blank" rel="noreferrer"
                                       href="https://www.instagram.com/eclectic.premium.car.wash/"><i
                                        className="fab fa-instagram"></i></a>
                                    <a className="btn" target="_blank" rel="noreferrer"
                                       href="https://www.linkedin.com/company/eclectic-detailing-doo/"><i
                                        className="fab fa-linkedin-in"></i></a>
                                    <a className="btn" target="_blank" rel="noreferrer"
                                       href="https://www.tiktok.com/@eclecticdetailing"><i
                                        className="fa-brands fa-tiktok"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-link">
                                <h2>Popularni linkovi</h2>
                                <a href="/o-nama">O nama</a>
                                <a href="/kontaktirajte-nas">Kontaktirajte nas</a>
                                <a href="/naše-lokacije">Naše lokacije</a>
                                <a href="/car-wash-cenovnik">Cenovnik Car Wash</a>
                                <a href="/detailing-cenovnik">Cenovnik Detailing</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-link">
                                <h2>Korisnički servisi</h2>
                                <a href="/uslovi-korišćenja-i-prodaje">Uslovi korišćenja i prodaje</a>
                                <a href="/politika-privatnosti">Politika privatnosti</a>
                                <a href="/kolačići">Kolačići</a>
                                <a href="/plaćanje-karticama">Plaćanje karticama</a>
                                <a href="/reklamacije-i-povraćaj-robe">Reklamacije i povraćaj robe</a>
                                <a href="/pravo-na-odustajanje">Pravo na odustajanje</a>
                                <a href="/povraćaj-sredstava">Povraćaj sredstava</a>
                                <a href="/pomoć-i-najčešća-pitanja">Pomoć i najčešća pitanja</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-newsletter">
                                <h2>Novosti i prijava</h2>
                                <form onSubmit={handleSubmit} method="POST" style={{marginTop: 2}} id="newsletter-form">
                                    <input className="form-control" style={{color: 'white'}} type="text"
                                           placeholder="Ime *"
                                           name="ime"/>
                                    <input className="form-control" style={{color: 'white'}} type="text"
                                           placeholder="Prezime *"
                                           name="prezime"/>
                                    <input className="form-control" style={{color: 'white'}}
                                           type="email"
                                           placeholder="E-mail *" name="email"/>
                                    <button className="btn btn-custom" type="submit">Prijavite se</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <a href="https://www.mastercard.com/global/en.html"><img
                        src={logo1}
                        style={{height: 45 + 'px', marginLeft: '2%', marginBottom: 10 + 'px'}}/></a>
                    <a href="https://www.mastercard.com/global/en.html"><img
                        src={logo2}
                        style={{height: 45 + 'px', marginLeft: '2%', marginBottom: 10 + 'px'}}/></a>
                    <a href="https://rs.visa.com/"><img
                        src={logo3}
                        style={{height: 45 + 'px', marginLeft: '2%', marginBottom: 10 + 'px'}}/></a>
                    <a href="https://dinacard.nbs.rs/cirilica/"><img src={logo4}
                                                                     style={{
                                                                         height: 45 + 'px',
                                                                         marginLeft: '2%',
                                                                         marginBottom: 10 + 'px'
                                                                     }}/></a>


                    <a href="https://www.otpbanka.rs/"><img src={logo5}
                                                            style={{
                                                                height: 45 + 'px',
                                                                marginLeft: '2%',
                                                                marginBottom: 10 + 'px'
                                                            }}/></a>
                    <a href="https://rs.visa.com/"><img
                        src={logo6}
                        style={{height: 45 + 'px', marginLeft: '2%', marginBottom: 10 + 'px'}}/></a>
                    <a href="https://www.mastercard.com/global/en.html"><img
                        src={logo7}
                        style={{height: 45 + 'px', marginLeft: '2%', marginBottom: 10 + 'px'}}/></a>
                </div>
                <div className="container copyright">
                    <p>
                        &copy; <a href="/">Eclectic {new Date().getFullYear()}</a>, Sva prava zadržana.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WebsiteLayout;