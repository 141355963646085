import React from 'react';

const StandardWashPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Standard Wash</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Standard Wash</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Standard Wash</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            *vizuelna inspekcija vozila
                            <br/><br/>
                            *izduvavanje pištoljima za vazduh i Tornadorima
                            <br/><br/>
                            *detaljno usisavanje
                            <br/><br/>
                            *brisanje enterijera proizvodima za čišćenje i mikrofiber krpama
                            <br/><br/>
                            *brisanje stakala
                            <br/><br/>
                            *pranje uvala vrata i poklopca rezervoara
                            <br/><br/>
                            *pranje lica felni i guma
                            <br/><br/>
                            *uklanjanje insekata ukoliko ima potrebe
                            <br/><br/>
                            *pretpranje AKTIVNOM PENOM
                            <br/><br/>
                            *puškiranje DEMINERALIZOVANOM vodom
                            <br/><br/>
                            *sigurno ručno pranje(2 bucket method)
                            <br/><br/>
                            *puškiranje DEMINERALIZOVANOM vodom
                            <br/><br/>
                            *nanosenje zaštite na spoljašnjost vozila
                            <br/><br/>
                            *puškiranje DEMINERALIZOVANOM vodom
                            <br/><br/>
                            *detaljno sušenje
                            <br/><br/>
                            *nanošenje dresinga na gume-mat ili sjaj, po želji.
                            <br/><br/>
                            Dobrodošli u svet detaljnog pranja vozila!
                            <br/><br/>
                            LIGHT pranje je, po našem skromnom mišljenju, apsolutni minimum kada pričamo o kvalitetnom
                            pranju i održavanju vozila.
                            <br/><br/>
                            U sklopu ove usluge, tehničar će najpre izvršiti vizuelnu inspekciju vozila sa
                            Vama,identifikujući problematične delove-ukoliko ih ima.
                            <br/><br/>
                            Potom krećemo sa enterijerom: patosnice se vade, kompresorskim pištoljima se izduvavaju
                            nepristupačni delovi, a sedista i ostatak vozila se prelazi specijalnim
                            uređajima-TORNADORIMA, koji će-obzirom da funkcionišu kao mini tornador,je li-, dubinski
                            “protresti” materijal sedista,poda, gepeka, i na površinu izbaciti prašinu, koja će se onda
                            detaljnim usisavanjem pokupiti. Isti postupak sleduje i patosnicama, ukoliko nisu gumene( u
                            tom slučaju se nanosi kvalitetno sredstvo za čišćenje(APC), ribaju se, ispiraju i suše).
                            <br/><br/>
                            Spomenuli smo detaljno usisavanje. To i mislimo. Tabla,centralna konzola,vrata će biti
                            pređena ultra mekim četkicama(koje ne mogu ogrebati osetljive/meke materijale), a sedišta,
                            pod i gepek će biti detaljno usisani.
                            <br/><br/>
                            Nakon toga, sve te površine će biti prebrisane odgovarajućom hemijom i kvalitetnim
                            mikrofiber krpama, te će sve te površine biti zaista čiste.
                            <br/><br/>
                            Vraćamo patosnice u vozilo.
                            <br/><br/>
                            Imajte na umu da u okviru ove usluge NE NANOSIMO dresinge na plasticne delove i/ili kožne
                            delove enterijera, te,ukoliko ovaj put ne želite naše premijum ECLECTIC WASH (u okviru kojeg
                            je ovo sastavni deo), svakako možete da,opciono, izaberete nešto od sledećeg, uz minimalnu
                            doplatu:
                            <br/><br/>
                            -nanošenje kvalitetnog dresinga na plastike,
                            <br/><br/>
                            -nanošenje kvalitetne kreme na kožne delove-”prehrana kože”,
                            <br/><br/>
                            -ozonizacija, tj dezinfekcija celog vozila, uključujući i ventilaciju.
                            <br/><br/>
                            Prelazimo na spoljašnjost.
                            <br/><br/>
                            Najpre ćemo naneti čistač na uvale vrata i sama vrata,gepek i poklopac rezervoara. zatim
                            ćemo agitirati nečistoće i izduvati tornadorom, višak ćemo pokupiti mikrofiber krpama.
                            <br/><br/>
                            Preći ćemo na felne i gume, na koje ćemo naneti odgovarajući kvalitetnu hemiju, dok ćemo na
                            podkrila naneti čistač. Hemiju ćemo naneti i na unutrašnjost felne, a lice felni i guma ćemo
                            detaljno preći posebnim četkama, kojima ćemo pospešiti dejstvo same hemije, a potom ćemo ih
                            detaljno isprati demineralizovanom vodom.
                            <br/><br/>
                            Nanosimo AKTIVNU PENU,koja će “omekšati” tvrdokornu nečistoću,tako što će aktivni sastojci u
                            njoj obložiti čestice prljavštine i,pod uticajem gravitacije,povući najveći deo sa sobom dok
                            se spušta niz vozilo.
                            <br/><br/>
                            Potom sledi detaljno ispiranje vozila.
                            <br/><br/>
                            Nakon toga, pristupamo jedinom metodu kontaktnog iliti ručnog pranja koji minimizuje rizik
                            od oštećivanja Vašeg laka-pranja tehnikom koja se u detailingu zove “2 bucket
                            method”(tehnika pranja pomoću dve kante). Ovom tehnikom se obezbeđuje da rizik od grebanja
                            laka, nanošenja novih riseva i sl. bude sveden na apsolutni minimum. Kvalitetan šampon i
                            demineralizovana voda se podrazumevaju.
                            <br/><br/>
                            Sledi ponovno ispiranje demineralizovanom vodom, te nanošenje neke od kvalitetnih, a brzih
                            vidova zaštite, što će proceniti Vaš tehničar.
                            <br/><br/>
                            Sledi sušenje AIR DRYER-om, koji će bez ikakvog fizičkog kontakta osušiti Vaš auto velikom
                            brzinom TOPLOG vazduha, a zaostale kapi će se eventualno “pokupiti” premijum peškirima za
                            sušenje vozila.
                            <br/><br/>
                            Potom brišemo sva stakla iznutra.
                            <br/><br/>
                            Za finale-izabraćete da li želite mat ili sjajni dresing za gume, izabraćete parfem koji će
                            vam tehničar naneti u unutrašnjost.
                            <br/><br/>
                            <strong>Procenjeno vreme trajanja usluge: 1h15m-1h30m</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StandardWashPage;