import React from 'react';

const EclecticWashPage = () => {
    return (
        <div>
            <div class="page-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Eclectic Wash</h2>
                        </div>
                        <div class="col-12">
                            <a href="/">Početna</a>
                            <a href="">Eclectic Wash</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="container">
                    <div class="section-header text-center">
                        <p>Eclectic Wash</p>
                        <h2>Opis usluge</h2>
                    </div>
                    <div class="row">
                        <p>
                            *vizuelna inspekcija vozila<br/>

                            *izduvavanje pištoljima za vazduh i Tornadorima<br/>

                            *detaljno usisavanje<br/>

                            *brisanje enterijera proizvodima za čišćenje i mikrofiber krpama<br/>

                            *brisanje stakala<br/>

                            *nanošenje dresinga za plastiku<br/>

                            *prehrana kožnog enterijera<br/>

                            *pranje uvala vrata i poklopca rezervoara<br/>

                            *pranje lica felni i guma<br/>

                            *uklanjanje insekata ukoliko ima potrebe<br/>

                            *pretpranje AKTIVNOM PENOM<br/>

                            *puškiranje DEMINERALIZOVANOM vodom<br/>

                            *sigurno ručno pranje(2 bucket method)<br/>

                            *puškiranje DEMINERALIZOVANOM vodom<br/>

                            *nanosenje zaštite na spoljašnjost vozila<br/>

                            *puškiranje DEMINERALIZOVANOM vodom<br/>

                            *detaljno sušenje<br/>

                            *nanošenje zaštite u vidu sprej ditejlera sa Si tehnologijom<br/>

                            *nanošenje dresinga na gume-mat ili sjaj, po želji.<br/>

                            Ovo je usluga zbog koje ste došli kod nas. Usluga urađena kako i treba da bude urađena.
                            Usluga namenjena ljudima koji nikad neće reći da im je auto samo prevozno sredstvo.<br/>

                            Kvalitetni proizvodi, kvalitetna usluga, najviši standardi rada posle kojeg vas neće
                            zadovoljiti ni jedno drugo “pranje”.<br/>

                            U okviru nje će Vaš ljubimac dobiti tretman kakav i treba da bude. Tretman koji i
                            zaslužuje(te).<br/>

                            Svaki deo eksterijera i enterijera će dobiti pažnju, a kvalitetni proizvodi će taj rezultat
                            održavati mnogo duže od inferiornih proizvoda iz “klasičnih” perionica. U ovoj usluzi ćete
                            videti suštinu i značenje reči “detailing”, a , nadamo se, i preko decenije iskustva u
                            istom.<br/>

                            Kao i svaku prethodnu, i ovu započinjemo vizuelnom inspekcijom vozila sa Vama,identifikujući
                            problematične delove-ukoliko ih ima, a potom krećemo sa radom na enterijeru.<br/>

                            Patosnice se vade, kompresorskim pištoljima se izduvavaju nepristupačni delovi, a sedista i
                            ostatak vozila se prelazi specijalnim uređajima-TORNADORIMA,koji će dubinski “protresti”
                            materijal sedista,poda, gepeka, i na površinu izbaciti prašinu, koja će se onda detaljnim
                            usisavanjem pokupiti. Isti postupak sleduje i patosnicama, ukoliko nisu gumene( u tom
                            slučaju se nanosi kvalitetno sredstvo za čišćenje(APC), ribaju se, ispiraju i suše).<br/>

                            Tabla,centralna konzola,vrata će biti pređena ultra mekim četkicama(koje ne mogu ogrebati
                            osetljive/meke materijale), a sedista, pod i gepek ce biti detaljno usisani.<br/>

                            Nakon toga, sve te površine će biti prebrisane odgovarajućom hemijom i kvalitetnim
                            mikrofiber krpama, te će sve te površine biti zaista čiste.<br/>

                            Vraćamo patosnice u vozilo, a ukoliko su gumene-u tom slučaju se nanosi kvalitetno sredstvo
                            za čišćenje(APC), ribaju se, ispiraju i SUŠE, a ten onda vraćaju u vozilo.<br/>

                            Pošto je ovo usluga koja nosi naše ime-nastavljamo sa radom.<br/>

                            Na plastične delove se nanosi kvalitetni dresing za plastiku, koji NEĆE umastiti, usijati i
                            statičkim elektricitetom privlačiti prašinu, NEĆE reagovati sa plastikom na neki neželjeni
                            način, NEĆE je izbledeti i slično.<br/>

                            Štaviše-daće joj fin, satenski sjaj(sheen), baš kakav plastika i treba da ima, a pod dodirom
                            neće biti masna.<br/>

                            Ukoliko plastika već ima gorenavedene “probleme”, ili je zapuštena, izbledela i slično-biće
                            vam predloženo da nanesemo neki od proizvoda koji daju za nijansu više sjaja koji će
                            “zamaskirati” postojeće stanje.<br/>

                            Takođe, ukoliko imate kožni enterijer, u okviru ove usluge će ista biti prehranjena
                            profesionalnim proizvodima koji će je omekšati,dati suptilan osećaj pod rukom,ostaviti bez
                            dodatnog sjaja(ukoliko je koža u takvom stanju da ne zahteva tretman dubinskog čišćenja) i
                            otpustiti svoj prefinjeni miris.<br/>

                            Krećemo sa radom na spoljašnjosti.<br/>

                            Najpre ćemo naneti čistač na uvale vrata i sama vrata,gepek i poklopac rezervoara. zatim
                            ćemo agitirati nečistoće i izduvati tornadorom, višak ćemo pokupiti mikrofiber krpama.<br/>

                            Preći ćemo na felne i gume, na koje ćemo naneti odgovarajući kvalitetnu hemiju, dok ćemo na
                            podkrila naneti čistač.<br/>

                            Posebnim četkama ćemo pospešiti dejstvo same hemije,detaljno očistiti felne “spolja i
                            iznutra”, a potom ćemo ih detaljno isprati demineralizovanom vodom.<br/>

                            Zatim nanosimo AKTIVNU PENU,koja će “omekšati” tvrdokornu nečistoću,tako što će aktivni
                            sastojci u njoj obložiti čestice prljavštine i,pod uticajem gravitacije,povući najveći deo
                            sa sobom dok se spušta niz vozilo.<br/>

                            Potom sledi detaljno ispiranje vozila.<br/>

                            Nakon toga, pristupamo jedinom metodu kontaktnog iliti ručnog pranja koji minimizuje rizik
                            od oštećivanja Vašeg laka-pranja tehnikom koja se u detailingu zove “2 bucket
                            method”(tehnika pranja pomoću dve kante). Ovom tehnikom se obezbeđuje da rizik od grebanja
                            laka, nanošenja novih riseva i sl. bude sveden na apsolutni minimum. Kvalitetan šampon i
                            demineralizovana voda se podrazumevaju.<br/>

                            Sledi ponovno ispiranje demineralizovanom vodom, a zatim-sušenje AIR DRYER-om, koji će bez
                            ikakvog fizičkog kontakta osušiti Vaš auto velikom brzinom TOPLOG vazduha, a zaostale kapi
                            će se eventualno “pokupiti” premijum peškirima za sušenje vozila.<br/>

                            Potom brišemo sva stakla iznutra.<br/>

                            U okviru ove usluge, dobićete poslednju reč tehnologije u zaštiti laka za redovno pranju i
                            održavanju- neki od ditejling sprejeva sa visokim procentom Si u sebi,što mu garantuje
                            dugotrajnost, postojanost, hidrofobnost i dubinu boje. Neki se nanose zasebno, neki u sklopu
                            pranja, to ne treba da Vas buni,jer će tehničar izabrati onaj koji treba da najbolje deluje
                            na konkretnom vozilu. Ukoliko pak imate želje da se nanese nešto po Vašem izboru od
                            ponuđenih proizvoda-slobodno naglasite PRE početka procesa pranja.<br/>

                            Za kraj-izabraćete da li želite mat ili sjajni dresing za gume, i okretanje vratova
                            prolaznika i drugih vozača može da počne.<br/>

                            Opciono, uz doplatu, u sklopu ove usluge možete izabrati i :<br/>

                            -nanošenje kvalitetnog KARNAUBA voska mašinski(daje nenadmašne rezultate u dubini
                            boje,sakrivanju riseva i refleksiji, ali je trajnost do mesec dana)<br/>

                            -nanošenje neke od polimernih zaštita na lak(sjajna refleksija i dubina boje, trajnost oko 6
                            meseci),<br/>

                            -nanošenje nekog od NAPREDNIH PREMAZA za zaštitu laka,koji imaju sve gorenavedene
                            kvalitete,ektremno su hidrofobni i traju oko 16 meseci (i duže, uz kvalitetno
                            održavanje),<br/>

                            -nanošenje keramičke zaštite na kožu,staklo,felne.<br/>

                            -ozonizaciju(dezinfekciju) vozila,uključujući i ventilaciju,<br/>

                            -uklanjanje dlaka od kućnih ljubimaca (za vrlo zaprljane površine),<br/>

                            -uklanjanje neprijatnih mirisa.<br/>

                            Pošto neke od ovih usluga iziskuju dodatno vreme, OBAVEZNO je unapred zakazati izabranu
                            opciju.<br/>

                            <strong>Procenjeno vreme trajanja usluge:1h30m do 1h45m(bez dodatnih opcija).</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EclecticWashPage;