import React from 'react';

const ShopPage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Prodavnica</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Prodavnica</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Prodavnica</p>
                        <h2>Naši premium prozivodi i pokloni</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShopPage;