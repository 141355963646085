import axios from 'axios'
import {url} from './url';

export const checkPromoKod = async (promoKod) => {
    return axios.get(url + 'promo-kod/' + promoKod, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export const getPromoKodovi = async () => {
    return axios.get(url + 'promo-kodovi', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const createPromoKod = async (formData) => {
    return axios.post(url + 'create-promo-kod', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const deletePromoKod = async (formData) => {
    return axios.post(url + 'delete-promo-kod', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}