import axios from 'axios'
import {url} from './url';

export const getScenario = async () => {
    return axios.get(url + 'scenario', {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export const getScenarioAdmin = async () => {
    return axios.get(url + 'scenario-admin', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const createScenario = async (formData) => {
    return axios.post(url + 'create-scenario', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}