import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Alert, Button, LinearProgress, Paper, TextField, Typography} from "@mui/material";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../components/Notification";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../assets/language/tableLang";
import {createPartner, deletePartner, getPartneri} from "../../apis/PartnerApi";
import DeleteIcon from '@mui/icons-material/Delete';

const AdminPartneriPage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const [nazivError, setNazivError] = React.useState(false);
    const [nazivErrorMessage, setNazivErrorMessage] = React.useState("");

    const [telefonError, setTelefonError] = React.useState(false);
    const [telefonErrorMessage, setTelefonErrorMessage] = React.useState("");

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const {data, isLoading, isError} = useQuery({
        queryKey: ["getPartneri"],
        queryFn: getPartneri,
    });

    const mutation = useMutation({
        mutationFn: (formData) => createPartner(formData),
        onSuccess: (response) => {
            setOpen(false);
            setOpenN(true);
            setType(1);
            setMessage(response.data.message);
            queryClient.invalidateQueries(["getPartneri"]);
        },
        onError: (error) => {
            setOpenN(true);
            setType(2);
            setMessage(error.response.data.message);
        },
    });

    const del = useMutation({
        mutationFn: (formData) => deletePartner(formData),
        onSuccess: (response) => {
            setOpen(false);
            setOpenN(true);
            setType(1);
            setMessage(response.data.message);
            queryClient.invalidateQueries(["getPartneri"]);
        },
        onError: (error) => {
            setOpenN(true);
            setType(2);
            setMessage(error.response.data.message);
        },
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "pib",
            headerName: "PIB",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "uuid",
            headerName: "UUID",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "popust",
            headerName: "Popust (%)",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../partner/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
        {
            field: "delete",
            headerName: "Brisanje",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="error"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<DeleteIcon/>}
                        onClick={(e) => handleDelete(e, params.row.id)}
                    >
                        Obriši
                    </Button>
                );
            },
        },
    ];

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        mutation.mutateAsync(formData).catch((e) => {
        });
    };

    const handleDelete = (event, value) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('id', value);
        del.mutateAsync(formData).catch((e) => {
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Notification
                    type={type}
                    message={message}
                    open={openN}
                    setOpen={setOpenN}
                />
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <AdminPanelSettingsTwoToneIcon size={22} sx={{mr: 2}}/>
                        Informacije o partnerima
                    </Typography>
                    {open ? (
                        <Button
                            variant="contained"
                            sx={{width: "100%", alignSelf: "center", mt: 2}}
                            endIcon={<RemoveTwoToneIcon/>}
                            onClick={handleOpen}
                        >
                            Sakrijte formu za dodavanje novog partnera
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{width: "100%", alignSelf: "center", mt: 2}}
                            endIcon={<AddTwoToneIcon/>}
                            onClick={handleOpen}
                        >
                            Dodajte novog partnera
                        </Button>
                    )}
                </Paper>
            </Grid>
            <Grid style={open ? null : {display: "none"}} size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography
                        sx={{textAlign: "center", color: "white"}}
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Forma za dodavanje partnera
                    </Typography>
                    <Box
                        sx={{m: 2}}
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            margin="normal"
                            fullWidth
                            name="naziv"
                            id="naziv"
                            label="Naziv partnera"
                            required
                            variant="outlined"
                            error={nazivError}
                            helperText={nazivErrorMessage}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="pib"
                            id="pib"
                            label="PIB partnera"
                            required
                            variant="outlined"
                            error={telefonError}
                            helperText={telefonErrorMessage}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="email"
                            id="email"
                            label="Email partnera"
                            required
                            variant="outlined"
                            error={telefonError}
                            helperText={telefonErrorMessage}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="popust"
                            id="popust"
                            label="Popust partnera"
                            variant="outlined"
                            error={telefonError}
                            helperText="Standardno 10%, unesite broj 10-100 da promenite"
                        />
                        <Button
                            variant="contained"
                            sx={{width: "100%", alignSelf: "center", mt: 2}}
                            endIcon={<AddTwoToneIcon/>}
                            type="submit"
                        >
                            Unesite novog partnera
                        </Button>
                    </Box>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja partnera!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AdminPartneriPage;