import axios from 'axios'
import {url} from './url';

export const getTermini = async (datum) => {
    return axios.get(url + 'termini/' + datum, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const getTerminiAdmin = async () => {
    return axios.get(url + 'termini-admin', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const getTerminiRadnik = async () => {
    return axios.get(url + 'termini-radnik', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const getTermin = async (id) => {
    return axios.get(url + 'termin/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const createTermin = async (formData) => {
    return axios.post(url + 'create-termin', formData, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export const cancelTermin = async (formData) => {
    return axios.post(url + 'cancel-termin', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const createTerminRadnik = async (formData) => {
    return axios.post(url + 'create-termin-radnik', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const updateTerminStatus = async (code, uuid) => {
    return axios.get(url + 'update-termin/' + code + "/" + uuid, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}