import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import AdministratorLayout from "../layout/AdministratorLayout";

const AdministratorRoute = () => {
    const role = localStorage.getItem("role");

    if (role != 1) {
        localStorage.clear();
        return <Navigate to="/login"/>;
    }

    return (
        <AdministratorLayout>
            <Outlet/>
        </AdministratorLayout>
    );
};

export default AdministratorRoute;