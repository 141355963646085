import React from 'react';

const PomocIPitanjaPage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Pomoć</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Pomoć</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Naša Pomoć</p>
                        <h2>Pomoć</h2>
                    </div>
                    <div className="row" id="post-div">
                    </div>
                    <div className="row">
                        <div className="col-12">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PomocIPitanjaPage;