import axios from 'axios'
import {url} from './url';

export const getUsluge = async () => {
    return axios.get(url + 'usluge', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const createUsluga = async (formData) => {
    return axios.post(url + 'create-usluga', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const getWashCenovnik = async () => {
    return axios.get(url + 'wash-cenovnik', {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export const getDetailingCenovnik = async () => {
    return axios.get(url + 'detailing-cenovnik', {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export const deleteUsluga = async (formData) => {
    return axios.post(url + 'delete-usluga', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const getUsluga = async (id) => {
    return axios.get(url + 'usluga/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const updateCena = async (formData) => {
    return axios.post(url + 'update-cena', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const addOpisEP = async (formData) => {
    return axios.post(url + 'add-opis', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const deleteOpisEP = async (formData) => {
    return axios.post(url + 'delete-opis', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export const canFitUslugaEP = async (formData) => {
    return axios.post(url + 'can-fit-usluga', formData, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}