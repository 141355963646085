import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    Alert,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid2";
import Notification from "../../components/Notification";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../assets/language/tableLang";
import {createPromoKod, deletePromoKod, getPromoKodovi} from "../../apis/PromoKodApi";
import {getPartneri} from "../../apis/PartnerApi";
import {srLatn} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";

const AdminPromoKodoviPage = () => {
    const queryClient = useQueryClient();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const [partner, setPartner] = React.useState(null);
    const [datumDo, setDatumDo] = useState(new Date());

    const handleChange = (event) => {
        setPartner(event.target.value);
    };

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const {data, isLoading, isError} = useQuery({
        queryKey: ["getPromoKodovi"],
        queryFn: getPromoKodovi,
    });

    const {data: partneri, isLoading: loadingPartneri, isError: errorPartneri} = useQuery({
        queryKey: ["getPartneri"],
        queryFn: getPartneri,
    });

    const mutation = useMutation({
        mutationFn: (formData) => createPromoKod(formData),
        onSuccess: (response) => {
            setOpen(false);
            setOpenN(true);
            setType(1);
            setMessage(response.data.message);
            queryClient.invalidateQueries(["getPromoKodovi"]);
        },
        onError: (error) => {
            setOpenN(true);
            setType(2);
            setMessage(error.response.data.message);
        },
    });

    const del = useMutation({
        mutationFn: (formData) => deletePromoKod(formData),
        onSuccess: (response) => {
            setOpen(false);
            setOpenN(true);
            setType(1);
            setMessage(response.data.message);
            queryClient.invalidateQueries(["getPromoKodovi"]);
        },
        onError: (error) => {
            setOpenN(true);
            setType(2);
            setMessage(error.response.data.message);
        },
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv_partnera",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "krajnji_datum",
            headerName: "Datum isteka",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                const date = new Date(params.row.krajnji_datum);
                return (
                    <span>{date.getDate()}/{(date.getMonth() + 1)}/{date.getFullYear()}</span>
                );
            }
        },
        {
            field: "kod",
            headerName: "Promo kod",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "popust",
            headerName: "Popust (%)",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "delete",
            headerName: "Brisanje",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="error"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<DeleteIcon/>}
                        onClick={(e) => handleDelete(e, params.row.id)}
                    >
                        Obriši
                    </Button>
                );
            },
        },
    ];

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('id_partner', partner);
        formData.append('krajnji_datum', datumDo.getFullYear() + '-' + (datumDo.getMonth() + 1) + "-" + datumDo.getDate());

        mutation.mutateAsync(formData).catch((e) => {
        });
    };

    const handleDelete = (event, value) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('id', value);
        del.mutateAsync(formData).catch((e) => {
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Notification
                    type={type}
                    message={message}
                    open={openN}
                    setOpen={setOpenN}
                />
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <AdminPanelSettingsTwoToneIcon size={22} sx={{mr: 2}}/>
                        Informacije o promo kodovima
                    </Typography>
                    {open ? (
                        <Button
                            variant="contained"
                            sx={{width: "100%", alignSelf: "center", mt: 2}}
                            endIcon={<RemoveTwoToneIcon/>}
                            onClick={handleOpen}
                        >
                            Sakrijte formu za dodavanje novog promo koda
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{width: "100%", alignSelf: "center", mt: 2}}
                            endIcon={<AddTwoToneIcon/>}
                            onClick={handleOpen}
                        >
                            Dodajte novi promo kod
                        </Button>
                    )}
                </Paper>
            </Grid>
            <Grid style={open ? null : {display: "none"}} size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography
                        sx={{textAlign: "center", color: "white"}}
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Forma za dodavanje promo kodova
                    </Typography>
                    <Box
                        sx={{m: 2}}
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        {loadingPartneri ? <CircularProgress/> : (
                            <FormControl required fullWidth>
                                <InputLabel id="demo-simple-select-label">Izaberite partnera</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={partner}
                                    label="Izaberite partnera"
                                    onChange={handleChange}
                                >
                                    {partneri?.data.map((item, index) => (
                                        <MenuItem value={item.id}>{item.naziv}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <TextField
                            required
                            margin="normal"
                            fullWidth
                            name="kod"
                            id="kod"
                            label="Kod"
                            variant="outlined"
                        />
                        <TextField
                            required
                            margin="normal"
                            fullWidth
                            name="popust"
                            id="popust"
                            label="Popust"
                            variant="outlined"
                        />
                        <LocalizationProvider adapterLocale={srLatn} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disablePast={true}
                                sx={{mt: 2, mb: 2, width: '100%'}}
                                label="Izaberite datum završetka važenja promo koda *"
                                placeholder="Izaberite datum završetka važenja promo koda *"
                                value={datumDo}
                                onChange={(newValue) => setDatumDo(newValue)}
                            />
                        </LocalizationProvider>
                        <Button
                            variant="contained"
                            sx={{width: "100%", alignSelf: "center", mt: 2}}
                            endIcon={<AddTwoToneIcon/>}
                            type="submit"
                        >
                            Unesite novi promo kod
                        </Button>
                    </Box>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        height: 700,
                    }}
                >
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja promo kodova!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AdminPromoKodoviPage;