import axios from 'axios'
import {url} from './url';

export const login = async (formData) => {
    return axios.post(url + 'login', formData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export const logout = async (formData) => {
    return axios.post(url + 'logout', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
}