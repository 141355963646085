import React from 'react';
import slika from '../../../assets/img/kartice.jpg';

const PlacanjeKarticamaPage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Plaćanje karticama</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Plaćanje karticama</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Plaćanje karticama</p>
                        <h2>Informacije o plaćanju</h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>Svoju narudžbinu možete platiti koristeći Visa, Visa Electron, MasterCard ili Maestro
                                platnu karticu. Kartica mora biti odobrena od strane banke izdavaoca za online
                                (Internet) plaćanje.</p>

                            <p style={{
                                marginTop: 0 + "in",
                                marginRight: 0 + "in",
                                marginBottom: 7.5 + "pt",
                                marginLeft: 0 + "in"
                            }}>
                                Prilikom
                                online naručivanja, odabirom odgovarajućeg načina plaćanja bićete preusmereni na
                                stranicu OTP Banke koja je zaštićena i sigurna za ovakav način plaćanja. Niti jednog
                                trenutka podaci o Vašoj platnoj kartici nisu dostupni našem sistemu.</p>

                            <p style={{
                                marginTop: 0 + "in",
                                marginRight: 0 + "in",
                                marginBottom: 7.5 + "pt",
                                marginLeft: 0 + "in"
                            }}>Na ovoj
                                stranici je potrebno da unesete sledeće podatke: Broj kartice, Datum isteka
                                i&nbsp; CVC2/CVV2 kod, koje možete pročitati sa Vaše kartice.</p>

                            <p><img alt="" src={slika}/></p>

                            <p><em>*Unos i provera podataka isključivo se obavljaju između korisnika kartice i OTP
                                Banke, a Internet trgovac nema uvid u podatke koji se razmenjuju.</em></p>

                            <p>Prilikom unošenja podataka o platnoj kartici, poverljive informacija se prenose putem
                                javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao
                                trenutno najsavremenije kriptografske tehnologije. Sva plaćanja će biti obavljena u
                                dinarima (RSD). Ukoliko se plaća platnim karticama inostranih Banaka izdavalaca,
                                dinarski iznos transakcije će biti konvertovan u settlement valutu Banke (EUR) prema
                                kursu Narodne Banke Srbije. Pri zaduživanju Vaše platne kartice, već konvertovan iznos
                                ce se konvertovati u Vašu lokalnu valutu, prema kursu koji primenjuju operatori platnih
                                kartica. Plaćanje nije moguće u stranoj valuti, već isključivo u valuti RSD. Plaćanje je
                                moguće platnim karticama domaćih i inostranih banaka.</p>

                            <p>Kupac koji koristi uslugu online plaćanja karticama preko e-shopa mora ispunjavati
                                sledeće uslove:</p>

                            <ul>
                                <li>&nbsp;&nbsp;&nbsp; Isporuka robe je moguća samo na teritoriji Republike Srbije, na
                                    adresi navedenoj u porudžbini.
                                </li>
                                <li>&nbsp;&nbsp;&nbsp; Samo vlasnik platne kartice može izvršiti plaćanje.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlacanjeKarticamaPage;