import React, {useState} from 'react';
import Notification from "../../components/Notification";
import {Button, Paper, Typography} from "@mui/material";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import Grid from "@mui/material/Grid2";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {useMutation} from "@tanstack/react-query";
import {createMailingCampaign} from "../../apis/NewsletterApi";

const AdminNewsLetterPage = () => {
    const [editorHtml, setEditorHtml] = useState('');

    const handleChange = (value) => {
        setEditorHtml(value);
    };

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('poruka', editorHtml);
        mutation.mutateAsync(formData).catch((e) => {
        });
    }

    const mutation = useMutation({
        mutationFn: (formData) => createMailingCampaign(formData),
        onSuccess: (response) => {
            setOpenN(true);
            setType(1);
            setMessage(response.data.message);
        },
        onError: (error) => {
            setOpenN(true);
            setType(2);
            setMessage(error.response.data.message);
        },
    });

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Notification
                    type={type}
                    message={message}
                    open={openN}
                    setOpen={setOpenN}
                />
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <AdminPanelSettingsTwoToneIcon size={22} sx={{mr: 2}}/>
                        Informacije o partnerima
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <ReactQuill
                        value={editorHtml}
                        onChange={handleChange} // On change, update the state
                        theme="snow" // You can use different themes like 'snow' or 'bubble'
                    />
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <h2 style={{color: 'white'}}>Izlaz:</h2>
                    <div dangerouslySetInnerHTML={{__html: editorHtml}}/>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper
                    sx={{
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <Button fullWidth variant="contained" onClick={handleSubmit}>Pošalji mailing kampanju</Button>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AdminNewsLetterPage;