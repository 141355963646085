import React from 'react';

const KolaciciPage = () => {
    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Kolačići</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Kolačići</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Naši Kolačići</p>
                        <h2>Kolačići</h2>
                    </div>
                    <div className="row" id="post-div">
                    </div>
                    <div className="row">
                        <div className="col-12">
                            Eclectic doo popravka i održavanje motornih vozila Beograd (u daljem tekstu: Eclectic ili
                            mi) preko veb-sajta: www.eclectic.rs (u daljem tekstu: Veb-sajt) koristi kolačiće koji su
                            neophodni za funkcionisanje Veb-sajta i koji omogućavaju bolje korisničko iskustvo i
                            kupovinu preko Veb-sajta. Više o kolačićima pročitajte ovde.

                            <h5>OBAVEŠTENJE O KOLAČIĆIMA (COOKIES)</h5>

                            U cilju pravilnog funkcionisanja Veb-sajta, pružanja boljeg korisničkog iskustva i
                            unapređenja Veb-sajta, Veb-sajt pohranjuje određenu količinu informacija (kolačića,
                            cookies-a) na Vaš uređaj. Pre pohranjivanja kolačića na Vaš uređaj dužni smo da dobijemo Vaš
                            pristanak. U tom smislu Vas obaveštavamo da korišćenjem Veb-sajta pristajete na upotrebu
                            kolačića od strane Games-a u skladu sa ovim Obaveštenjem.

                            Korišćenje kolačića možete da blokirate odgovarajućim podešavanjem u Vašem internet
                            pretraživaču na koji način uskraćujete ili opozivate pristanak za njihovo korišćenje.
                            Blokiranjem kolačića i dalje možete da pretražujete Veb-sajt ali Vam neke njegove mogućnosti
                            neće biti dostupne.


                            <h5>Šta je kolačić?</h5>

                            Kolačići su tekstualni dokumenti koji se čuvaju na Vašem korisničkom uređaju, a nastaju dok
                            posećujete Veb-sajt. Kada ponovo budete pristupili Veb-sajtu Games može preuzeti podatke sa
                            Vašeg uređaja, a koji su sadržani u kolačićima. Posredstvom kolačića od Vas prikupljamo
                            podatke, kao što su: naziv domena i računara sa kojeg pristupate internetu, adresu internet
                            protokola (IP) računara koji koristite, datum i vreme pristupanja Veb-sajtu, internet adrese
                            stranica preko kojih ste pristupili Veb-sajtu.

                            <h5>Koju vrstu kolačića koristimo?</h5>

                            Na Veb-sajtu koristimo više vrsta kolačića koji se razlikuju po svojoj svrsi, dužini
                            trajanja i veb-sajtu koji ih je integrisao.

                            Privremeni kolačići pohranjuju privremene podatke, kao što su podaci u vezi sa konkretnom
                            kupovinom preko Veb-sajta koji se brišu sa uređaja nakon zatvaranja Vašeg internet
                            pretraživača.

                            Trajne kolačiće koristimo za potrebe pravilnog i boljeg funkcionisanja Veb-sajta, a što Vama
                            kao registrovanom korisniku olakšava pristup, a nama pomaže da bolje razumemo potrebe
                            korisnika i prilagodimo Veb-sajt potrebama korisnika.

                            Ispod se nalazi spisak kolačića koje koristimo i podataka koje preko njih prikluplajmo:
                            <table className="table table-bordered table-hover" style={{textAlign: 'center'}}>
                                <thead>
                                <tr>
                                    <th>NAZIV KOLAČIĆA</th>
                                    <th>OPIS KOLAČIĆA</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                            <h5>Šta su kolačići trećih lica?</h5>

                            Kolačići trećih lica potiču sa drugih veb-sajtova koje posećujete, na
                            kojima se Eclectic reklamira. Pomoću tih kolačića može na primer da se
                            prati korišćenje interneta u marketinške svrhe.

                            Eclectic koristi usluge nekoliko servisa koji koriste ograničene kolačiće.
                            Ti kolačići imaju za cilj omogućavanje određenih funkcija koje korisnicima
                            olakšavaju pristup sadržaju kao i korišćenje tog sadržaja od strane
                            drugih internet servisa, kao što su sledeći koje trenutno omogućavamo:

                            društvene mreže na kojima se oglašavamo (Facebook, Instagram) i
                            servis za merenje posećenosti (Google Analytics).

                            <h5>Kako onemogućiti kolačiće</h5>

                            Blokiranjem kolačića odlučujete da li pristajete na pohranjivanje kolačića na Vašem uređaju.
                            Sva podešavanja u vezi sa kolačićma možete da izvršite u Vašem internet pretraživaču.
                            Ako onemogućite kolačiće, nećete moći da koristite neke od funkcija na veb-sajtovima.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KolaciciPage;