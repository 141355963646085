import React from 'react';
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {unsubscribeKlijent} from "../../apis/KlijentiApi";
import {Alert, LinearProgress} from "@mui/material";

const UnsubscribePage = () => {
    const {email} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['unsubscribe', email],
        queryFn: () => unsubscribeKlijent(email),
        enabled: email != null || email != ""
    });

    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Odjavljivanje sa mailing liste</h2>
                        </div>
                        <div className="col-12">
                            <a href="/">Početna</a>
                            <a href="">Odjavljivanje</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {isLoading ? <LinearProgress/> : (
                    data?.data.type == 0 ? (
                        <Alert
                            severity="success"
                            variant="filled"
                            sx={{width: "100%", justifyContent: "center"}}
                        >
                            {data?.data.message}
                        </Alert>
                    ) : (
                        <Alert
                            severity="error"
                            variant="filled"
                            sx={{width: "100%", justifyContent: "center"}}
                        >
                            {data?.data.message}
                        </Alert>
                    )
                )}
            </div>
        </div>
    );
};

export default UnsubscribePage;