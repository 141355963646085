import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import c1 from "../../assets/img/carousel-1.jpg";
import c2 from "../../assets/img/cpic.jpg";
import c3 from "../../assets/img/carousel-2.jpg";
import c4 from "../../assets/img/carousel-3.jpg";
import image1 from "../../assets/img/lambo.jpg";

const HomePage = () => {
    return (
        <div>
            <div className="carousel">
                <OwlCarousel items={1} className='owl-theme' loop margin={10} nav>
                    <div className='carousel-item'>
                        <div className="carousel-img">
                            <img src={c1} alt="Image" className="respimg"/>
                        </div>
                        <div className="carousel-text">
                            <h3>Premium Car Wash & Detailing Studio</h3>
                            <h1>Eclectic Detailing</h1>
                            <a className="btn btn-custom" href="/o-nama">O nama</a>
                        </div>
                    </div>
                    <div className='carousel-item'>
                        <div className="carousel-img">
                            <img src={c2} alt="Image" className="respimg"/>
                        </div>
                        <div className="carousel-text">
                            <h3>Premium Car Wash & Detailing Studio</h3>
                            <h1>Rezervacija termina</h1>
                            <a className="btn btn-custom" href="/zakazivanje">Zakažite termin</a>
                        </div>
                    </div>
                    <div className='carousel-item'>
                        <div className="carousel-img">
                            <img src={c3} alt="Image" className="respimg"/>
                        </div>
                        <div className="carousel-text">
                            <h3>Naše usluge</h3>
                            <h1>Eclectic Usluge</h1>
                            <a className="btn btn-custom" href="/car-wash-usluge">Saznajte više</a>
                        </div>
                    </div>
                    <div className='carousel-item'>
                        <div className="carousel-img">
                            <img src={c4} alt="Image" className="respimg"/>
                        </div>
                        <div className="carousel-text">
                            <h3>Naša galerija</h3>
                            <h1>Eclectic Galerija</h1>
                            <a className="btn btn-custom" href="/car-wash-galerija">Pogledajte</a>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
            <div className="about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src={image1} alt="Slika lambo"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-header text-left">
                                <p>Zašto Eclectic?</p>
                                <h2>Prednosti korišćenja naših usluga</h2>
                            </div>
                            <div className="about-content">
                                <p>
                                    Nekoliko glavni razloga zašto odabrati baš nas, i zašto koristiti naše premium
                                    usluge za negu vašeg automobila.
                                </p>
                                <ul>
                                    <li><i className="far fa-check-circle"></i>Demineralizovana voda</li>
                                    <li><i className="far fa-check-circle"></i>Profesionalni alati za pranje</li>
                                    <li>
                                        <i className="far fa-check-circle"></i>Bezkomporomisni kvalitet hemije
                                    </li>
                                    <li><i className="far fa-check-circle"></i>Iskusno osoblje</li>
                                </ul>
                                <a className="btn btn-custom" href="/o-nama">O nama</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Šta mi radimo?</p>
                        <h2>Naše Premium Usluge</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-wash-1"></i>
                                <h3>Spoljno pranje</h3>
                                <p>
                                    Pranje vašeg automobila spolja, najmodernijim alatima i preparatima
                                    koji ne mogu oštetiti vaš automobil.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-wash"></i>
                                <h3>Unutrašnje pranje</h3>
                                <p>
                                    Pranje vašeg automobila iznutra, uklanjanje fleka, prljavštine
                                    i prašine iz unutrašnjosti vašeg automobila.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-vacuum-cleaner"></i>
                                <h3>Usisavanje</h3>
                                <p>
                                    Kompletno uklanjanje prljavštine i ostalih krupnijih stvari
                                    iz vašeg automobila.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-seat"></i>
                                <h3>Pranje sedišta</h3>
                                <p>
                                    Otklanjanje najtvrdokornijih fleka modernim hemijskim sredstvima
                                    bez oštećivanja materijala na vašim sedištima.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-service"></i>
                                <h3>Čišćenje prozora</h3>
                                <p>
                                    Pranje i čišćenje prozora tako da prozori opet budu sjajni,
                                    bez propratnih fleka i kapljica kasnije
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-service-2"></i>
                                <h3>Brisanje automobila</h3>
                                <p>
                                    Brisanje automobila suvom krpom i otklanjanje svih tragova
                                    da je auto uopšte bio kvašen.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-wash"></i>
                                <h3>Skidanje fleka</h3>
                                <p>
                                    Skidanje fleka sa spoljašnjosti vašeg automobila, tako da
                                    vaš auto izgleda kao nov.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-brush-1"></i>
                                <h3>Keramička zaštita</h3>
                                <p>
                                    Zaštita celog enterijera vašeg automobila najkvalitetnijom keramikom,
                                    da vaš automobil izgleda kao nov.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="facts" data-parallax="scroll" data-image-src="img/facts.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="facts-item">
                                <i className="fa fa-map-marker-alt"></i>
                                <div className="facts-text">
                                    <h3 data-toggle="counter-up">1</h3>
                                    <p>Lokacija</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="facts-item">
                                <i className="fa fa-user"></i>
                                <div className="facts-text">
                                    <h3 data-toggle="counter-up">10</h3>
                                    <p>Zaposlenih</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="facts-item">
                                <i className="fa fa-users"></i>
                                <div className="facts-text">
                                    <h3 data-toggle="counter-up">100</h3>
                                    <p>Zadovoljnih klijenata</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="facts-item">
                                <i className="fa fa-check"></i>
                                <div className="facts-text">
                                    <h3 data-toggle="counter-up">100</h3>
                                    <p>Opranih automobila</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="location">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-header text-left">
                                <p>Naše lokacije</p>
                                <h2>Car Wash & Detailing mesta</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="location-item">
                                        <i className="fa fa-map-marker-alt"></i>
                                        <div className="location-text">
                                            <h3>Eclectic Car Wash</h3>
                                            <a href="https://www.google.com/maps/place/Eclectic+detailing+doo/@44.8199987,20.4709148,20.21z/data=!4m5!3m4!1s0x475a7befabedb307:0x44a40ca2add5b03!8m2!3d44.8200229!4d20.4706318">
                                                <p>Venizelosova 15, Beograd</p>
                                            </a>
                                            <p><strong>Pozovite:</strong><a
                                                href="tel:+381692103985">+381692103985</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="location-item">
                                        <i className="fa fa-map-marker-alt"></i>
                                        <div className="location-text">
                                            <h3>Eclectic Detailing Studio</h3>
                                            <a href="">
                                                <p>Zvezdara</p>
                                            </a>
                                            <p><strong>Pozovite:</strong><a
                                                href="tel:+381692103985">+381692103985</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="location-item">
                                        <i className="fa fa-map-marker-alt"></i>
                                        <div className="location-text">
                                            <h3>Otvara se uskoro!</h3>
                                            <p>Novi Beograd</p>
                                            <p><strong>Pozovite:</strong><a
                                                href="tel:+381692103985">+381692103985</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="location-form">
                                <h3>Zakazivanje <i className="fa-solid fa-clock"></i></h3>
                                <p>
                                    Da li želite da zakažete vaš termin? <br/>
                                    Nema problema! Lako i brzo zakažite vaš termin danas preko našeg sajta! <br/>
                                    U par klikova zakažite i platite vaš termin, a mi ćemo se pobrinuti da vaše
                                    vozilo izgleda kao novo!
                                </p>
                                <div>
                                    <a href="/zakazivanje">
                                        <button className="btn btn-custom" type="submit">
                                            Zakažite <i className="fa-solid fa-calendar-days"></i>
                                        </button>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;