import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {useNavigate} from "react-router";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import GroupIcon from '@mui/icons-material/Group';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CollectionsIcon from '@mui/icons-material/Collections';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import EventBusyIcon from "@mui/icons-material/EventBusy";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ArticleIcon from '@mui/icons-material/Article';

const mainListItems = [
    {
        text: "Početna",
        icon: <HomeRoundedIcon/>,
        route: "../administrator/početna",
    },
    {
        text: "Partneri",
        icon: <HandshakeIcon/>,
        route: "../administrator/partneri",
    },
    {
        text: "Termini",
        icon: <CalendarMonthIcon/>,
        route: "../administrator/termini",
    },
    {
        text: "Usluge",
        icon: <CleaningServicesIcon/>,
        route: "../administrator/usluge",
    },
    {
        text: "Klijenti",
        icon: <GroupIcon/>,
        route: "../administrator/mušterije",
    },
    {
        text: "Prijavljeni korisnici",
        icon: <NewspaperIcon/>,
        route: "../administrator/klijenti",
    },
    {
        text: "Radnici",
        icon: <EngineeringIcon/>,
        route: "../administrator/radnici",
    },
    {
        text: "Galerija",
        icon: <CollectionsIcon/>,
        route: "../administrator/galerija",
    },
    {
        text: "Promo kodovi",
        icon: <QrCode2Icon/>,
        route: "../administrator/promo-kodovi",
    },
    {
        text: "Neradni dani",
        icon: <EventBusyIcon/>,
        route: "../administrator/neradni-dani",
    },
    {
        text: "Novosti",
        icon: <ArticleIcon/>,
        route: "../administrator/novosti",
    },
];

export default function MenuContent() {
    let selectedIndex = localStorage.getItem("index");

    const navigate = useNavigate();

    return (
        <Stack sx={{flexGrow: 1, p: 1, justifyContent: "space-between"}}>
            <List dense>
                {mainListItems.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{display: "block"}}>
                        <ListItemButton
                            onClick={() => {
                                localStorage.setItem("index", index);
                                navigate(item.route);
                            }}
                            selected={index == selectedIndex}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
}
